export const megalithicStructures = [
    {
        "name": "Stonehenge",
        "location": "Wiltshire, England",
        "coordinates": [51.1789, -1.8262],
        "description": "A prehistoric monument consisting of a ring of standing stones.",
        "wikipedia": "https://en.wikipedia.org/wiki/Stonehenge",
        "constructedDate": "c. 3000–2000 BC",
        "isUnesco": true
    },
    {
        "name": "Göbekli Tepe",
        "location": "Şanlıurfa, Turkey",
        "coordinates": [37.2231, 38.9226],
        "description": "An archaeological site in the Southeastern Anatolia Region of Turkey.",
        "wikipedia": "https://en.wikipedia.org/wiki/G%C3%B6bekli_Tepe",
        "constructedDate": "c. 9600 BC",
        "isUnesco": true
    },
    {
        "name": "Carnac Stones",
        "location": "Brittany, France",
        "coordinates": [47.5961, -3.0823],
        "description": "An exceptionally dense collection of megalithic sites around the village of Carnac.",
        "wikipedia": "https://en.wikipedia.org/wiki/Carnac_stones",
        "constructedDate": "c. 4500 BC",
        "isUnesco": false
    },
    {
        "name": "Newgrange",
        "location": "County Meath, Ireland",
        "coordinates": [53.6947, -6.4755],
        "description": "A prehistoric monument in County Meath, Ireland, and the most famous of all Irish passage tombs.",
        "wikipedia": "https://en.wikipedia.org/wiki/Newgrange",
        "constructedDate": "c. 3200 BC",
        "isUnesco": true
    },
    {
        "name": "Machu Picchu",
        "location": "Cusco Region, Peru",
        "coordinates": [-13.1631, -72.5450],
        "description": "A 15th-century Inca citadel located in the Eastern Cordillera of southern Peru.",
        "wikipedia": "https://en.wikipedia.org/wiki/Machu_Picchu",
        "constructedDate": "c. 1450 AD",
        "isUnesco": true
    },
    {
        "name": "The Great Pyramid of Giza",
        "location": "Giza, Egypt",
        "coordinates": [29.9792, 31.1342],
        "description": "The oldest and largest of the three pyramids in the Giza pyramid complex.",
        "wikipedia": "https://en.wikipedia.org/wiki/Great_Pyramid_of_Giza",
        "constructedDate": "c. 2580–2560 BC",
        "isUnesco": true
    },
    {
        "name": "Pyramid of Khafre",
        "location": "Giza, Egypt",
        "coordinates": [29.9761, 31.1325],
        "description": "The second-tallest and second-largest of the Ancient Egyptian Pyramids of Giza.",
        "wikipedia": "https://en.wikipedia.org/wiki/Pyramid_of_Khafre",
        "constructedDate": "c. 2570 BC",
        "isUnesco": false
    },
    {
        "name": "Pyramid of Menkaure",
        "location": "Giza, Egypt",
        "coordinates": [29.972, 31.1344],
        "description": "The smallest of the three main pyramids of Giza, known for its elaborate mortuary temple.",
        "wikipedia": "https://en.wikipedia.org/wiki/Pyramid_of_Menkaure",
        "constructedDate": "c. 2510 BC",
        "isUnesco": false
    },
    {
        "name": "Pyramid of the Sun",
        "location": "Teotihuacan, Mexico",
        "coordinates": [19.6925, -98.8447],
        "description": "The largest building in Teotihuacan, believed to have been constructed about 200 AD.",
        "wikipedia": "https://en.wikipedia.org/wiki/Teotihuacan#Pyramid_of_the_Sun",
        "constructedDate": "c. 200 AD",
        "isUnesco": true
    },
    {
        "name": "Avebury",
        "location": "Wiltshire, England",
        "coordinates": [51.4280, -1.8541],
        "description": "A Neolithic henge monument containing three stone circles, around the village of Avebury.",
        "wikipedia": "https://en.wikipedia.org/wiki/Avebury",
        "constructedDate": "c. 2850 BC",
        "isUnesco": true
    },
    {
        "name": "Sacsayhuamán",
        "location": "Cusco, Peru",
        "coordinates": [-13.5097, -71.9813],
        "description": "A citadel on the northern outskirts of the city of Cusco, known for its large dry stone walls.",
        "wikipedia": "https://en.wikipedia.org/wiki/Sacsayhuam%C3%A1n",
        "constructedDate": "c. 1438–1471 AD",
        "isUnesco": true
    },
    {
        "name": "The Callanish Stones",
        "location": "Isle of Lewis, Scotland",
        "coordinates": [58.1970, -6.7443],
        "description": "A cross-shaped setting of standing stones erected during the late Neolithic era.",
        "wikipedia": "https://en.wikipedia.org/wiki/Callanish_Stones",
        "constructedDate": "c. 2900–2600 BC",
        "isUnesco": false
    },
    {
        "name": "Baalbek",
        "location": "Baalbek, Lebanon",
        "coordinates": [34.0064, 36.2039],
        "description": "An ancient Phoenician city, known for its grand Roman temple complex.",
        "wikipedia": "https://en.wikipedia.org/wiki/Baalbek",
        "constructedDate": "c. 9000 BC (site)",
        "isUnesco": true
    },
    {
        "name": "Tiahuanaco",
        "location": "Bolivia",
        "coordinates": [-16.5534, -68.6701],
        "description": "A Pre-Columbian archaeological site in western Bolivia near Lake Titicaca.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tiwanaku",
        "constructedDate": "c. 300 BC",
        "isUnesco": true
    },
    {
        "name": "Dolmens of North Caucasus",
        "location": "Russia",
        "coordinates": [43.5000, 44.0000],
        "description": "Megalithic structures scattered throughout the foothills of the Caucasus mountains.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dolmens_of_the_North_Caucasus",
        "constructedDate": "c. 2500–2000 BC",
        "isUnesco": false
    },
    {
        "name": "Skara Brae",
        "location": "Orkney, Scotland",
        "coordinates": [59.0489, -3.3432],
        "description": "A stone-built Neolithic settlement on the Bay of Skaill on the west coast of Mainland, Orkney, Scotland.",
        "wikipedia": "https://en.wikipedia.org/wiki/Skara_Brae",
        "constructedDate": "c. 3180 BC",
        "isUnesco": true
    },
    {
        "name": "Serpent Mound",
        "location": "Adams County, Ohio, USA",
        "coordinates": [39.0247, -83.4296],
        "description": "An effigy mound in the shape of a snake with an undulating coil, thought to have been built by ancient Indigenous peoples.",
        "wikipedia": "https://en.wikipedia.org/wiki/Serpent_Mound",
        "constructedDate": "c. 1000 AD",
        "isUnesco": false
    },
    {
        "name": "Maeshowe",
        "location": "Orkney, Scotland",
        "coordinates": [58.9953, -3.1883],
        "description": "A Neolithic chambered cairn and passage grave situated on the Mainland of Orkney.",
        "wikipedia": "https://en.wikipedia.org/wiki/Maeshowe",
        "constructedDate": "c. 2800 BC",
        "isUnesco": true
    },
    {
        "name": "Bryn Celli Ddu",
        "location": "Anglesey, Wales",
        "coordinates": [53.2198, -4.2613],
        "description": "A Neolithic henge monument on the Welsh island of Anglesey, often considered one of the finest passage graves in Wales.",
        "wikipedia": "https://en.wikipedia.org/wiki/Bryn_Celli_Ddu",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "The Hypogeum of Ħal-Saflieni",
        "location": "Paola, Malta",
        "coordinates": [35.8740, 14.5013],
        "description": "An underground prehistoric burial site, dating back to the Saflieni phase in Maltese prehistory.",
        "wikipedia": "https://en.wikipedia.org/wiki/%C4%A6al-Saflieni_Hypogeum",
        "constructedDate": "c. 4000–2500 BC",
        "isUnesco": true
    },
    {
        "name": "Nabta Playa",
        "location": "Egypt",
        "coordinates": [22.5067, 30.7112],
        "description": "An archaeological site in the Nubian Desert that contains the remains of prehistoric human habitation.",
        "wikipedia": "https://en.wikipedia.org/wiki/Nabta_Playa",
        "constructedDate": "c. 7500 BC",
        "isUnesco": false
    },
    {
        "name": "Hagar Qim",
        "location": "Qrendi, Malta",
        "coordinates": [35.8317, 14.4432],
        "description": "A megalithic temple complex found on the Mediterranean island of Malta.",
        "wikipedia": "https://en.wikipedia.org/wiki/%C4%A6a%C4%A1ar_Qim",
        "constructedDate": "c. 3700–3200 BC",
        "isUnesco": true
    },
    {
        "name": "Dolmen of Menga",
        "location": "Antequera, Spain",
        "coordinates": [37.0214, -4.5484],
        "description": "A large megalithic dolmen dating from the third millennium BC.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dolmen_of_Menga",
        "constructedDate": "c. 3750–3650 BC",
        "isUnesco": true
    },
    {
        "name": "Drombeg stone circle",
        "location": "County Cork, Ireland",
        "coordinates": [51.5612, -9.0993],
        "description": "A recumbent stone circle located in County Cork, Ireland.",
        "wikipedia": "https://en.wikipedia.org/wiki/Drombeg_stone_circle",
        "constructedDate": "c. 1100–800 BC",
        "isUnesco": false
    },
    {
        "name": "La Hougue Bie",
        "location": "Jersey, Channel Islands",
        "coordinates": [49.2008, -2.0627],
        "description": "A Neolithic ritual site and passage grave in the Parish of Grouville, Jersey.",
        "wikipedia": "https://en.wikipedia.org/wiki/La_Hougue_Bie",
        "constructedDate": "c. 3500 BC",
        "isUnesco": false
    },
    {
        "name": "Mên-an-Tol",
        "location": "Cornwall, England",
        "coordinates": [50.1645, -5.6197],
        "description": "A small formation of standing stones in Cornwall, England.",
        "wikipedia": "https://en.wikipedia.org/wiki/M%C3%AAn-an-Tol",
        "constructedDate": "c. Bronze Age",
        "isUnesco": false
    },
    {
        "name": "Menhir de Champ-Dolent",
        "location": "Brittany, France",
        "coordinates": [48.4800, -1.8500],
        "description": "A large standing stone in Brittany, France.",
        "wikipedia": "https://en.wikipedia.org/wiki/Menhir_de_Champ-Dolent",
        "constructedDate": "c. 5000 BC",
        "isUnesco": false
    },
    {
        "name": "Harhoog",
        "location": "Sylt, Germany",
        "coordinates": [54.9231, 8.3064],
        "description": "A dolmen or passage grave on the island of Sylt, Germany.",
        "wikipedia": "https://en.wikipedia.org/wiki/Harhoog",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Dolmen du Goërem",
        "location": "Brittany, France",
        "coordinates": [47.6401, -3.1168],
        "description": "A megalithic tomb in Brittany, France.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dolmen",
        "constructedDate": "c. 4000–2000 BC",
        "isUnesco": false
    },
    {
        "name": "Tarxien Temples",
        "location": "Tarxien, Malta",
        "coordinates": [35.8784, 14.5149],
        "description": "A group of megalithic temples dating back to approximately 3150 BC.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tarxien_Temples",
        "constructedDate": "c. 3150 BC",
        "isUnesco": false
    },
    {
        "name": "Dún Aonghasa",
        "location": "Inishmore, Ireland",
        "coordinates": [53.1254, -9.7687],
        "description": "A prehistoric fort on the Aran Islands of County Galway, Ireland.",
        "wikipedia": "https://en.wikipedia.org/wiki/D%C3%BAn_Aonghasa",
        "constructedDate": "c. 1100 BC",
        "isUnesco": false
    },
    {
        "name": "Barnenez",
        "location": "Brittany, France",
        "coordinates": [48.6354, -3.8755],
        "description": "A Neolithic monument located in Brittany, France, consisting of an ancient cairn.",
        "wikipedia": "https://en.wikipedia.org/wiki/Barnenez",
        "constructedDate": "c. 4850 BC",
        "isUnesco": false
    },
    {
        "name": "Maidanetske",
        "location": "Ukraine",
        "coordinates": [48.9094, 30.1841],
        "description": "An archaeological site of the Cucuteni–Trypillia culture in Ukraine.",
        "wikipedia": "https://en.wikipedia.org/wiki/Maidanetske",
        "constructedDate": "c. 3900 BC",
        "isUnesco": false
    },
    {
        "name": "Nuraghe Santu Antine",
        "location": "Sardinia, Italy",
        "coordinates": [40.5218, 8.7975],
        "description": "A nuragic complex in Torralba, Sardinia.",
        "wikipedia": "https://en.wikipedia.org/wiki/Nuraghe_Santu_Antine",
        "constructedDate": "c. 1800 BC",
        "isUnesco": false
    },
    {
        "name": "Ggantija",
        "location": "Gozo, Malta",
        "coordinates": [36.0464, 14.2701],
        "description": "A megalithic temple complex located on the Mediterranean island of Gozo.",
        "wikipedia": "https://en.wikipedia.org/wiki/%C4%A0gantija",
        "constructedDate": "c. 3600–2500 BC",
        "isUnesco": false
    },
    {
        "name": "Stone Circles of Senegambia",
        "location": "Senegal and The Gambia",
        "coordinates": [13.6838, -14.9688],
        "description": "A megalithic complex located in the Gambia River basin.",
        "wikipedia": "https://en.wikipedia.org/wiki/Stone_Circles_of_Senegambia",
        "constructedDate": "c. 300 BC–1500 AD",
        "isUnesco": false
    },
    {
        "name": "Heroon at Trysa",
        "location": "Antalya Province, Turkey",
        "coordinates": [36.2564, 29.8610],
        "description": "A monumental tomb built by the Lycian dynasties.",
        "wikipedia": "https://en.wikipedia.org/wiki/Heroon_at_Trysa",
        "constructedDate": "c. 350 BC",
        "isUnesco": false
    },
    {
        "name": "Dolmen of Guadalperal",
        "location": "Cáceres, Spain",
        "coordinates": [39.8638, -5.6166],
        "description": "A megalithic monument now partially submerged by the waters of a reservoir.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dolmen_of_Guadalperal",
        "constructedDate": "c. 2000 BC",
        "isUnesco": false
    },
    {
        "name": "Dolmen of Soto",
        "location": "Huelva, Spain",
        "coordinates": [37.4031, -6.5624],
        "description": "A Neolithic dolmen located in Trigueros, Spain.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dolmen_of_Soto",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Kostroma Moat",
        "location": "Kostroma Oblast, Russia",
        "coordinates": [58.4288, 41.1249],
        "description": "An ancient earthwork and settlement site.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kostroma_Moat",
        "constructedDate": "c. 1000 BC",
        "isUnesco": false
    },
    {
        "name": "Locmariaquer Megaliths",
        "location": "Brittany, France",
        "coordinates": [47.5841, -3.1688],
        "description": "A complex of Neolithic sites, including the Great Broken Menhir of Er Grah.",
        "wikipedia": "https://en.wikipedia.org/wiki/Locmariaquer_megaliths",
        "constructedDate": "c. 4500 BC",
        "isUnesco": false
    },
    {
        "name": "Monte d'Accoddi",
        "location": "Sardinia, Italy",
        "coordinates": [40.7293, 8.5413],
        "description": "A Neolithic archaeological site in northern Sardinia, Italy.",
        "wikipedia": "https://en.wikipedia.org/wiki/Monte_d%27Accoddi",
        "constructedDate": "c. 4000 BC",
        "isUnesco": false
    },
    {
        "name": "Amada Stone Circle",
        "location": "Sudan",
        "coordinates": [22.3365, 31.6257],
        "description": "A prehistoric stone circle located in the Nubian Desert.",
        "wikipedia": "https://en.wikipedia.org/wiki/Amada_(Nubia)",
        "constructedDate": "c. 5000–2000 BC",
        "isUnesco": false
    },
    {
        "name": "Bu Tinah Island Stone Structures",
        "location": "Abu Dhabi, United Arab Emirates",
        "coordinates": [24.5333, 52.6667],
        "description": "Stone structures found on Bu Tinah Island, indicating ancient human activity.",
        "wikipedia": "https://en.wikipedia.org/wiki/Bu_Tinah",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Lough Gur Stone Circle",
        "location": "County Limerick, Ireland",
        "coordinates": [52.5217, -8.5240],
        "description": "A stone circle near Lough Gur, known for its ancient ritual significance.",
        "wikipedia": "https://en.wikipedia.org/wiki/Lough_Gur",
        "constructedDate": "c. 2500 BC",
        "isUnesco": false
    },
    {
        "name": "Hulbjerg Passage Grave",
        "location": "Langeland, Denmark",
        "coordinates": [54.7948, 10.6722],
        "description": "A passage grave located on the island of Langeland, Denmark.",
        "wikipedia": "https://en.wikipedia.org/wiki/Hulbjerg_J%C3%A6ttekammer",
        "constructedDate": "c. 3200 BC",
        "isUnesco": false
    },
    {
        "name": "Temple of Malta",
        "location": "Ġgantija, Malta",
        "coordinates": [36.0483, 14.2658],
        "description": "Ancient megalithic temples located on the Maltese island of Gozo.",
        "wikipedia": "https://en.wikipedia.org/wiki/%C4%A0gantija",
        "constructedDate": "c. 3600–2500 BC",
        "isUnesco": false
    },
    {
        "name": "Le Grand Menhir Brisé",
        "location": "Brittany, France",
        "coordinates": [47.5786, -3.1231],
        "description": "A large broken menhir located in the Locmariaquer megaliths complex.",
        "wikipedia": "https://en.wikipedia.org/wiki/Locmariaquer_megaliths",
        "constructedDate": "c. 4500 BC",
        "isUnesco": false
    },
    {
        "name": "Hagar Qim Temples",
        "location": "Qrendi, Malta",
        "coordinates": [35.8318, 14.4415],
        "description": "A megalithic temple complex dating back to the Ġgantija phase.",
        "wikipedia": "https://en.wikipedia.org/wiki/%C4%A6a%C4%A1ar_Qim",
        "constructedDate": "c. 3600–3200 BC",
        "isUnesco": false
    },
    {
        "name": "Mnevniki Dolmen",
        "location": "Moscow, Russia",
        "coordinates": [55.7720, 37.3870],
        "description": "A prehistoric dolmen discovered in the Mnevniki district of Moscow.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dolmen",
        "constructedDate": "c. 3000–2000 BC",
        "isUnesco": false
    },
    {
        "name": "Gavrinis",
        "location": "Brittany, France",
        "coordinates": [47.5867, -2.8700],
        "description": "An island in the Gulf of Morbihan, known for its megalithic cairn and passage grave.",
        "wikipedia": "https://en.wikipedia.org/wiki/Gavrinis",
        "constructedDate": "c. 3500 BC",
        "isUnesco": false
    },
    {
        "name": "Borger Oak Wood Dolmen",
        "location": "Borger, Netherlands",
        "coordinates": [52.9275, 6.7950],
        "description": "A large dolmen located in the province of Drenthe, known as the 'Hunebedden'.",
        "wikipedia": "https://en.wikipedia.org/wiki/Hunebed",
        "constructedDate": "c. 3400 BC",
        "isUnesco": false
    },
    {
        "name": "Erdvėnų Pilkapiai",
        "location": "Lithuania",
        "coordinates": [55.5846, 24.9390],
        "description": "A group of burial mounds known as 'kurgans' located in Lithuania.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kurgan",
        "constructedDate": "c. 2000 BC",
        "isUnesco": false
    },
    {
        "name": "Dolmen de Viera",
        "location": "Antequera, Spain",
        "coordinates": [37.0221, -4.5519],
        "description": "A large dolmen and burial chamber near the Dolmen of Menga.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dolmen_of_Viera",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Stenness Stones",
        "location": "Orkney, Scotland",
        "coordinates": [59.0000, -3.2090],
        "description": "Part of the Neolithic Heart of Orkney, these stones form part of an ancient henge monument.",
        "wikipedia": "https://en.wikipedia.org/wiki/Stones_of_Stenness",
        "constructedDate": "c. 3100 BC",
        "isUnesco": false
    },
    {
        "name": "Temple of the Masks",
        "location": "Cohokia Mounds, Illinois, USA",
        "coordinates": [38.6564, -90.0577],
        "description": "Part of a large pre-Columbian Native American city featuring large earthen mounds.",
        "wikipedia": "https://en.wikipedia.org/wiki/Cahokia",
        "constructedDate": "c. 1050–1350 AD",
        "isUnesco": false
    },
    {
        "name": "Stone Ship Anundshög",
        "location": "Västmanland, Sweden",
        "coordinates": [59.6319, 16.5667],
        "description": "The largest stone ship in Sweden, an ancient Germanic burial monument.",
        "wikipedia": "https://en.wikipedia.org/wiki/Anundsh%C3%B6g",
        "constructedDate": "c. 1000 AD",
        "isUnesco": false
    },
    {
        "name": "Tumulus of Bougon",
        "location": "Nouvelle-Aquitaine, France",
        "coordinates": [46.4747, -0.1712],
        "description": "A group of barrows, or tumuli, that date back to the Neolithic period.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tumulus_of_Bougon",
        "constructedDate": "c. 4700 BC",
        "isUnesco": false
    },
    {
        "name": "The Old Man of Storr",
        "location": "Isle of Skye, Scotland",
        "coordinates": [57.5070, -6.1757],
        "description": "A rocky hill on the Trotternish peninsula, known for its unusual pinnacle of rock.",
        "wikipedia": "https://en.wikipedia.org/wiki/The_Storr",
        "constructedDate": "Geological formation, but associated with ancient myths.",
        "isUnesco": false
    },
    {
        "name": "Ales Stenar",
        "location": "Skåne, Sweden",
        "coordinates": [55.3800, 14.0528],
        "description": "A megalithic monument consisting of a stone ship, located near the village of Kåseberga.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ale%27s_Stones",
        "constructedDate": "c. 1400 BC",
        "isUnesco": false
    },
    {
        "name": "Monte Alban",
        "location": "Oaxaca, Mexico",
        "coordinates": [17.0475, -96.7675],
        "description": "An ancient Zapotec archaeological site situated on a mountain range.",
        "wikipedia": "https://en.wikipedia.org/wiki/Monte_Alb%C3%A1n",
        "constructedDate": "c. 500 BC–750 AD",
        "isUnesco": false
    },
    {
        "name": "The Giant's Grave",
        "location": "Arzachena, Sardinia",
        "coordinates": [41.0535, 9.4046],
        "description": "A large ancient grave monument of the Nuragic civilization.",
        "wikipedia": "https://en.wikipedia.org/wiki/Giants%27_graves_(Sardinia)",
        "constructedDate": "c. 1600 BC",
        "isUnesco": false
    },
    {
        "name": "King's Men Stone Circle",
        "location": "Oxfordshire, England",
        "coordinates": [51.9284, -1.5424],
        "description": "Part of the Rollright Stones complex, a stone circle dating back to the Neolithic period.",
        "wikipedia": "https://en.wikipedia.org/wiki/Rollright_Stones",
        "constructedDate": "c. 2500 BC",
        "isUnesco": false
    },
    {
        "name": "Ishibutai Kofun",
        "location": "Asuka, Japan",
        "coordinates": [34.4700, 135.7889],
        "description": "A stone tomb from the Asuka period, believed to be the burial site of Soga no Umako.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ishibutai_Kofun",
        "constructedDate": "c. 600 AD",
        "isUnesco": false
    },
    {
        "name": "Dolmen de la Pierre Levée",
        "location": "Poitiers, France",
        "coordinates": [46.5690, 0.3522],
        "description": "A dolmen located near Poitiers, known for its massive horizontal capstone.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dolmen",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Jelling Stones",
        "location": "Jelling, Denmark",
        "coordinates": [55.7539, 9.4174],
        "description": "Runestones erected by King Gorm the Old and King Harald Bluetooth in the 10th century.",
        "wikipedia": "https://en.wikipedia.org/wiki/Jelling_stones",
        "constructedDate": "c. 965 AD",
        "isUnesco": false
    },
    {
        "name": "Monte d'Accoddi",
        "location": "Sassari, Sardinia, Italy",
        "coordinates": [40.7289, 8.5426],
        "description": "An ancient site with a ziggurat-like structure, dating back to the Neolithic period.",
        "wikipedia": "https://en.wikipedia.org/wiki/Monte_d%27Accoddi",
        "constructedDate": "c. 4000–3650 BC",
        "isUnesco": false
    },
    {
        "name": "Mên Scryfa",
        "location": "Cornwall, England",
        "coordinates": [50.1833, -5.6000],
        "description": "An inscribed stone standing in a field near Madron in Cornwall.",
        "wikipedia": "https://en.wikipedia.org/wiki/M%C3%AAn_Scryfa",
        "constructedDate": "c. 5th–6th century AD",
        "isUnesco": false
    },
    {
        "name": "Goseck Circle",
        "location": "Saxony-Anhalt, Germany",
        "coordinates": [51.1986, 11.8650],
        "description": "An early Neolithic circular enclosure with gates aligned to the winter solstice.",
        "wikipedia": "https://en.wikipedia.org/wiki/Goseck_circle",
        "constructedDate": "c. 4900 BC",
        "isUnesco": false
    },
    {
        "name": "Carrowkeel Megalithic Cemetery",
        "location": "County Sligo, Ireland",
        "coordinates": [54.0560, -8.4627],
        "description": "A group of passage tombs from the Neolithic period, located in the Bricklieve Mountains.",
        "wikipedia": "https://en.wikipedia.org/wiki/Carrowkeel_Megalithic_Cemetery",
        "constructedDate": "c. 3500 BC",
        "isUnesco": false
    },
    {
        "name": "Megaliths of Pangguyangan",
        "location": "West Java, Indonesia",
        "coordinates": [-6.8989, 107.6171],
        "description": "Ancient stone structures found in the highlands of West Java, Indonesia.",
        "wikipedia": "https://en.wikipedia.org/wiki/Megaliths_of_Indonesia",
        "constructedDate": "c. 2000 BC",
        "isUnesco": false
    },
    {
        "name": "Cairnpapple Hill",
        "location": "West Lothian, Scotland",
        "coordinates": [55.9228, -3.6542],
        "description": "A hill with a ceremonial site dating back to the Neolithic period, featuring cairns and henges.",
        "wikipedia": "https://en.wikipedia.org/wiki/Cairnpapple_Hill",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Tara Hill",
        "location": "County Meath, Ireland",
        "coordinates": [53.5775, -6.6091],
        "description": "A ceremonial and burial site associated with ancient kingship rituals in Ireland.",
        "wikipedia": "https://en.wikipedia.org/wiki/Hill_of_Tara",
        "constructedDate": "c. 3500 BC",
        "isUnesco": false
    },
    {
        "name": "Bada Valley Megaliths",
        "location": "Central Sulawesi, Indonesia",
        "coordinates": [-1.3813, 120.1050],
        "description": "A collection of large stone carvings located in the Bada Valley, some over 5000 years old.",
        "wikipedia": "https://en.wikipedia.org/wiki/Bada_Valley",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Banwell Bone Cave",
        "location": "Somerset, England",
        "coordinates": [51.3230, -2.8637],
        "description": "A natural cave with archaeological findings dating back to the Neolithic period.",
        "wikipedia": "https://en.wikipedia.org/wiki/Banwell_Bone_Cave",
        "constructedDate": "c. 10000 BC",
        "isUnesco": false
    },
    {
        "name": "Kuk Swamp",
        "location": "Papua New Guinea",
        "coordinates": [-5.7500, 144.2500],
        "description": "An archaeological site indicating early agricultural activity in the highlands of Papua New Guinea.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kuk_Swamp",
        "constructedDate": "c. 4000 BC",
        "isUnesco": false
    },
    {
        "name": "Amiantos Asbestos Mines",
        "location": "Cyprus",
        "coordinates": [34.9000, 32.9167],
        "description": "An ancient mining site in Cyprus with evidence of early extraction techniques.",
        "wikipedia": "https://en.wikipedia.org/wiki/Amiantos",
        "constructedDate": "c. 2000 BC",
        "isUnesco": false
    },
    {
        "name": "Dún Dúchathair",
        "location": "Inishmore, Ireland",
        "coordinates": [53.1173, -9.7565],
        "description": "A large stone fort on the island of Inishmore in the Aran Islands.",
        "wikipedia": "https://en.wikipedia.org/wiki/D%C3%BAn_D%C3%BAchathair",
        "constructedDate": "c. 1000 BC",
        "isUnesco": false
    },
    {
        "name": "Giant's Ring",
        "location": "Belfast, Northern Ireland",
        "coordinates": [54.5384, -5.9595],
        "description": "A prehistoric henge and stone circle situated in Belfast, Northern Ireland.",
        "wikipedia": "https://en.wikipedia.org/wiki/Giant%27s_Ring",
        "constructedDate": "c. 2700 BC",
        "isUnesco": false
    },
    {
        "name": "The Cromlech of the Almendres",
        "location": "Évora, Portugal",
        "coordinates": [38.5584, -8.0613],
        "description": "A large megalithic complex of standing stones located near Évora.",
        "wikipedia": "https://en.wikipedia.org/wiki/Almendres_Cromlech",
        "constructedDate": "c. 6000 BC",
        "isUnesco": false
    },
    {
        "name": "Tombs of the Kings",
        "location": "Paphos, Cyprus",
        "coordinates": [34.7761, 32.4162],
        "description": "An impressive necropolis with large underground tombs dating back to the 4th century BC.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tombs_of_the_Kings_(Paphos)",
        "constructedDate": "c. 4th century BC",
        "isUnesco": false
    },
    {
        "name": "Chevresse Dolmen",
        "location": "Île-de-France, France",
        "coordinates": [48.5764, 2.4018],
        "description": "An ancient dolmen located near the city of Fontainebleau.",
        "wikipedia": "https://fr.wikipedia.org/wiki/Dolmen_de_Chevresse",
        "constructedDate": "c. 4000 BC",
        "isUnesco": false
    },
    {
        "name": "Samaipata",
        "location": "Santa Cruz, Bolivia",
        "coordinates": [-18.1781, -63.8731],
        "description": "An archaeological site with rock carvings on a mountain, believed to have ceremonial significance.",
        "wikipedia": "https://en.wikipedia.org/wiki/El_Fuerte_de_Samaipata",
        "constructedDate": "c. 300 AD",
        "isUnesco": false
    },
    {
        "name": "Menhir de Kerloas",
        "location": "Plouarzel, Brittany, France",
        "coordinates": [48.4483, -4.7836],
        "description": "The tallest standing menhir in Brittany, measuring over 9 meters high.",
        "wikipedia": "https://en.wikipedia.org/wiki/Menhir_de_Kerloas",
        "constructedDate": "c. 4500 BC",
        "isUnesco": false
    },
    {
        "name": "Dolmen of Menga",
        "location": "Andalusia, Spain",
        "coordinates": [37.0214, -4.5485],
        "description": "One of the largest dolmens in Europe, part of the Antequera Dolmens Site.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dolmen_of_Menga",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Menhir de Monteneuf",
        "location": "Brittany, France",
        "coordinates": [47.9089, -2.2262],
        "description": "Part of a large group of Neolithic standing stones located in Brittany.",
        "wikipedia": "https://en.wikipedia.org/wiki/Monteneuf",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Rujm el-Hiri",
        "location": "Golan Heights, Israel",
        "coordinates": [32.9028, 35.7831],
        "description": "An ancient stone structure consisting of concentric circles of stone.",
        "wikipedia": "https://en.wikipedia.org/wiki/Rujm_el-Hiri",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "La Hougue des Géons",
        "location": "Jersey, Channel Islands",
        "coordinates": [49.2300, -2.1100],
        "description": "A Neolithic gallery grave located on the island of Jersey.",
        "wikipedia": "https://en.wikipedia.org/wiki/La_Hougue_des_G%C3%A9ons",
        "constructedDate": "c. 3500 BC",
        "isUnesco": false
    },
    {
        "name": "The Ballochmyle Cup and Ring Marks",
        "location": "Ayrshire, Scotland",
        "coordinates": [55.4928, -4.3863],
        "description": "A series of prehistoric carvings on sandstone outcrops, thought to date back to the Neolithic or early Bronze Age.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ballochmyle_cup_and_ring_marks",
        "constructedDate": "c. 3000–2000 BC",
        "isUnesco": false
    },
    {
        "name": "Dolmen of Oppagne",
        "location": "Wallonia, Belgium",
        "coordinates": [50.3294, 5.5450],
        "description": "An ancient dolmen located near the town of Oppagne, known for its unique structure.",
        "wikipedia": "https://fr.wikipedia.org/wiki/Dolmen_d%27Oppagne",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Sechin Bajo",
        "location": "Ancash, Peru",
        "coordinates": [-9.1378, -78.0100],
        "description": "An archaeological site known for its complex of ancient stone structures.",
        "wikipedia": "https://en.wikipedia.org/wiki/Sechin_Bajo",
        "constructedDate": "c. 3500 BC",
        "isUnesco": false
    },
    {
        "name": "Monte Bubbonia",
        "location": "Sicily, Italy",
        "coordinates": [37.2333, 14.1167],
        "description": "An ancient site with remains of megalithic structures, located on a hill in Sicily.",
        "wikipedia": "https://en.wikipedia.org/wiki/Monte_Bubbonia",
        "constructedDate": "c. 2000 BC",
        "isUnesco": false
    },
    {
        "name": "Dolmen de l'Esprit",
        "location": "Languedoc-Roussillon, France",
        "coordinates": [43.6072, 3.9045],
        "description": "A dolmen located near Montpellier, known for its characteristic capstone.",
        "wikipedia": "https://fr.wikipedia.org/wiki/Dolmen_de_l%27Esprit",
        "constructedDate": "c. 2500 BC",
        "isUnesco": false
    },
    {
        "name": "Dolmen de la Loge-aux-Sarrazins",
        "location": "Île-de-France, France",
        "coordinates": [48.5081, 2.5852],
        "description": "An ancient dolmen located near the forest of Fontainebleau.",
        "wikipedia": "https://fr.wikipedia.org/wiki/Dolmen_de_la_Loge-aux-Sarrazins",
        "constructedDate": "c. 4000 BC",
        "isUnesco": false
    },
    {
        "name": "Kangxi Dolmen",
        "location": "Gyeonggi Province, South Korea",
        "coordinates": [37.6620, 126.6958],
        "description": "A prehistoric dolmen site located in Gyeonggi Province, South Korea.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dolmen",
        "constructedDate": "c. 1000 BC",
        "isUnesco": false
    },
    {
        "name": "Dolmen de la Planche à Puare",
        "location": "Vendée, France",
        "coordinates": [46.7401, -1.3140],
        "description": "A prehistoric dolmen located in the Vendée region of France.",
        "wikipedia": "https://fr.wikipedia.org/wiki/Dolmen_de_la_Planche_%C3%A0_Puare",
        "constructedDate": "c. 3500 BC",
        "isUnesco": false
    },
    {
        "name": "Cairn de Barnenez",
        "location": "Brittany, France",
        "coordinates": [48.6355, -3.8791],
        "description": "One of the earliest and largest megalithic monuments in Europe, located in Brittany.",
        "wikipedia": "https://en.wikipedia.org/wiki/Barnenez",
        "constructedDate": "c. 4850 BC",
        "isUnesco": false
    },
    {
        "name": "Colossi of Memnon",
        "location": "Luxor, Egypt",
        "coordinates": [25.7205, 32.6100],
        "description": "Two massive stone statues of Pharaoh Amenhotep III, known as the Colossi of Memnon.",
        "wikipedia": "https://en.wikipedia.org/wiki/Colossi_of_Memnon",
        "constructedDate": "c. 1350 BC",
        "isUnesco": false
    },
    {
        "name": "Dolmen de Mané Kerioned",
        "location": "Brittany, France",
        "coordinates": [47.6417, -3.1211],
        "description": "A group of three dolmens located in Carnac, Brittany, known for their alignment.",
        "wikipedia": "https://en.wikipedia.org/wiki/Carnac_stones",
        "constructedDate": "c. 3500 BC",
        "isUnesco": false
    },
    {
        "name": "Tomb of the Eagles",
        "location": "Orkney, Scotland",
        "coordinates": [58.8531, -2.7825],
        "description": "A prehistoric burial chamber in Orkney, discovered with eagle talons and human remains.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tomb_of_the_Eagles",
        "constructedDate": "c. 3150 BC",
        "isUnesco": false
    },
    {
        "name": "Dolmen of Mavrogianni",
        "location": "Euboea, Greece",
        "coordinates": [38.5736, 23.6186],
        "description": "A Neolithic dolmen located in the mountains of Euboea, Greece.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dolmen",
        "constructedDate": "c. 2500 BC",
        "isUnesco": false
    },
    {
        "name": "Le Menec Alignment",
        "location": "Carnac, Brittany, France",
        "coordinates": [47.5992, -3.0775],
        "description": "The largest group of standing stones in Carnac, consisting of around 1,100 stones in 11 columns.",
        "wikipedia": "https://en.wikipedia.org/wiki/Carnac_stones",
        "constructedDate": "c. 3300 BC",
        "isUnesco": false
    },
    {
        "name": "Dolmen de Bagneux",
        "location": "Saumur, France",
        "coordinates": [47.2560, -0.0814],
        "description": "One of the largest dolmens in France, featuring an impressive capstone.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dolmen",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Altopiano del Golgo",
        "location": "Sardinia, Italy",
        "coordinates": [40.0536, 9.6919],
        "description": "A plateau with numerous nuragic monuments and tombs of the giants.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tombs_of_the_giants",
        "constructedDate": "c. 1600 BC",
        "isUnesco": false
    },
    {
        "name": "Giants' Tombs",
        "location": "Sardinia, Italy",
        "coordinates": [40.0591, 9.6591],
        "description": "Ancient Sardinian megalithic structures used as collective tombs.",
        "wikipedia": "https://en.wikipedia.org/wiki/Giants%27_graves_(Sardinia)",
        "constructedDate": "c. 1800 BC",
        "isUnesco": false
    },
    {
        "name": "Hulbjerg Passage Grave",
        "location": "Langeland, Denmark",
        "coordinates": [54.8450, 10.5158],
        "description": "A well-preserved passage grave located on the island of Langeland.",
        "wikipedia": "https://en.wikipedia.org/wiki/Passage_grave",
        "constructedDate": "c. 3200 BC",
        "isUnesco": false
    },
    {
        "name": "Cueva de Menga",
        "location": "Antequera, Spain",
        "coordinates": [37.0214, -4.5522],
        "description": "A large ancient burial dolmen located near the Dolmen de Viera and the Dolmen de El Romeral.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dolmen_of_Menga",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Dolmen de la Madeleine",
        "location": "Petit-Palais-et-Cornemps, France",
        "coordinates": [44.9661, -0.0378],
        "description": "A classic example of a dolmen in the region of Nouvelle-Aquitaine.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dolmen",
        "constructedDate": "c. 3500 BC",
        "isUnesco": false
    },
    {
        "name": "Castlerigg Stone Circle",
        "location": "Keswick, England",
        "coordinates": [54.6021, -3.0986],
        "description": "An ancient stone circle situated on a picturesque hilltop in the Lake District.",
        "wikipedia": "https://en.wikipedia.org/wiki/Castlerigg_stone_circle",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Listoghil",
        "location": "County Sligo, Ireland",
        "coordinates": [54.0142, -8.5206],
        "description": "The central monument in Carrowmore, featuring a cairn and passage tomb.",
        "wikipedia": "https://en.wikipedia.org/wiki/Carrowmore",
        "constructedDate": "c. 3500 BC",
        "isUnesco": false
    },
    {
        "name": "Okunoin Dolmen",
        "location": "Kumano, Japan",
        "coordinates": [33.9220, 135.9180],
        "description": "An ancient dolmen found in the Kumano area, often visited for its historical significance.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dolmen",
        "constructedDate": "c. 2000 BC",
        "isUnesco": false
    },
    {
        "name": "Gunung Padang Megalithic Site",
        "location": "Cianjur, West Java, Indonesia",
        "coordinates": [-6.9948, 107.0572],
        "description": "A megalithic site in Indonesia thought to be one of the largest in Southeast Asia.",
        "wikipedia": "https://en.wikipedia.org/wiki/Gunung_Padang_megalithic_site",
        "constructedDate": "c. 5000 BC",
        "isUnesco": false
    },
    {
        "name": "Dolmen of Chabola de la Hechicera",
        "location": "Elvillar, Spain",
        "coordinates": [42.5944, -2.6108],
        "description": "One of the best-preserved dolmens in the Basque Country, notable for its size and state of preservation.",
        "wikipedia": "https://en.wikipedia.org/wiki/Chabola_de_la_Hechicera",
        "constructedDate": "c. 2500 BC",
        "isUnesco": false
    },
    {
        "name": "La Table des Marchands",
        "location": "Locmariaquer, France",
        "coordinates": [47.5736, -3.1125],
        "description": "A large dolmen with intricate carvings, located near other famous megaliths in Brittany.",
        "wikipedia": "https://en.wikipedia.org/wiki/Locmariaquer_megaliths",
        "constructedDate": "c. 4000 BC",
        "isUnesco": false
    },
    {
        "name": "Bosnian Pyramid",
        "location": "Visoko, Bosnia and Herzegovina",
        "coordinates": [43.9776, 18.1793],
        "description": "A controversial site claimed to be an ancient pyramid, though often debated.",
        "wikipedia": "https://en.wikipedia.org/wiki/Bosnian_pyramid_claims",
        "constructedDate": "Debated, controversial",
        "isUnesco": false
    },
    {
        "name": "Dolmen de la Joselière",
        "location": "Pornic, France",
        "coordinates": [47.1158, -2.1116],
        "description": "A dolmen located near the Atlantic coast, part of the prehistoric heritage of France.",
        "wikipedia": "https://fr.wikipedia.org/wiki/Dolmen_de_la_Joseli%C3%A8re",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Pyramid of Cestius",
        "location": "Rome, Italy",
        "coordinates": [41.8763, 12.4813],
        "description": "A pyramid-shaped tomb built for Gaius Cestius, a Roman magistrate.",
        "wikipedia": "https://en.wikipedia.org/wiki/Pyramid_of_Cestius",
        "constructedDate": "c. 12 BC",
        "isUnesco": false
    },
    {
        "name": "Menhir of Fumel",
        "location": "Fumel, France",
        "coordinates": [44.4956, 0.9647],
        "description": "A standing stone in the Lot-et-Garonne department, notable for its size and isolation.",
        "wikipedia": "https://fr.wikipedia.org/wiki/Menhir_de_Fumel",
        "constructedDate": "c. 3500 BC",
        "isUnesco": false
    },
    {
        "name": "The Dwarfie Stane",
        "location": "Hoy, Orkney Islands, Scotland",
        "coordinates": [58.8540, -3.3310],
        "description": "A large sandstone boulder hollowed out to form a chamber, unique in the British Isles.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dwarfie_Stane",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Dolmen of Alcaide",
        "location": "Alentejo, Portugal",
        "coordinates": [38.6984, -8.0414],
        "description": "A prehistoric dolmen found in Portugal, part of a rich megalithic landscape.",
        "wikipedia": "https://pt.wikipedia.org/wiki/Dolmen",
        "constructedDate": "c. 4000 BC",
        "isUnesco": false
    },
    {
        "name": "Kalambo Falls Site",
        "location": "Zambia-Tanzania Border",
        "coordinates": [-8.5971, 31.2412],
        "description": "An archaeological site near Kalambo Falls with evidence of prehistoric human activity.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kalambo_Falls",
        "constructedDate": "Evidence of habitation dates back over 250,000 years",
        "isUnesco": false
    },
    {
        "name": "Old Keig Stone Circle",
        "location": "Aberdeenshire, Scotland",
        "coordinates": [57.3514, -2.7266],
        "description": "A recumbent stone circle in Scotland, featuring a large horizontal stone flanked by upright stones.",
        "wikipedia": "https://en.wikipedia.org/wiki/Old_Keig_Stone_Circle",
        "constructedDate": "c. 2000 BC",
        "isUnesco": false
    },
    {
        "name": "Lanyon Quoit",
        "location": "Cornwall, England",
        "coordinates": [50.1519, -5.6067],
        "description": "A dolmen located in Cornwall, consisting of a large capstone supported by three upright stones.",
        "wikipedia": "https://en.wikipedia.org/wiki/Lanyon_Quoit",
        "constructedDate": "c. 2500 BC",
        "isUnesco": false
    },
    {
        "name": "Nimrud",
        "location": "Nimrud, Iraq",
        "coordinates": [36.1000, 43.3333],
        "description": "An ancient Assyrian city featuring several palaces and temples with large stone reliefs.",
        "wikipedia": "https://en.wikipedia.org/wiki/Nimrud",
        "constructedDate": "c. 900 BC",
        "isUnesco": false
    },
    {
        "name": "Jericho",
        "location": "West Bank, Palestine",
        "coordinates": [31.8667, 35.4500],
        "description": "One of the oldest inhabited cities in the world, with evidence of ancient walls and structures.",
        "wikipedia": "https://en.wikipedia.org/wiki/Jericho",
        "constructedDate": "c. 9000 BC",
        "isUnesco": false
    },
    {
        "name": "Diquís Spheres",
        "location": "Costa Rica",
        "coordinates": [8.7987, -83.6065],
        "description": "A collection of over 300 petrospheres in Costa Rica, created by the Diquís culture.",
        "wikipedia": "https://en.wikipedia.org/wiki/Stone_spheres_of_Costa_Rica",
        "constructedDate": "c. 300 AD",
        "isUnesco": false
    },
    {
        "name": "Yagul",
        "location": "Oaxaca, Mexico",
        "coordinates": [16.9603, -96.5267],
        "description": "A pre-Columbian archaeological site and former city-state in the Valley of Oaxaca.",
        "wikipedia": "https://en.wikipedia.org/wiki/Yagul",
        "constructedDate": "c. 500–1500 AD",
        "isUnesco": false
    },
    {
        "name": "Mount Nemrut",
        "location": "Adıyaman Province, Turkey",
        "coordinates": [37.9807, 38.7411],
        "description": "A monumental tomb sanctuary with massive stone heads and statues, built by King Antiochus I.",
        "wikipedia": "https://en.wikipedia.org/wiki/Mount_Nemrut",
        "constructedDate": "c. 62 BC",
        "isUnesco": false
    },
    {
        "name": "Pyramid of the Moon",
        "location": "Teotihuacan, Mexico",
        "coordinates": [19.6925, -98.8433],
        "description": "A large pyramid located at the end of the Avenue of the Dead in Teotihuacan.",
        "wikipedia": "https://en.wikipedia.org/wiki/Pyramid_of_the_Moon",
        "constructedDate": "c. 200–450 AD",
        "isUnesco": false
    },
    {
        "name": "Beaghmore Stone Circles",
        "location": "County Tyrone, Northern Ireland",
        "coordinates": [54.7675, -6.8972],
        "description": "A complex of stone circles and cairns located in Northern Ireland, noted for its astronomical alignments.",
        "wikipedia": "https://en.wikipedia.org/wiki/Beaghmore",
        "constructedDate": "c. 2000 BC",
        "isUnesco": false
    },
    {
        "name": "Laas Geel",
        "location": "Somaliland",
        "coordinates": [9.6333, 44.0333],
        "description": "A complex of caves and rock shelters with ancient Neolithic rock art.",
        "wikipedia": "https://en.wikipedia.org/wiki/Laas_Geel",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Oyu Stone Circles",
        "location": "Akita Prefecture, Japan",
        "coordinates": [40.3242, 140.4708],
        "description": "A Jomon period archaeological site consisting of two large stone circles.",
        "wikipedia": "https://en.wikipedia.org/wiki/Oyu_Stone_Circles",
        "constructedDate": "c. 2000 BC",
        "isUnesco": false
    },
    {
        "name": "Tombs of Buganda Kings at Kasubi",
        "location": "Kampala, Uganda",
        "coordinates": [0.3136, 32.5644],
        "description": "A UNESCO World Heritage site that is the burial ground for the Kings of Buganda.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tombs_of_Buganda_Kings_at_Kasubi",
        "constructedDate": "c. 1881 AD",
        "isUnesco": false
    },
    {
        "name": "Madain Saleh",
        "location": "Al-‘Ula, Saudi Arabia",
        "coordinates": [26.7968, 37.9538],
        "description": "An ancient Nabatean city with well-preserved rock-cut monumental tombs.",
        "wikipedia": "https://en.wikipedia.org/wiki/Mada%27in_Saleh",
        "constructedDate": "c. 1st century AD",
        "isUnesco": false
    },
    {
        "name": "Sungir",
        "location": "Vladimir Oblast, Russia",
        "coordinates": [56.1910, 40.4864],
        "description": "A significant Paleolithic archaeological site with elaborate burials.",
        "wikipedia": "https://en.wikipedia.org/wiki/Sungir",
        "constructedDate": "c. 32,000–28,000 BC",
        "isUnesco": false
    },
    {
        "name": "Lindholm Høje",
        "location": "Aalborg, Denmark",
        "coordinates": [57.0720, 9.9208],
        "description": "A Viking burial site with stone ship settings and numerous grave mounds.",
        "wikipedia": "https://en.wikipedia.org/wiki/Lindholm_H%C3%B8je",
        "constructedDate": "c. 1000 AD",
        "isUnesco": false
    },
    {
        "name": "Serra da Capivara National Park",
        "location": "Piauí, Brazil",
        "coordinates": [-8.4167, -42.5500],
        "description": "An area with prehistoric rock art and archaeological sites in northeastern Brazil.",
        "wikipedia": "https://en.wikipedia.org/wiki/Serra_da_Capivara_National_Park",
        "constructedDate": "c. 25,000 BC",
        "isUnesco": false
    },
    {
        "name": "Dolmen de Soto",
        "location": "Huelva, Spain",
        "coordinates": [37.3958, -6.5658],
        "description": "A Neolithic dolmen known for its large capstone and inner chamber.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dolmen_of_Soto",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Djenné-Djenno",
        "location": "Mali",
        "coordinates": [13.9060, -4.5534],
        "description": "An ancient city and archaeological site that was a major center of trade.",
        "wikipedia": "https://en.wikipedia.org/wiki/Djenn%C3%A9-Djenno",
        "constructedDate": "c. 250 BC",
        "isUnesco": false
    },
    {
        "name": "The Kofun of Ishiyama",
        "location": "Kyoto, Japan",
        "coordinates": [35.0133, 135.7775],
        "description": "An ancient kofun burial mound in Japan.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kofun",
        "constructedDate": "c. 250–538 AD",
        "isUnesco": false
    },
    {
        "name": "Ingombe Ilede",
        "location": "Southern Province, Zambia",
        "coordinates": [-17.4167, 27.3667],
        "description": "An archaeological site known for its rich finds of Iron Age artifacts and burial sites.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ingombe_Ilede",
        "constructedDate": "c. 700–1100 AD",
        "isUnesco": false
    },
    {
        "name": "Hattusha",
        "location": "Boğazkale, Turkey",
        "coordinates": [40.0200, 34.6195],
        "description": "The ancient capital of the Hittite Empire, known for its massive stone walls and temples.",
        "wikipedia": "https://en.wikipedia.org/wiki/Hattusa",
        "constructedDate": "c. 1600 BC",
        "isUnesco": false
    },
    {
        "name": "Tomb of the Giants Li Muri",
        "location": "Arzachena, Sardinia, Italy",
        "coordinates": [41.0333, 9.4667],
        "description": "An ancient site with multiple stone circles and burial cists dating back to the Ozieri culture.",
        "wikipedia": "https://en.wikipedia.org/wiki/Li_Muri",
        "constructedDate": "c. 3500 BC",
        "isUnesco": false
    },
    {
        "name": "Mnajdra",
        "location": "Qrendi, Malta",
        "coordinates": [35.8312, 14.4472],
        "description": "A complex of megalithic temples on the southern coast of Malta, renowned for its astronomical alignments.",
        "wikipedia": "https://en.wikipedia.org/wiki/Mnajdra",
        "constructedDate": "c. 3600–2500 BC",
        "isUnesco": false
    },
    {
        "name": "Uppåkra Archaeological Site",
        "location": "Skåne, Sweden",
        "coordinates": [55.7178, 13.2325],
        "description": "One of the largest Iron Age settlements in Scandinavia, featuring numerous ancient artifacts and building foundations.",
        "wikipedia": "https://en.wikipedia.org/wiki/Upp%C3%A5kra_Archaeological_Center",
        "constructedDate": "c. 100–1000 AD",
        "isUnesco": false
    },
    {
        "name": "Karakol",
        "location": "Altai Republic, Russia",
        "coordinates": [50.7667, 88.3667],
        "description": "A burial site in the Altai Mountains, known for its megalithic kurgans and petroglyphs.",
        "wikipedia": "https://en.wikipedia.org/wiki/Altai_Mountains",
        "constructedDate": "c. 1000 BC",
        "isUnesco": false
    },
    {
        "name": "Tomb of Agamemnon",
        "location": "Mycenae, Greece",
        "coordinates": [37.7225, 22.7517],
        "description": "A large beehive-shaped tomb or tholos, also known as the Treasury of Atreus.",
        "wikipedia": "https://en.wikipedia.org/wiki/Treasury_of_Atreus",
        "constructedDate": "c. 1250 BC",
        "isUnesco": false
    },
    {
        "name": "Su Nuraxi di Barumini",
        "location": "Sardinia, Italy",
        "coordinates": [39.7053, 8.9906],
        "description": "A well-preserved nuragic complex in Sardinia, consisting of a central tower and a village.",
        "wikipedia": "https://en.wikipedia.org/wiki/Su_Nuraxi_di_Barumini",
        "constructedDate": "c. 1500 BC",
        "isUnesco": false
    },
    {
        "name": "Mohenjo-Daro",
        "location": "Sindh, Pakistan",
        "coordinates": [27.3294, 68.1386],
        "description": "An archaeological site of one of the largest settlements of the ancient Indus Valley Civilization.",
        "wikipedia": "https://en.wikipedia.org/wiki/Mohenjo-daro",
        "constructedDate": "c. 2500 BC",
        "isUnesco": false
    },
    {
        "name": "Ancient City of Damascus",
        "location": "Damascus, Syria",
        "coordinates": [33.5131, 36.2919],
        "description": "One of the oldest continuously inhabited cities in the world, with evidence of habitation since ancient times.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ancient_City_of_Damascus",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Timgad",
        "location": "Batna, Algeria",
        "coordinates": [35.4875, 6.4681],
        "description": "A Roman colonial town founded by Emperor Trajan, known for its grid layout and well-preserved ruins.",
        "wikipedia": "https://en.wikipedia.org/wiki/Timgad",
        "constructedDate": "c. 100 AD",
        "isUnesco": false
    },
    {
        "name": "Jarlshof",
        "location": "Shetland, Scotland",
        "coordinates": [59.8696, -1.3057],
        "description": "An archaeological site on the Shetland Islands with structures from the Neolithic period to the 16th century.",
        "wikipedia": "https://en.wikipedia.org/wiki/Jarlshof",
        "constructedDate": "c. 2500 BC–1600 AD",
        "isUnesco": false
    },
    {
        "name": "Khami Ruins",
        "location": "Zimbabwe",
        "coordinates": [-20.1797, 28.4506],
        "description": "An archaeological site representing the ruins of the capital of the Kingdom of Butua.",
        "wikipedia": "https://en.wikipedia.org/wiki/Khami_Ruins",
        "constructedDate": "c. 1450–1650 AD",
        "isUnesco": false
    },
    {
        "name": "Tiwanaku",
        "location": "La Paz, Bolivia",
        "coordinates": [-16.5532, -68.6791],
        "description": "An ancient city near Lake Titicaca, known for its impressive stone architecture and monumental structures.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tiwanaku",
        "constructedDate": "c. 400 AD",
        "isUnesco": false
    },
    {
        "name": "Kilmartin Glen",
        "location": "Argyll, Scotland",
        "coordinates": [56.1164, -5.4891],
        "description": "An area with numerous ancient monuments, including standing stones, stone circles, and burial cairns.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kilmartin_Glen",
        "constructedDate": "c. 3500 BC",
        "isUnesco": false
    },
    {
        "name": "Bryn Celli Ddu",
        "location": "Anglesey, Wales",
        "coordinates": [53.2198, -4.2614],
        "description": "A Neolithic burial chamber on the Welsh island of Anglesey, known for its passage grave and alignments.",
        "wikipedia": "https://en.wikipedia.org/wiki/Bryn_Celli_Ddu",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Hulbjerg Passage Grave",
        "location": "Langeland, Denmark",
        "coordinates": [54.7933, 10.6367],
        "description": "A Neolithic passage grave located on the island of Langeland, Denmark.",
        "wikipedia": "https://en.wikipedia.org/wiki/Hulbjerg_J%C3%A6ttekammer",
        "constructedDate": "c. 3200 BC",
        "isUnesco": false
    },
    {
        "name": "Wéris Dolmen",
        "location": "Wéris, Belgium",
        "coordinates": [50.3144, 5.5275],
        "description": "A megalithic dolmen in Belgium, part of a larger collection of prehistoric sites in the area.",
        "wikipedia": "https://en.wikipedia.org/wiki/W%C3%A9ris",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Huaca de la Luna",
        "location": "Moche Valley, Peru",
        "coordinates": [-8.1100, -78.9728],
        "description": "A large adobe brick temple built by the Moche civilization, known for its murals and reliefs.",
        "wikipedia": "https://en.wikipedia.org/wiki/Huaca_de_la_Luna",
        "constructedDate": "c. 100–700 AD",
        "isUnesco": false
    },
    {
        "name": "Sannai-Maruyama Site",
        "location": "Aomori, Japan",
        "coordinates": [40.8222, 140.7733],
        "description": "A large and well-preserved Jomon period archaeological site featuring ancient pit dwellings and artifacts.",
        "wikipedia": "https://en.wikipedia.org/wiki/Sannai-Maruyama_site",
        "constructedDate": "c. 3900 BC",
        "isUnesco": false
    },
    {
        "name": "La Blanca",
        "location": "San Marcos, Guatemala",
        "coordinates": [14.5667, -92.0167],
        "description": "An ancient city in Guatemala with monumental architecture and evidence of Preclassic occupation.",
        "wikipedia": "https://en.wikipedia.org/wiki/La_Blanca_(archaeological_site)",
        "constructedDate": "c. 900 BC",
        "isUnesco": false
    },
    {
        "name": "Menhir de Kerloas",
        "location": "Plouarzel, France",
        "coordinates": [48.4528, -4.7650],
        "description": "The tallest standing menhir in Brittany, France, measuring over 9 meters.",
        "wikipedia": "https://en.wikipedia.org/wiki/Menhir_de_Kerloas",
        "constructedDate": "c. 4500 BC",
        "isUnesco": false
    },
    {
        "name": "Silbury Hill",
        "location": "Wiltshire, England",
        "coordinates": [51.4153, -1.8575],
        "description": "The largest artificial mound in Europe, associated with the Avebury complex.",
        "wikipedia": "https://en.wikipedia.org/wiki/Silbury_Hill",
        "constructedDate": "c. 2400 BC",
        "isUnesco": false
    },
    {
        "name": "Great Zimbabwe",
        "location": "Masvingo, Zimbabwe",
        "coordinates": [-20.2671, 30.9335],
        "description": "The capital of the Kingdom of Zimbabwe during the country's Late Iron Age.",
        "wikipedia": "https://en.wikipedia.org/wiki/Great_Zimbabwe",
        "constructedDate": "c. 1100–1450 AD",
        "isUnesco": false
    },
    {
        "name": "Ban Chiang",
        "location": "Udon Thani, Thailand",
        "coordinates": [17.4261, 103.2418],
        "description": "An archaeological site in Thailand with evidence of prehistoric metalworking.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ban_Chiang",
        "constructedDate": "c. 2100 BC",
        "isUnesco": false
    },
    {
        "name": "Cairnpapple Hill",
        "location": "West Lothian, Scotland",
        "coordinates": [55.9230, -3.6645],
        "description": "A ceremonial site with evidence of Neolithic and Bronze Age activities.",
        "wikipedia": "https://en.wikipedia.org/wiki/Cairnpapple_Hill",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Rujm el-Hiri",
        "location": "Golan Heights",
        "coordinates": [32.9028, 35.7828],
        "description": "A megalithic structure consisting of concentric circles of stone with a burial chamber in the center.",
        "wikipedia": "https://en.wikipedia.org/wiki/Rujm_el-Hiri",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Alacahöyük",
        "location": "Çorum Province, Turkey",
        "coordinates": [40.2333, 34.6833],
        "description": "A significant archaeological site in Turkey with Hittite and pre-Hittite artifacts and architecture.",
        "wikipedia": "https://en.wikipedia.org/wiki/Alacah%C3%B6y%C3%BCk",
        "constructedDate": "c. 2500 BC",
        "isUnesco": false
    },
    {
        "name": "Çatalhöyük",
        "location": "Konya Province, Turkey",
        "coordinates": [37.6650, 32.8260],
        "description": "A large Neolithic and Chalcolithic proto-city settlement in southern Anatolia.",
        "wikipedia": "https://en.wikipedia.org/wiki/%C3%87atalh%C3%B6y%C3%BCk",
        "constructedDate": "c. 7500 BC",
        "isUnesco": false
    },
    {
        "name": "Val Camonica Rock Drawings",
        "location": "Lombardy, Italy",
        "coordinates": [45.9549, 10.2732],
        "description": "A collection of petroglyphs depicting over 140,000 symbols and figures.",
        "wikipedia": "https://en.wikipedia.org/wiki/Rock_Drawings_in_Valcamonica",
        "constructedDate": "c. 8000 BC",
        "isUnesco": false
    },
    {
        "name": "Seyitömer Mound",
        "location": "Kütahya, Turkey",
        "coordinates": [39.1343, 29.9336],
        "description": "A Bronze Age mound that contains numerous graves and a rich collection of artifacts.",
        "wikipedia": "https://en.wikipedia.org/wiki/Seyit%C3%B6mer_H%C3%B6y%C3%BC%C4%9F%C3%BC",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Belintash",
        "location": "Rhodope Mountains, Bulgaria",
        "coordinates": [41.8231, 24.8461],
        "description": "A rock sanctuary in the Rhodope Mountains thought to have been used by Thracians.",
        "wikipedia": "https://en.wikipedia.org/wiki/Belintash",
        "constructedDate": "c. 5000 BC",
        "isUnesco": false
    },
    {
        "name": "Dolmen de la Table",
        "location": "Brittany, France",
        "coordinates": [48.6472, -2.1019],
        "description": "A Neolithic dolmen located near the coast of Brittany, known for its massive capstone.",
        "wikipedia": "https://fr.wikipedia.org/wiki/Dolmen_de_la_Table",
        "constructedDate": "c. 4000 BC",
        "isUnesco": false
    },
    {
        "name": "Güvercinlik Cave",
        "location": "Antalya, Turkey",
        "coordinates": [37.0000, 30.0000],
        "description": "An archaeological site in Turkey, notable for its Neolithic carvings and remains.",
        "wikipedia": "https://en.wikipedia.org/wiki/G%C3%BCvercinlik_Cave",
        "constructedDate": "c. 5000 BC",
        "isUnesco": false
    },
    {
        "name": "Wassu Stone Circles",
        "location": "Central River, The Gambia",
        "coordinates": [13.6917, -14.8833],
        "description": "Part of the Senegambian stone circles, thought to have been used as burial sites.",
        "wikipedia": "https://en.wikipedia.org/wiki/Wassu_Stone_Circles",
        "constructedDate": "c. 750–1000 AD",
        "isUnesco": false
    },
    {
        "name": "Malinalco",
        "location": "State of Mexico, Mexico",
        "coordinates": [18.9578, -99.4931],
        "description": "An archaeological site with monolithic carvings and structures, used for Aztec religious ceremonies.",
        "wikipedia": "https://en.wikipedia.org/wiki/Malinalco",
        "constructedDate": "c. 1500 AD",
        "isUnesco": false
    },
    {
        "name": "Tula Archaeological Site",
        "location": "Hidalgo, Mexico",
        "coordinates": [20.0547, -99.3398],
        "description": "Known for its large basalt warrior columns and Toltec artifacts.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tula_(Mesoamerican_site)",
        "constructedDate": "c. 900–1150 AD",
        "isUnesco": false
    },
    {
        "name": "Enkomi",
        "location": "Famagusta, Cyprus",
        "coordinates": [35.1250, 33.8917],
        "description": "An ancient city with a variety of ruins including stone fortifications and Bronze Age artifacts.",
        "wikipedia": "https://en.wikipedia.org/wiki/Enkomi",
        "constructedDate": "c. 1600 BC",
        "isUnesco": false
    },
    {
        "name": "Caral",
        "location": "Lima Region, Peru",
        "coordinates": [-10.8920, -77.5197],
        "description": "One of the oldest urban centers in the Americas, featuring large stone pyramids.",
        "wikipedia": "https://en.wikipedia.org/wiki/Caral",
        "constructedDate": "c. 2600 BC",
        "isUnesco": false
    },
    {
        "name": "Hulbjerg Passage Grave",
        "location": "Langeland, Denmark",
        "coordinates": [54.7948, 10.6722],
        "description": "A passage grave located on the island of Langeland, Denmark.",
        "wikipedia": "https://en.wikipedia.org/wiki/Hulbjerg_J%C3%A6ttekammer",
        "constructedDate": "c. 3200 BC",
        "isUnesco": false
    },
    {
        "name": "Kalavasos-Tenta",
        "location": "Kalavasos, Cyprus",
        "coordinates": [34.7525, 33.303056],
        "description": "An archaeological site representing a Neolithic settlement with circular mud-brick buildings.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tenta,_Cyprus",
        "constructedDate": "c. 7000 BC",
        "isUnesco": false
    },
    {
        "name": "Choirokoitia",
        "location": "Choirokoitia, Cyprus",
        "coordinates": [34.7879, 33.3375],
        "description": "A well-preserved archaeological site of a Neolithic settlement in Cyprus.",
        "wikipedia": "https://en.wikipedia.org/wiki/Choirokoitia",
        "constructedDate": "c. 7000–4000 BC",
        "isUnesco": false
    },
    {
        "name": "Phaistos",
        "location": "Heraklion, Crete, Greece",
        "coordinates": [35.0589, 24.8191],
        "description": "An archaeological site and palace complex on Crete, famous for the Phaistos Disc, which contains one of the earliest known forms of written language.",
        "wikipedia": "https://en.wikipedia.org/wiki/Phaistos",
        "constructedDate": "c. 2000–1700 BC",
        "isUnesco": false
    },
    {
        "name": "Kommos",
        "location": "Heraklion, Crete, Greece",
        "coordinates": [35.0128, 24.7686],
        "description": "An ancient Minoan harbor town, with significant archaeological findings including structures and pottery.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kommos",
        "constructedDate": "c. 2000 BC",
        "isUnesco": false
    },
    {
        "name": "Archanes",
        "location": "Heraklion, Crete, Greece",
        "coordinates": [35.2239, 25.1444],
        "description": "An important Minoan settlement near the palace of Knossos, known for its archaeological remains.",
        "wikipedia": "https://en.wikipedia.org/wiki/Archanes",
        "constructedDate": "c. 2500 BC",
        "isUnesco": false
    },
    {
        "name": "Gournia",
        "location": "Lassithi, Crete, Greece",
        "coordinates": [35.1019, 25.8006],
        "description": "A well-preserved Minoan town, featuring streets, houses, and a central palace.",
        "wikipedia": "https://en.wikipedia.org/wiki/Gournia",
        "constructedDate": "c. 1500 BC",
        "isUnesco": false
    },
    {
        "name": "Zakros Palace",
        "location": "Lassithi, Crete, Greece",
        "coordinates": [35.0890, 26.2627],
        "description": "The site of a Minoan palace on Crete, known for its architecture and artifacts.",
        "wikipedia": "https://en.wikipedia.org/wiki/Zakros",
        "constructedDate": "c. 1900 BC",
        "isUnesco": false
    },
    {
        "name": "Hirebenakal",
        "location": "Koppal, Karnataka, India",
        "coordinates": [15.3548, 76.3876],
        "description": "An ancient megalithic site known for its numerous dolmens and burial structures.",
        "wikipedia": "https://en.wikipedia.org/wiki/Hirebenakal",
        "constructedDate": "c. 800 BC",
        "isUnesco": false
    },
    {
        "name": "Chhattisgarh Megaliths",
        "location": "Bastar, Chhattisgarh, India",
        "coordinates": [19.1071, 81.9535],
        "description": "Megalithic sites featuring stone alignments and burial monuments, significant to local tribal communities.",
        "wikipedia": "https://en.wikipedia.org/wiki/Megaliths_in_India",
        "constructedDate": "c. 1000–500 BC",
        "isUnesco": false
    },
    {
        "name": "Mahurjhari",
        "location": "Nagpur, Maharashtra, India",
        "coordinates": [21.1466, 79.0889],
        "description": "An archaeological site with a large number of megalithic burial structures, including stone circles and dolmens.",
        "wikipedia": "https://en.wikipedia.org/wiki/Megaliths_in_India",
        "constructedDate": "c. 1000 BC",
        "isUnesco": false
    },
    {
        "name": "Sanganakallu",
        "location": "Bellary, Karnataka, India",
        "coordinates": [15.1295, 76.9193],
        "description": "A Neolithic site known for its ancient settlements and megalithic structures.",
        "wikipedia": "https://en.wikipedia.org/wiki/Sanganakallu",
        "constructedDate": "c. 3000–2000 BC",
        "isUnesco": false
    },
    {
        "name": "Pandu Rajar Dhibi",
        "location": "West Bengal, India",
        "coordinates": [23.0046, 87.8929],
        "description": "An archaeological site with evidence of early settlements and megalithic practices.",
        "wikipedia": "https://en.wikipedia.org/wiki/Pandu_Rajar_Dhibi",
        "constructedDate": "c. 1000 BC",
        "isUnesco": false
    },
    {
        "name": "Byse",
        "location": "Kundapur, Karnataka, India",
        "coordinates": [13.6325, 74.6662],
        "description": "A lesser-known site with a variety of megalithic structures including dolmens and stone circles.",
        "wikipedia": "https://en.wikipedia.org/wiki/Byse",
        "constructedDate": "c. 1000 BC",
        "isUnesco": false
    },
    {
        "name": "Nilaskal",
        "location": "Karnataka, India",
        "coordinates": [13.5815, 75.2663],
        "description": "Megalithic site featuring a variety of stone structures, including dolmens and menhirs.",
        "wikipedia": "https://en.wikipedia.org/wiki/Megaliths_in_India",
        "constructedDate": "c. 800 BC",
        "isUnesco": false
    },
    {
        "name": "Porkalam Dolmens",
        "location": "Thrissur, Kerala, India",
        "coordinates": [10.7453, 76.2721],
        "description": "Ancient dolmen structures located in the district of Thrissur, Kerala, featuring stone burial chambers.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dolmen",
        "constructedDate": "c. 1000–500 BC",
        "isUnesco": false
    },
    {
        "name": "Jorwe Megalithic Sites",
        "location": "Ahmednagar, Maharashtra, India",
        "coordinates": [19.0833, 74.7333],
        "description": "A collection of megalithic sites known for their complex burial practices and stone circles.",
        "wikipedia": "https://en.wikipedia.org/wiki/Jorwe_culture",
        "constructedDate": "c. 1300–700 BC",
        "isUnesco": false
    },
    {
        "name": "Bhimbetka Rock Shelters",
        "location": "Raisen, Madhya Pradesh, India",
        "coordinates": [22.9351, 77.6126],
        "description": "A UNESCO World Heritage site with prehistoric rock paintings and evidence of early human life.",
        "wikipedia": "https://en.wikipedia.org/wiki/Bhimbetka_rock_shelters",
        "constructedDate": "c. 30,000 BC",
        "isUnesco": false
    },
    {
        "name": "Kudakkallu Parambu",
        "location": "Thrissur, Kerala, India",
        "coordinates": [10.5867, 76.2414],
        "description": "A prehistoric megalithic site featuring umbrella stones, dolmens, and burial urns.",
        "wikipedia": "https://en.wikipedia.org/wiki/Megaliths_in_Kerala",
        "constructedDate": "c. 2000 BC",
        "isUnesco": false
    },
    {
        "name": "Chopani Mando",
        "location": "Allahabad, Uttar Pradesh, India",
        "coordinates": [25.3700, 82.9800],
        "description": "An ancient site with evidence of the Mesolithic and early Neolithic period settlements.",
        "wikipedia": "https://en.wikipedia.org/wiki/Chopani_Mando",
        "constructedDate": "c. 7000 BC",
        "isUnesco": false
    },
    {
        "name": "Paiyampalli",
        "location": "Vellore, Tamil Nadu, India",
        "coordinates": [12.5739, 78.5733],
        "description": "An archaeological site featuring megalithic burials and early Iron Age settlements.",
        "wikipedia": "https://en.wikipedia.org/wiki/Paiyampalli",
        "constructedDate": "c. 1000–300 BC",
        "isUnesco": false
    },
    {
        "name": "Vangchhia",
        "location": "Champhai, Mizoram, India",
        "coordinates": [23.3441, 93.3256],
        "description": "Megalithic site featuring stone structures and carvings that indicate the presence of ancient settlements.",
        "wikipedia": "https://en.wikipedia.org/wiki/Megaliths_in_India",
        "constructedDate": "c. 500 BC",
        "isUnesco": false
    },
    {
        "name": "Nartiang Monoliths",
        "location": "West Jaintia Hills, Meghalaya, India",
        "coordinates": [25.6023, 92.3151],
        "description": "A significant megalithic site with a large collection of monoliths used for ceremonial purposes.",
        "wikipedia": "https://en.wikipedia.org/wiki/Nartiang_Megaliths",
        "constructedDate": "c. 16th century AD",
        "isUnesco": false
    },
    {
        "name": "Sanur Megaliths",
        "location": "Tumkur, Karnataka, India",
        "coordinates": [13.3382, 77.1017],
        "description": "Megalithic structures in the form of dolmens and stone circles, indicative of ancient burial practices.",
        "wikipedia": "https://en.wikipedia.org/wiki/Sanur,_Karnataka",
        "constructedDate": "c. 1000 BC",
        "isUnesco": false
    },
    {
        "name": "Tekkalakota",
        "location": "Ballari, Karnataka, India",
        "coordinates": [15.4700, 76.9300],
        "description": "A megalithic site featuring dolmens, stone circles, and burial sites, providing insight into ancient cultures.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tekkalakota",
        "constructedDate": "c. 1200–600 BC",
        "isUnesco": false
    },
    {
        "name": "Uttara Kannada Dolmens",
        "location": "Uttara Kannada, Karnataka, India",
        "coordinates": [14.7761, 74.4785],
        "description": "Megalithic dolmens and stone alignments found in the forests of Uttara Kannada.",
        "wikipedia": "https://en.wikipedia.org/wiki/Megaliths_in_India",
        "constructedDate": "c. 1000–500 BC",
        "isUnesco": false
    },
    {
        "name": "Heggade Halli Dolmens",
        "location": "Shivamogga, Karnataka, India",
        "coordinates": [14.1113, 75.6986],
        "description": "A collection of ancient dolmens and stone circles located in the hills of Shivamogga district.",
        "wikipedia": "https://en.wikipedia.org/wiki/Megaliths_in_India",
        "constructedDate": "c. 1200 BC",
        "isUnesco": false
    },
    {
        "name": "Hiregudda",
        "location": "Hampi, Karnataka, India",
        "coordinates": [15.3350, 76.4622],
        "description": "An archaeological site with megalithic burial structures and ancient rock art.",
        "wikipedia": "https://en.wikipedia.org/wiki/Hampi",
        "constructedDate": "c. 1000 BC",
        "isUnesco": false
    },
    {
        "name": "Megaliths of Kurumbanmoozhy",
        "location": "Pathanamthitta, Kerala, India",
        "coordinates": [9.2613, 76.7841],
        "description": "A site featuring dolmens and menhirs, believed to have been used for burial and ritual purposes.",
        "wikipedia": "https://en.wikipedia.org/wiki/Megaliths_in_India",
        "constructedDate": "c. 2000–1000 BC",
        "isUnesco": false
    },
    {
        "name": "Sidlaphadi Cave",
        "location": "Badami, Karnataka, India",
        "coordinates": [15.9183, 75.6945],
        "description": "A rock shelter with evidence of prehistoric habitation and rock art.",
        "wikipedia": "https://en.wikipedia.org/wiki/Badami",
        "constructedDate": "c. 2000 BC",
        "isUnesco": false
    },
    {
        "name": "Sanganakallu",
        "location": "Bellary, Karnataka, India",
        "coordinates": [15.1295, 76.9193],
        "description": "One of the largest Neolithic sites in India, known for its ancient settlements and rock art.",
        "wikipedia": "https://en.wikipedia.org/wiki/Sanganakallu",
        "constructedDate": "c. 3000–2000 BC",
        "isUnesco": false
    },
    {
        "name": "Kupgal Petroglyphs",
        "location": "Bellary, Karnataka, India",
        "coordinates": [15.1675, 76.8485],
        "description": "An archaeological site with rock engravings dating back to the Neolithic period.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kupgal_petroglyphs",
        "constructedDate": "c. 2000 BC",
        "isUnesco": false
    },
    {
        "name": "Vibhuthihalli Megaliths",
        "location": "Yadgir, Karnataka, India",
        "coordinates": [16.7638, 76.8069],
        "description": "A site known for its ancient megalithic structures, including dolmens and burial mounds.",
        "wikipedia": "https://en.wikipedia.org/wiki/Megaliths_in_India",
        "constructedDate": "c. 1000 BC",
        "isUnesco": false
    },
    {
        "name": "Goseong Dolmens",
        "location": "Goseong County, South Korea",
        "coordinates": [34.9845, 128.3247],
        "description": "Part of the Gochang, Hwasun, and Ganghwa Dolmen Sites, a UNESCO World Heritage site featuring numerous dolmens.",
        "wikipedia": "https://en.wikipedia.org/wiki/Gochang,_Hwasun_and_Ganghwa_Dolmen_Sites",
        "constructedDate": "c. 1000–300 BC",
        "isUnesco": false
    },
    {
        "name": "Nias Megalithic Culture",
        "location": "Nias Island, Indonesia",
        "coordinates": [1.1097, 97.5200],
        "description": "Nias is known for its megalithic culture with stone monuments and structures that are still used in traditional ceremonies.",
        "wikipedia": "https://en.wikipedia.org/wiki/Nias",
        "constructedDate": "c. 500 BC",
        "isUnesco": false
    },
    {
        "name": "Dholavira",
        "location": "Gujarat, India",
        "coordinates": [23.8859, 70.3671],
        "description": "An archaeological site of the Harappan civilization, known for its unique water conservation system and urban planning.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dholavira",
        "constructedDate": "c. 2650 BC",
        "isUnesco": false
    },
    {
        "name": "Lopburi",
        "location": "Lopburi, Thailand",
        "coordinates": [14.7995, 100.6535],
        "description": "Ancient ruins and stone structures from the Dvaravati, Khmer, and Ayutthaya periods.",
        "wikipedia": "https://en.wikipedia.org/wiki/Lopburi",
        "constructedDate": "c. 600–1400 AD",
        "isUnesco": false
    },
    {
        "name": "Nan Madol",
        "location": "Pohnpei, Micronesia",
        "coordinates": [6.8419, 158.3358],
        "description": "A ruined city adjacent to the eastern shore of the island of Pohnpei, built on a series of small artificial islands linked by a network of canals.",
        "wikipedia": "https://en.wikipedia.org/wiki/Nan_Madol",
        "constructedDate": "c. 1200 AD",
        "isUnesco": false
    },
    {
        "name": "Thimlich Ohinga",
        "location": "Migori County, Kenya",
        "coordinates": [-0.8463, 34.3289],
        "description": "A complex of stone-built ruins believed to have been constructed by communities as early as the 16th century.",
        "wikipedia": "https://en.wikipedia.org/wiki/Thimlich_Ohinga",
        "constructedDate": "c. 16th century AD",
        "isUnesco": false
    },
    {
        "name": "Menhir de Valbelle",
        "location": "Valbelle, France",
        "coordinates": [44.1691, 5.8828],
        "description": "A prehistoric standing stone located in the French region of Provence-Alpes-Côte d'Azur.",
        "wikipedia": "https://en.wikipedia.org/wiki/Menhir",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Jaulian Monastery",
        "location": "Taxila, Pakistan",
        "coordinates": [33.7451, 72.8194],
        "description": "A Buddhist monastery and stupa complex located near Taxila, known for its stone architecture and reliefs.",
        "wikipedia": "https://en.wikipedia.org/wiki/Jaulian",
        "constructedDate": "c. 2nd century AD",
        "isUnesco": false
    },
    {
        "name": "Gunung Padang Megalithic Site",
        "location": "Cianjur, West Java, Indonesia",
        "coordinates": [-6.9948, 107.0572],
        "description": "A megalithic site in Indonesia thought to be one of the largest in Southeast Asia.",
        "wikipedia": "https://en.wikipedia.org/wiki/Gunung_Padang_megalithic_site",
        "constructedDate": "c. 5000 BC",
        "isUnesco": false
    },
    {
        "name": "Phnom Kulen",
        "location": "Siem Reap Province, Cambodia",
        "coordinates": [13.5161, 104.1166],
        "description": "An ancient hilltop site with megalithic features, waterfalls, and the reclining Buddha.",
        "wikipedia": "https://en.wikipedia.org/wiki/Phnom_Kulen",
        "constructedDate": "c. 9th century AD",
        "isUnesco": false
    },
    {
        "name": "Plain of Jars",
        "location": "Xieng Khouang, Laos",
        "coordinates": [19.4404, 103.1520],
        "description": "A landscape in Laos featuring thousands of stone jars of varying sizes.",
        "wikipedia": "https://en.wikipedia.org/wiki/Plain_of_Jars",
        "constructedDate": "c. 500 BC–500 AD",
        "isUnesco": false
    },
    {
        "name": "Hongshan Culture Megaliths",
        "location": "Inner Mongolia, China",
        "coordinates": [42.0191, 119.2037],
        "description": "Megalithic structures attributed to the Hongshan culture, featuring stone circles and altars.",
        "wikipedia": "https://en.wikipedia.org/wiki/Hongshan_culture",
        "constructedDate": "c. 4700–2900 BC",
        "isUnesco": false
    },
    {
        "name": "Chandraketugarh",
        "location": "West Bengal, India",
        "coordinates": [22.6900, 88.6200],
        "description": "An archaeological site in India with ancient fortifications and megalithic structures.",
        "wikipedia": "https://en.wikipedia.org/wiki/Chandraketugarh",
        "constructedDate": "c. 400 BC–100 AD",
        "isUnesco": false
    },
    {
        "name": "Megalithic Site of Marayoor",
        "location": "Idukki, Kerala, India",
        "coordinates": [10.2766, 77.1616],
        "description": "Dolmens and rock shelters in the Western Ghats, indicative of ancient burial practices.",
        "wikipedia": "https://en.wikipedia.org/wiki/Marayur",
        "constructedDate": "c. 1000 BC",
        "isUnesco": false
    },
    {
        "name": "Chengte (Chengde) Megaliths",
        "location": "Hebei Province, China",
        "coordinates": [40.9739, 117.9338],
        "description": "Megalithic structures located in the region known for its historical significance and heritage.",
        "wikipedia": "https://en.wikipedia.org/wiki/Chengde",
        "constructedDate": "c. 2000 BC",
        "isUnesco": false
    },
    {
        "name": "Banpo Neolithic Village",
        "location": "Xi'an, China",
        "coordinates": [34.2740, 109.0140],
        "description": "An archaeological site and Neolithic village showcasing early Chinese settlement patterns.",
        "wikipedia": "https://en.wikipedia.org/wiki/Banpo",
        "constructedDate": "c. 4500–3750 BC",
        "isUnesco": false
    },
    {
        "name": "Seongsan Ilchulbong",
        "location": "Jeju Island, South Korea",
        "coordinates": [33.4610, 126.9415],
        "description": "A tuff cone formed by hydrovolcanic eruptions, with megalithic elements present.",
        "wikipedia": "https://en.wikipedia.org/wiki/Seongsan_Ilchulbong",
        "constructedDate": "Natural formation with ancient human modification",
        "isUnesco": false
    },
    {
        "name": "Hamanaka Stone Circle",
        "location": "Hokkaido, Japan",
        "coordinates": [43.9122, 144.1672],
        "description": "A stone circle site in the northern part of Japan, dating back to the late Jomon period.",
        "wikipedia": "https://en.wikipedia.org/wiki/Jomon_period",
        "constructedDate": "c. 1500 BC",
        "isUnesco": false
    },
    {
        "name": "Yangshan Quarry",
        "location": "Nanjing, China",
        "coordinates": [32.0986, 118.8613],
        "description": "An ancient stone quarry used during the Ming dynasty, known for its unfinished stele.",
        "wikipedia": "https://en.wikipedia.org/wiki/Yangshan_Quarry",
        "constructedDate": "c. 1405 AD",
        "isUnesco": false
    },
    {
        "name": "Banteay Chhmar",
        "location": "Banteay Meanchey, Cambodia",
        "coordinates": [14.0451, 103.1128],
        "description": "A large temple complex from the Angkor period, featuring extensive bas-reliefs.",
        "wikipedia": "https://en.wikipedia.org/wiki/Banteay_Chhmar",
        "constructedDate": "c. 12th century AD",
        "isUnesco": false
    },
    {
        "name": "Plain of Jars",
        "location": "Xieng Khouang, Laos",
        "coordinates": [19.4404, 103.1520],
        "description": "A landscape in Laos dotted with thousands of stone jars of varying sizes.",
        "wikipedia": "https://en.wikipedia.org/wiki/Plain_of_Jars",
        "constructedDate": "c. 500 BC–500 AD",
        "isUnesco": false
    },
    {
        "name": "Jeongok Prehistory Museum",
        "location": "Yeoncheon, South Korea",
        "coordinates": [38.0181, 127.0715],
        "description": "A museum showcasing artifacts from the Paleolithic period in Korea, with nearby archaeological sites.",
        "wikipedia": "https://en.wikipedia.org/wiki/Jeongok_Prehistory_Museum",
        "constructedDate": "Exhibition of ancient artifacts",
        "isUnesco": false
    },
    {
        "name": "Padah-Lin Caves",
        "location": "Shan State, Myanmar",
        "coordinates": [20.7300, 96.6400],
        "description": "A series of caves featuring prehistoric rock art and stone tools, indicative of early human activity.",
        "wikipedia": "https://en.wikipedia.org/wiki/Padah-Lin_Caves",
        "constructedDate": "c. 13,000 BC",
        "isUnesco": false
    },
    {
        "name": "Hongshan Culture Megaliths",
        "location": "Inner Mongolia, China",
        "coordinates": [42.0191, 119.2037],
        "description": "Megalithic structures attributed to the Hongshan culture, featuring stone circles and altars.",
        "wikipedia": "https://en.wikipedia.org/wiki/Hongshan_culture",
        "constructedDate": "c. 4700–2900 BC",
        "isUnesco": false
    },
    {
        "name": "Bakong Temple",
        "location": "Siem Reap Province, Cambodia",
        "coordinates": [13.3473, 103.9702],
        "description": "A pyramid-shaped temple in the Roluos group of temples, a precursor to the Angkor Wat complex.",
        "wikipedia": "https://en.wikipedia.org/wiki/Bakong",
        "constructedDate": "c. 881 AD",
        "isUnesco": false
    },
    {
        "name": "Baijnath Temple Complex",
        "location": "Himachal Pradesh, India",
        "coordinates": [32.0482, 76.6560],
        "description": "An ancient complex with megalithic stone structures and temples.",
        "wikipedia": "https://en.wikipedia.org/wiki/Baijnath,_Himachal_Pradesh",
        "constructedDate": "c. 1204 AD",
        "isUnesco": false
    },
    {
        "name": "Karakol Culture",
        "location": "Siberia, Russia",
        "coordinates": [52.5200, 90.0852],
        "description": "Megalithic structures related to the Karakol culture in Siberia.",
        "wikipedia": "https://en.wikipedia.org/wiki/Karakol_Culture",
        "constructedDate": "c. 3000–2000 BC",
        "isUnesco": false
    },
    {
        "name": "Toba Batak Megaliths",
        "location": "Sumatra, Indonesia",
        "coordinates": [2.6628, 98.8753],
        "description": "Stone monuments and sarcophagi of the Toba Batak people.",
        "wikipedia": "https://en.wikipedia.org/wiki/Toba_Batak",
        "constructedDate": "c. 600 AD",
        "isUnesco": false
    },
    {
        "name": "Jungbang Sanseong",
        "location": "South Chungcheong, South Korea",
        "coordinates": [36.5651, 126.9930],
        "description": "Ancient stone fortress with megalithic construction.",
        "wikipedia": "https://en.wikipedia.org/wiki/Jungbang_Sanseong",
        "constructedDate": "c. 7th century AD",
        "isUnesco": false
    },
    {
        "name": "Hatusua Megaliths",
        "location": "Seram Island, Indonesia",
        "coordinates": [-3.2347, 128.2894],
        "description": "A megalithic site with stone tombs and carved figures.",
        "wikipedia": "https://en.wikipedia.org/wiki/Seram_Island",
        "constructedDate": "c. 2000 BC",
        "isUnesco": false
    },
    {
        "name": "Muang Fa Daet",
        "location": "Kalasin, Thailand",
        "coordinates": [16.4336, 103.5064],
        "description": "An ancient settlement with numerous megalithic structures.",
        "wikipedia": "https://en.wikipedia.org/wiki/Muang_Fa_Daet",
        "constructedDate": "c. 7th century AD",
        "isUnesco": false
    },
    {
        "name": "Seonamsa Stone Pagoda",
        "location": "Gwangyang, South Korea",
        "coordinates": [34.9194, 127.5542],
        "description": "A stone pagoda at Seonamsa Temple, known for its megalithic elements.",
        "wikipedia": "https://en.wikipedia.org/wiki/Seonamsa",
        "constructedDate": "c. 529 AD",
        "isUnesco": false
    },
    {
        "name": "Luoyang Ancient Tombs",
        "location": "Luoyang, China",
        "coordinates": [34.6570, 112.4425],
        "description": "A complex of ancient tombs with megalithic construction.",
        "wikipedia": "https://en.wikipedia.org/wiki/Luoyang",
        "constructedDate": "c. 771 BC–221 AD",
        "isUnesco": false
    },
    {
        "name": "Gua Sireh",
        "location": "Sarawak, Malaysia",
        "coordinates": [1.3500, 110.2830],
        "description": "A limestone cave site with ancient megalithic carvings.",
        "wikipedia": "https://en.wikipedia.org/wiki/Sarawak",
        "constructedDate": "c. 1000 BC",
        "isUnesco": false
    },
    {
        "name": "Megalithic Site of Tondo",
        "location": "West Java, Indonesia",
        "coordinates": [-6.4100, 107.6000],
        "description": "Stone structures and ancient monuments from the Sunda Kingdom.",
        "wikipedia": "https://en.wikipedia.org/wiki/Sunda_Kingdom",
        "constructedDate": "c. 7th century AD",
        "isUnesco": false
    },
    {
        "name": "Wurdi Youang",
        "location": "Victoria, Australia",
        "coordinates": [-37.8920, 144.3540],
        "description": "An ancient Aboriginal stone arrangement that aligns with the solstices.",
        "wikipedia": "https://en.wikipedia.org/wiki/Wurdi_Youang",
        "constructedDate": "c. 2000 BC",
        "isUnesco": false
    },
    {
        "name": "Murujuga",
        "location": "Western Australia, Australia",
        "coordinates": [-20.7451, 116.7912],
        "description": "The Burrup Peninsula, home to thousands of ancient Aboriginal rock engravings.",
        "wikipedia": "https://en.wikipedia.org/wiki/Murujuga",
        "constructedDate": "c. 30,000 BC",
        "isUnesco": false
    },
    {
        "name": "Lake Condah Stone Structures",
        "location": "Victoria, Australia",
        "coordinates": [-38.0700, 141.8789],
        "description": "Stone aquaculture systems created by the Gunditjmara people for eel farming.",
        "wikipedia": "https://en.wikipedia.org/wiki/Budj_Bim",
        "constructedDate": "c. 6600 BC",
        "isUnesco": false
    },
    {
        "name": "Brewarrina Fish Traps",
        "location": "New South Wales, Australia",
        "coordinates": [-29.9617, 146.8644],
        "description": "An ancient Aboriginal fish trap complex in the Barwon River.",
        "wikipedia": "https://en.wikipedia.org/wiki/Brewarrina,_New_South_Wales#Fish_Traps",
        "constructedDate": "c. 40,000 BC",
        "isUnesco": false
    },
    {
        "name": "Ngaut Ngaut",
        "location": "South Australia, Australia",
        "coordinates": [-34.5700, 139.6300],
        "description": "An archaeological site with rock art and evidence of ancient habitation.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ngaut_Ngaut_Conservation_Park",
        "constructedDate": "c. 5000 BC",
        "isUnesco": false
    },
    {
        "name": "Grampians Petroglyphs",
        "location": "Victoria, Australia",
        "coordinates": [-37.2000, 142.4167],
        "description": "Rock art engravings and paintings in the Grampians National Park.",
        "wikipedia": "https://en.wikipedia.org/wiki/Grampians_National_Park",
        "constructedDate": "c. 22,000 BC",
        "isUnesco": false
    },
    {
        "name": "Mumbulla Mountain",
        "location": "New South Wales, Australia",
        "coordinates": [-36.5263, 149.9308],
        "description": "A sacred site for the Yuin people, featuring ancient ceremonial grounds.",
        "wikipedia": "https://en.wikipedia.org/wiki/Mumbulla_Mountain",
        "constructedDate": "Cultural significance since ancient times",
        "isUnesco": false
    },
    {
        "name": "Koonalda Cave",
        "location": "Nullarbor Plain, South Australia",
        "coordinates": [-31.4225, 129.0705],
        "description": "A cave site with some of the oldest evidence of human activity in Australia, including ancient engravings.",
        "wikipedia": "https://en.wikipedia.org/wiki/Koonalda_Cave",
        "constructedDate": "c. 22,000 BC",
        "isUnesco": false
    },
    {
        "name": "Devil's Marbles",
        "location": "Northern Territory, Australia",
        "coordinates": [-20.5630, 134.2575],
        "description": "A geological formation of large granite boulders, important in Aboriginal mythology.",
        "wikipedia": "https://en.wikipedia.org/wiki/Karlu_Karlu",
        "constructedDate": "Natural formation, significant in Aboriginal culture",
        "isUnesco": false
    },
    {
        "name": "Baiame's Ngunnhu",
        "location": "New South Wales, Australia",
        "coordinates": [-30.9067, 147.7264],
        "description": "An ancient fish trap complex built by the local Aboriginal people, possibly over 40,000 years old.",
        "wikipedia": "https://en.wikipedia.org/wiki/Baiame%27s_Ngunnhu",
        "constructedDate": "c. 40,000 BC",
        "isUnesco": false
    },
    {
        "name": "Bunjil's Shelter",
        "location": "Grampians National Park, Victoria, Australia",
        "coordinates": [-37.2350, 142.4656],
        "description": "A rock shelter featuring Aboriginal rock art depicting the creator spirit Bunjil.",
        "wikipedia": "https://en.wikipedia.org/wiki/Bunjil%27s_Shelter",
        "constructedDate": "Ancient Aboriginal",
        "isUnesco": false
    },
    {
        "name": "Yengo National Park Rock Engravings",
        "location": "New South Wales, Australia",
        "coordinates": [-32.8893, 150.7539],
        "description": "A collection of Aboriginal rock engravings found throughout Yengo National Park.",
        "wikipedia": "https://en.wikipedia.org/wiki/Yengo_National_Park",
        "constructedDate": "Ancient Aboriginal",
        "isUnesco": false
    },
    {
        "name": "Mount Grenfell Historic Site",
        "location": "New South Wales, Australia",
        "coordinates": [-31.3678, 145.0172],
        "description": "A site featuring ancient Aboriginal rock art, including hand stencils and animal figures.",
        "wikipedia": "https://en.wikipedia.org/wiki/Mount_Grenfell_Historic_Site",
        "constructedDate": "Ancient Aboriginal",
        "isUnesco": false
    },
    {
        "name": "Mutawintji Historic Site",
        "location": "New South Wales, Australia",
        "coordinates": [-31.2678, 142.4006],
        "description": "A culturally significant area with Aboriginal rock engravings and paintings.",
        "wikipedia": "https://en.wikipedia.org/wiki/Mutawintji_National_Park",
        "constructedDate": "Ancient Aboriginal",
        "isUnesco": false
    },
    {
        "name": "Ewaninga Rock Carvings Conservation Reserve",
        "location": "Northern Territory, Australia",
        "coordinates": [-24.2270, 134.1405],
        "description": "A reserve featuring petroglyphs created by the Arrernte people, depicting various symbols and figures.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ewaninga_Rock_Carvings_Conservation_Reserve",
        "constructedDate": "Ancient Aboriginal",
        "isUnesco": false
    },
    {
        "name": "Ngaut Ngaut Conservation Park",
        "location": "South Australia, Australia",
        "coordinates": [-34.5650, 139.6203],
        "description": "A site with rock art and archaeological evidence of ancient Aboriginal habitation.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ngaut_Ngaut_Conservation_Park",
        "constructedDate": "Ancient Aboriginal",
        "isUnesco": false
    },
    {
        "name": "Meringo Rock Shelter",
        "location": "New South Wales, Australia",
        "coordinates": [-35.9000, 150.0333],
        "description": "A rock shelter containing Aboriginal paintings and engravings.",
        "wikipedia": "https://en.wikipedia.org/wiki/Aboriginal_Australian_art",
        "constructedDate": "Ancient Aboriginal",
        "isUnesco": false
    },
    {
        "name": "Quinkan Rock Art",
        "location": "Queensland, Australia",
        "coordinates": [-15.3200, 144.2500],
        "description": "A UNESCO-listed site with extensive Aboriginal rock art, considered among the world's top ten rock art areas.",
        "wikipedia": "https://en.wikipedia.org/wiki/Quinkan_rock_art",
        "constructedDate": "Ancient Aboriginal",
        "isUnesco": false
    },
    {
        "name": "Great Zimbabwe",
        "location": "Masvingo, Zimbabwe",
        "coordinates": [-20.2671, 30.9335],
        "description": "The capital of the Kingdom of Zimbabwe during the country's Late Iron Age.",
        "wikipedia": "https://en.wikipedia.org/wiki/Great_Zimbabwe",
        "constructedDate": "c. 1100–1450 AD",
        "isUnesco": false
    },
    {
        "name": "Tomb of the Giants of Ilha de Moçambique",
        "location": "Ilha de Moçambique, Mozambique",
        "coordinates": [-15.0365, 40.7351],
        "description": "Ancient megalithic tombs located on the historic island of Mozambique.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ilha_de_Mo%C3%A7ambique",
        "constructedDate": "c. 1000–1500 AD",
        "isUnesco": false
    },
    {
        "name": "Tiya",
        "location": "Southern Nations, Nationalities, and Peoples' Region, Ethiopia",
        "coordinates": [8.4305, 38.6225],
        "description": "A UNESCO World Heritage Site known for its large number of megalithic stelae.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tiya",
        "constructedDate": "c. 12th century AD",
        "isUnesco": false
    },
    {
        "name": "Sine Ngayène",
        "location": "Senegal",
        "coordinates": [13.6838, -15.2111],
        "description": "Part of the Senegambian stone circles, consisting of numerous standing stones.",
        "wikipedia": "https://en.wikipedia.org/wiki/Stone_Circles_of_Senegambia",
        "constructedDate": "c. 750–1000 AD",
        "isUnesco": false
    },
    {
        "name": "Wassu Stone Circles",
        "location": "Central River, The Gambia",
        "coordinates": [13.6917, -14.8833],
        "description": "One of the best-known sites in the Senegambian stone circles, thought to be used as ancient burial grounds.",
        "wikipedia": "https://en.wikipedia.org/wiki/Wassu_Stone_Circles",
        "constructedDate": "c. 750–1000 AD",
        "isUnesco": false
    },
    {
        "name": "Aksum Obelisks",
        "location": "Axum, Ethiopia",
        "coordinates": [14.1231, 38.7200],
        "description": "Ancient stelae erected in the city of Axum, representing the burial markers of the ancient Kingdom of Aksum.",
        "wikipedia": "https://en.wikipedia.org/wiki/Obelisk_of_Axum",
        "constructedDate": "c. 4th century AD",
        "isUnesco": false
    },
    {
        "name": "Aswan Obelisk",
        "location": "Aswan, Egypt",
        "coordinates": [24.0889, 32.8998],
        "description": "An unfinished obelisk located in the northern region of the stone quarries of ancient Egypt.",
        "wikipedia": "https://en.wikipedia.org/wiki/Unfinished_obelisk",
        "constructedDate": "c. 15th century BC",
        "isUnesco": false
    },
    {
        "name": "Khasekhemwy's Tomb",
        "location": "Abydos, Egypt",
        "coordinates": [26.1839, 31.9197],
        "description": "An ancient royal tomb from the Second Dynasty of Egypt, notable for its massive stone enclosure.",
        "wikipedia": "https://en.wikipedia.org/wiki/Khasekhemwy",
        "constructedDate": "c. 2700 BC",
        "isUnesco": false
    },
    {
        "name": "Bagnold Sun Compass",
        "location": "Libyan Desert, Egypt",
        "coordinates": [25.0000, 25.0000],
        "description": "Ancient circular stone structures thought to have been used as a sun compass.",
        "wikipedia": "https://en.wikipedia.org/wiki/Libyan_desert_glass",
        "constructedDate": "Unknown",
        "isUnesco": false
    },
    {
        "name": "Ingombe Ilede",
        "location": "Southern Province, Zambia",
        "coordinates": [-17.4167, 27.3667],
        "description": "An archaeological site known for its Iron Age artifacts and burials.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ingombe_Ilede",
        "constructedDate": "c. 700–1100 AD",
        "isUnesco": false
    },
    {
        "name": "Adrar Bous",
        "location": "Niger",
        "coordinates": [21.1500, 8.9500],
        "description": "An archaeological site with evidence of prehistoric habitation and rock art.",
        "wikipedia": "https://en.wikipedia.org/wiki/Adrar_Bous",
        "constructedDate": "c. 8000 BC",
        "isUnesco": false
    },
    {
        "name": "Wadi Mathendous",
        "location": "Fezzan, Libya",
        "coordinates": [25.7500, 11.5000],
        "description": "Known for its ancient petroglyphs depicting wild animals.",
        "wikipedia": "https://en.wikipedia.org/wiki/Wadi_Mathendous",
        "constructedDate": "c. 10,000–5000 BC",
        "isUnesco": false
    },
    {
        "name": "Matrilineal Rock Art",
        "location": "Kondoa, Tanzania",
        "coordinates": [-4.4000, 35.8000],
        "description": "A UNESCO World Heritage site with rock art representing human figures and animals.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kondoa_Rock-Art_Sites",
        "constructedDate": "c. 2000 BC",
        "isUnesco": false
    },
    {
        "name": "Nok Culture",
        "location": "Nigeria",
        "coordinates": [9.5667, 8.5167],
        "description": "An ancient civilization known for its terracotta sculptures and iron smelting.",
        "wikipedia": "https://en.wikipedia.org/wiki/Nok_culture",
        "constructedDate": "c. 1000 BC–300 AD",
        "isUnesco": false
    },
    {
        "name": "Oshogbo Sacred Grove",
        "location": "Osun State, Nigeria",
        "coordinates": [7.7500, 4.5667],
        "description": "A sacred forest along the banks of the Osun River, containing shrines and sanctuaries.",
        "wikipedia": "https://en.wikipedia.org/wiki/Sacred_Grove",
        "constructedDate": "Ancient cultural site",
        "isUnesco": false
    },
    {
        "name": "Mount Loura",
        "location": "Guinea",
        "coordinates": [12.2333, -12.6167],
        "description": "A mountain known for its ancient stone circles and burial mounds.",
        "wikipedia": "https://en.wikipedia.org/wiki/Mount_Loura",
        "constructedDate": "c. 1000 BC",
        "isUnesco": false
    },
    {
        "name": "Matobo Hills",
        "location": "Zimbabwe",
        "coordinates": [-20.5000, 28.5000],
        "description": "A UNESCO World Heritage site with a high concentration of rock art.",
        "wikipedia": "https://en.wikipedia.org/wiki/Matobo_National_Park",
        "constructedDate": "c. 13,000 BC",
        "isUnesco": false
    },
    {
        "name": "The Great Enclosure",
        "location": "Khami Ruins, Zimbabwe",
        "coordinates": [-20.1797, 28.4506],
        "description": "An archaeological site representing the ruins of the capital of the Kingdom of Butua.",
        "wikipedia": "https://en.wikipedia.org/wiki/Khami_Ruins",
        "constructedDate": "c. 1450–1650 AD",
        "isUnesco": false
    },
    {
        "name": "Namoratunga",
        "location": "Lake Turkana, Kenya",
        "coordinates": [2.25, 36.0833],
        "description": "A group of megalithic sites with stone pillars associated with prehistoric cultures.",
        "wikipedia": "https://en.wikipedia.org/wiki/Namoratunga",
        "constructedDate": "c. 300 BC",
        "isUnesco": false
    },
    {
        "name": "Bagnold's Stone Circle",
        "location": "Gilf Kebir, Egypt",
        "coordinates": [23.6333, 25.6167],
        "description": "A prehistoric stone circle located in the Egyptian desert, discovered by explorer Ralph Bagnold.",
        "wikipedia": "https://en.wikipedia.org/wiki/Gilf_Kebir",
        "constructedDate": "c. 4th millennium BC",
        "isUnesco": false
    },
    {
        "name": "Bouar Megaliths",
        "location": "Bouar, Central African Republic",
        "coordinates": [5.9333, 15.6000],
        "description": "A group of megalithic monuments located near the town of Bouar.",
        "wikipedia": "https://en.wikipedia.org/wiki/Bouar",
        "constructedDate": "c. 4500 BC",
        "isUnesco": false
    },
    {
        "name": "Wassu Stone Circles",
        "location": "Gambia",
        "coordinates": [13.6868, -14.8818],
        "description": "Stone circles and burial mounds attributed to the ancient Senegambian stone circle culture.",
        "wikipedia": "https://en.wikipedia.org/wiki/Wassu_Stone_Circles",
        "constructedDate": "c. 750–1000 AD",
        "isUnesco": false
    },
    {
        "name": "Sine Ngayène Stone Circles",
        "location": "Senegal",
        "coordinates": [13.6918, -15.5227],
        "description": "A group of stone circles and tumuli part of the Senegambian stone circle sites.",
        "wikipedia": "https://en.wikipedia.org/wiki/Sine_Ngay%C3%A8ne",
        "constructedDate": "c. 3rd century BC to 16th century AD",
        "isUnesco": false
    },
    {
        "name": "Ader Megaliths",
        "location": "Niger",
        "coordinates": [14.9902, 5.7673],
        "description": "Megalithic structures found in the Ader region of Niger, including stone pillars and burial sites.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ader",
        "constructedDate": "c. 2000 BC",
        "isUnesco": false
    },
    {
        "name": "Adam's Calendar",
        "location": "Mpumalanga, South Africa",
        "coordinates": [-25.9236, 30.5096],
        "description": "A circular stone structure believed to be one of the oldest man-made structures in the world.",
        "wikipedia": "https://en.wikipedia.org/wiki/Adam's_Calendar",
        "constructedDate": "c. 75,000 BC",
        "isUnesco": false
    },
    {
        "name": "Chaco Canyon",
        "location": "New Mexico, USA",
        "coordinates": [36.053, -107.956],
        "description": "A major center of Ancestral Puebloan culture with a large concentration of pueblos.",
        "wikipedia": "https://en.wikipedia.org/wiki/Chaco_Culture_National_Historical_Park",
        "constructedDate": "c. 900–1150 AD",
        "isUnesco": false
    },
    {
        "name": "Great Serpent Mound",
        "location": "Ohio, USA",
        "coordinates": [39.025, -83.429],
        "description": "A prehistoric effigy mound representing a serpent, built by ancient Native American cultures.",
        "wikipedia": "https://en.wikipedia.org/wiki/Serpent_Mound",
        "constructedDate": "c. 1000 AD",
        "isUnesco": false
    },
    {
        "name": "Poverty Point",
        "location": "Louisiana, USA",
        "coordinates": [32.636, -91.408],
        "description": "An ancient site known for its earthen mounds and extensive trade networks.",
        "wikipedia": "https://en.wikipedia.org/wiki/Poverty_Point",
        "constructedDate": "c. 1700–1100 BC",
        "isUnesco": false
    },
    {
        "name": "Cahokia Mounds",
        "location": "Illinois, USA",
        "coordinates": [38.655, -90.064],
        "description": "The site of a pre-Columbian Native American city known for its large earthen mounds.",
        "wikipedia": "https://en.wikipedia.org/wiki/Cahokia",
        "constructedDate": "c. 1050–1350 AD",
        "isUnesco": false
    },
    {
        "name": "Stone Spheres of Costa Rica",
        "location": "Diquís Delta, Costa Rica",
        "coordinates": [8.748, -83.576],
        "description": "A collection of over 300 petrospheres created by the Diquís culture.",
        "wikipedia": "https://en.wikipedia.org/wiki/Stone_spheres_of_Costa_Rica",
        "constructedDate": "c. 300–1500 AD",
        "isUnesco": false
    },
    {
        "name": "Casa Grande Ruins",
        "location": "Arizona, USA",
        "coordinates": [32.995, -111.537],
        "description": "An ancient Hohokam site featuring a four-story adobe structure.",
        "wikipedia": "https://en.wikipedia.org/wiki/Casa_Grande_Ruins_National_Monument",
        "constructedDate": "c. 1350 AD",
        "isUnesco": false
    },
    {
        "name": "Palenque",
        "location": "Chiapas, Mexico",
        "coordinates": [17.484, -92.046],
        "description": "An ancient Maya city known for its impressive architecture and sculptural art.",
        "wikipedia": "https://en.wikipedia.org/wiki/Palenque",
        "constructedDate": "c. 226 BC–799 AD",
        "isUnesco": false
    },
    {
        "name": "Cañada de La Virgen",
        "location": "Guanajuato, Mexico",
        "coordinates": [20.89, -100.868],
        "description": "A pre-Columbian archaeological site with pyramid structures.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ca%C3%B1ada_de_La_Virgen",
        "constructedDate": "c. 540–1050 AD",
        "isUnesco": false
    },
    {
        "name": "Cuicuilco",
        "location": "Mexico City, Mexico",
        "coordinates": [19.302, -99.177],
        "description": "An ancient city with one of the earliest large circular pyramids.",
        "wikipedia": "https://en.wikipedia.org/wiki/Cuicuilco",
        "constructedDate": "c. 800–600 BC",
        "isUnesco": false
    },
    {
        "name": "Tiwanaku",
        "location": "La Paz, Bolivia",
        "coordinates": [-16.554, -68.679],
        "description": "A significant pre-Columbian archaeological site known for its monumental architecture.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tiwanaku",
        "constructedDate": "c. 400–1000 AD",
        "isUnesco": false
    },
    {
        "name": "Tulum",
        "location": "Quintana Roo, Mexico",
        "coordinates": [20.211, -87.465],
        "description": "A Maya walled city located on the Caribbean coast, known for its well-preserved ruins.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tulum",
        "constructedDate": "c. 1200–1450 AD",
        "isUnesco": false
    },
    {
        "name": "Copán",
        "location": "Copán Department, Honduras",
        "coordinates": [14.836, -89.141],
        "description": "An archaeological site of the Maya civilization, known for its elaborate stone sculptures.",
        "wikipedia": "https://en.wikipedia.org/wiki/Cop%C3%A1n",
        "constructedDate": "c. 5th–9th century AD",
        "isUnesco": false
    },
    {
        "name": "Chimney Rock",
        "location": "Colorado, USA",
        "coordinates": [37.179, -107.308],
        "description": "An Ancestral Puebloan site known for its astronomical alignments.",
        "wikipedia": "https://en.wikipedia.org/wiki/Chimney_Rock_National_Monument",
        "constructedDate": "c. 925–1125 AD",
        "isUnesco": false
    },
    {
        "name": "Tikal",
        "location": "Petén Department, Guatemala",
        "coordinates": [17.222, -89.623],
        "description": "One of the largest archaeological sites of the Maya civilization, with towering temples.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tikal",
        "constructedDate": "c. 200–900 AD",
        "isUnesco": false
    },
    {
        "name": "Monte Albán",
        "location": "Oaxaca, Mexico",
        "coordinates": [17.047, -96.767],
        "description": "A large pre-Columbian archaeological site known for its monumental architecture.",
        "wikipedia": "https://en.wikipedia.org/wiki/Monte_Alb%C3%A1n",
        "constructedDate": "c. 500 BC–750 AD",
        "isUnesco": false
    },
    {
        "name": "El Tajín",
        "location": "Veracruz, Mexico",
        "coordinates": [20.444, -97.376],
        "description": "A pre-Columbian archaeological site known for its unique architecture and ball courts.",
        "wikipedia": "https://en.wikipedia.org/wiki/El_Taj%C3%ADn",
        "constructedDate": "c. 600–1200 AD",
        "isUnesco": false
    },
    {
        "name": "Kuelap",
        "location": "Amazonas, Peru",
        "coordinates": [-6.419, -77.926],
        "description": "A fortified citadel built by the Chachapoya culture, featuring massive stone walls.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kuelap",
        "constructedDate": "c. 6th century AD",
        "isUnesco": false
    },
    {
        "name": "Mesa Verde",
        "location": "Colorado, USA",
        "coordinates": [37.230, -108.461],
        "description": "A UNESCO World Heritage site known for its well-preserved Ancestral Puebloan cliff dwellings.",
        "wikipedia": "https://en.wikipedia.org/wiki/Mesa_Verde_National_Park",
        "constructedDate": "c. 600–1300 AD",
        "isUnesco": false
    },
    {
        "name": "Marcahuasi",
        "location": "Lima Region, Peru",
        "coordinates": [-11.8019, -76.6206],
        "description": "A plateau famous for its stone formations and megalithic sculptures.",
        "wikipedia": "https://en.wikipedia.org/wiki/Marcahuasi",
        "constructedDate": "c. 500 BC",
        "isUnesco": false
    },
    {
        "name": "Chavín de Huántar",
        "location": "Ancash Region, Peru",
        "coordinates": [-9.5806, -77.1786],
        "description": "An archaeological site with megalithic temples and stone sculptures.",
        "wikipedia": "https://en.wikipedia.org/wiki/Chav%C3%ADn_de_Hu%C3%A1ntar",
        "constructedDate": "c. 1200–500 BC",
        "isUnesco": false
    },
    {
        "name": "La Venta",
        "location": "Tabasco, Mexico",
        "coordinates": [18.0931, -94.0183],
        "description": "An ancient Olmec site with large stone heads and megalithic structures.",
        "wikipedia": "https://en.wikipedia.org/wiki/La_Venta",
        "constructedDate": "c. 900–400 BC",
        "isUnesco": false
    },
    {
        "name": "San Agustín Archaeological Park",
        "location": "Huila, Colombia",
        "coordinates": [1.886, -76.280],
        "description": "A park with hundreds of megalithic statues and burial mounds.",
        "wikipedia": "https://en.wikipedia.org/wiki/San_Agust%C3%ADn_Archaeological_Park",
        "constructedDate": "c. 1st to 8th century AD",
        "isUnesco": false
    },
    {
        "name": "Tierradentro",
        "location": "Cauca, Colombia",
        "coordinates": [2.5728, -76.0172],
        "description": "An archaeological site with hypogea and megalithic tombs.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tierradentro",
        "constructedDate": "c. 6th to 10th century AD",
        "isUnesco": false
    },
    {
        "name": "Monte Albán",
        "location": "Oaxaca, Mexico",
        "coordinates": [17.043, -96.767],
        "description": "An ancient city with pyramids, tombs, and megalithic carvings.",
        "wikipedia": "https://en.wikipedia.org/wiki/Monte_Alb%C3%A1n",
        "constructedDate": "c. 500 BC–750 AD",
        "isUnesco": false
    },
    {
        "name": "Caral",
        "location": "Lima Region, Peru",
        "coordinates": [-10.8925, -77.5211],
        "description": "One of the oldest civilizations in the Americas with megalithic pyramids.",
        "wikipedia": "https://en.wikipedia.org/wiki/Caral",
        "constructedDate": "c. 2600–2000 BC",
        "isUnesco": false
    },
    {
        "name": "Tikal",
        "location": "Petén, Guatemala",
        "coordinates": [17.222, -89.623],
        "description": "An ancient Maya city with monumental architecture and stone pyramids.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tikal",
        "constructedDate": "c. 200–900 AD",
        "isUnesco": false
    },
    {
        "name": "Copán",
        "location": "Copán Department, Honduras",
        "coordinates": [14.835, -89.159],
        "description": "A Maya site known for its sculpted stone stelae and altars.",
        "wikipedia": "https://en.wikipedia.org/wiki/Cop%C3%A1n",
        "constructedDate": "c. 5th to 9th century AD",
        "isUnesco": false
    },
    {
        "name": "Chichen Itza",
        "location": "Yucatán, Mexico",
        "coordinates": [20.6843, -88.5678],
        "description": "A large pre-Columbian city with monumental architecture and stone carvings.",
        "wikipedia": "https://en.wikipedia.org/wiki/Chichen_Itza",
        "constructedDate": "c. 600–1200 AD",
        "isUnesco": false
    },
    {
        "name": "Petroglyphs Provincial Park",
        "location": "Ontario, Canada",
        "coordinates": [44.6036, -78.0309],
        "description": "A site known for its ancient Indigenous rock carvings, representing one of Canada's largest collections of petroglyphs.",
        "wikipedia": "https://en.wikipedia.org/wiki/Petroglyphs_Provincial_Park",
        "constructedDate": "c. 900–1400 AD",
        "isUnesco": false
    },
    {
        "name": "Kejimkujik National Park Petroglyphs",
        "location": "Nova Scotia, Canada",
        "coordinates": [44.3833, -65.2167],
        "description": "A series of petroglyphs created by the Mi'kmaq people, depicting traditional life and spiritual beliefs.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kejimkujik_National_Park",
        "constructedDate": "c. 500–1500 AD",
        "isUnesco": false
    },
    {
        "name": "L'Anse aux Meadows",
        "location": "Newfoundland and Labrador, Canada",
        "coordinates": [51.5938, -55.5351],
        "description": "An archaeological site of a Norse settlement dating back to around 1000 AD, representing the earliest known European presence in North America.",
        "wikipedia": "https://en.wikipedia.org/wiki/L%27Anse_aux_Meadows",
        "constructedDate": "c. 1000 AD",
        "isUnesco": false
    },
    {
        "name": "Manitou Stone",
        "location": "Alberta, Canada",
        "coordinates": [52.9219, -110.1067],
        "description": "A large iron meteorite held sacred by Indigenous peoples, traditionally used in spiritual ceremonies.",
        "wikipedia": "https://en.wikipedia.org/wiki/Manitou_Stone",
        "constructedDate": "Natural formation, spiritual significance",
        "isUnesco": false
    },
    {
        "name": "Sacred Buffalo Guardian Mountain",
        "location": "Alberta, Canada",
        "coordinates": [49.0000, -110.0000],
        "description": "A natural rock formation used by Indigenous peoples as a vision quest site, with ancient rock art nearby.",
        "wikipedia": "https://en.wikipedia.org/wiki/Blackfoot_language",
        "constructedDate": "Ancient cultural site",
        "isUnesco": false
    },
    {
        "name": "Ninstints (SGang Gwaay)",
        "location": "British Columbia, Canada",
        "coordinates": [52.0916, -131.2238],
        "description": "A UNESCO World Heritage site featuring Haida totem poles and remains of wooden houses on a remote island.",
        "wikipedia": "https://en.wikipedia.org/wiki/SGang_Gwaay_Llnaagay",
        "constructedDate": "c. 1800s AD",
        "isUnesco": false
    },
    {
        "name": "Geoglyph of Moose Mountain",
        "location": "Saskatchewan, Canada",
        "coordinates": [50.2044, -102.7214],
        "description": "A large bison effigy made from stones and earth, believed to be created by Indigenous peoples.",
        "wikipedia": "https://en.wikipedia.org/wiki/Moose_Mountain_(Saskatchewan)",
        "constructedDate": "Prehistoric",
        "isUnesco": false
    },
    {
        "name": "The Forks",
        "location": "Winnipeg, Manitoba, Canada",
        "coordinates": [49.8916, -97.1308],
        "description": "A historic site and meeting place with archaeological evidence of human activity dating back 6,000 years.",
        "wikipedia": "https://en.wikipedia.org/wiki/The_Forks,_Winnipeg",
        "constructedDate": "c. 4000 BC",
        "isUnesco": false
    },
    {
        "name": "Inuksuk at Apex",
        "location": "Iqaluit, Nunavut, Canada",
        "coordinates": [63.7331, -68.5011],
        "description": "An Inuit stone landmark used as a navigational aid in the Arctic region.",
        "wikipedia": "https://en.wikipedia.org/wiki/Inuksuk",
        "constructedDate": "Traditional",
        "isUnesco": false
    },
    {
        "name": "Manitou Mounds",
        "location": "Ontario, Canada",
        "coordinates": [48.9885, -92.9396],
        "description": "An ancient Ojibwe sacred site consisting of burial mounds and ceremonial structures.",
        "wikipedia": "https://en.wikipedia.org/wiki/Manitou_Mounds",
        "constructedDate": "c. 500 BC–1650 AD",
        "isUnesco": false
    },
    {
        "name": "Keatley Creek Archaeological Site",
        "location": "British Columbia, Canada",
        "coordinates": [50.7747, -121.9478],
        "description": "A large prehistoric village site with evidence of complex pit house structures.",
        "wikipedia": "https://en.wikipedia.org/wiki/Keatley_Creek_Archaeological_Site",
        "constructedDate": "c. 1000 BC–1000 AD",
        "isUnesco": false
    },
    {
        "name": "Serpent Mounds",
        "location": "Ontario, Canada",
        "coordinates": [44.2197, -78.1261],
        "description": "An ancient burial mound shaped like a serpent, built by Indigenous peoples.",
        "wikipedia": "https://en.wikipedia.org/wiki/Serpent_Mounds_Park",
        "constructedDate": "c. 200 BC–400 AD",
        "isUnesco": false
    },
    {
        "name": "Effigy Mounds National Monument",
        "location": "Iowa, USA",
        "coordinates": [43.0800, -91.2000],
        "description": "Although in the USA, it shares cultural links with ancient mound-building practices in Canada.",
        "wikipedia": "https://en.wikipedia.org/wiki/Effigy_Mounds_National_Monument",
        "constructedDate": "c. 500–1300 AD",
        "isUnesco": false
    },
    {
        "name": "Kekerten Island Whaling Station",
        "location": "Nunavut, Canada",
        "coordinates": [66.9667, -67.3667],
        "description": "An archaeological site with stone structures used during the whaling era.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kekerten",
        "constructedDate": "c. 1800s AD",
        "isUnesco": false
    },
    {
        "name": "Writing-on-Stone Provincial Park",
        "location": "Alberta, Canada",
        "coordinates": [49.0500, -111.6167],
        "description": "A cultural site featuring rock art and stone formations used by Indigenous peoples.",
        "wikipedia": "https://en.wikipedia.org/wiki/Writing-on-Stone_Provincial_Park",
        "constructedDate": "Ancient",
        "isUnesco": false
    },
    {
        "name": "Gornaya Shoria Megaliths",
        "location": "Kemerovo Oblast, Russia",
        "coordinates": [52.9348, 87.3590],
        "description": "A site with massive stone blocks forming a mysterious wall structure.",
        "wikipedia": "https://en.wikipedia.org/wiki/Gornaya_Shoria",
        "constructedDate": "Unknown",
        "isUnesco": false
    },
    {
        "name": "Dolmens of North Caucasus",
        "location": "Caucasus Mountains, Russia",
        "coordinates": [43.5820, 44.0104],
        "description": "Ancient dolmen structures scattered across the Caucasus Mountains.",
        "wikipedia": "https://en.wikipedia.org/wiki/Caucasus_dolmens",
        "constructedDate": "c. 3000–2000 BC",
        "isUnesco": false
    },
    {
        "name": "Verkhnyaya Balkaria Megaliths",
        "location": "Kabardino-Balkaria, Russia",
        "coordinates": [43.5000, 43.2500],
        "description": "A site with ancient stone structures and burial mounds.",
        "wikipedia": "https://en.wikipedia.org/wiki/Verkhnyaya_Balkaria",
        "constructedDate": "Unknown",
        "isUnesco": false
    },
    {
        "name": "Menhirs of Khakassia",
        "location": "Khakassia, Russia",
        "coordinates": [53.1333, 89.7667],
        "description": "Standing stone monuments believed to be associated with ancient rituals.",
        "wikipedia": "https://en.wikipedia.org/wiki/Khakassia",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Konder Megalith",
        "location": "Amur Oblast, Russia",
        "coordinates": [51.3167, 128.1167],
        "description": "An unusual megalithic structure with a circular formation.",
        "wikipedia": "https://en.wikipedia.org/wiki/Konder_massif",
        "constructedDate": "Unknown",
        "isUnesco": false
    },
    {
        "name": "Arkaim",
        "location": "Chelyabinsk Oblast, Russia",
        "coordinates": [52.6350, 59.5669],
        "description": "An archaeological site of an ancient fortified settlement, part of the Sintashta culture.",
        "wikipedia": "https://en.wikipedia.org/wiki/Arkaim",
        "constructedDate": "c. 2000 BC",
        "isUnesco": false
    },
    {
        "name": "Sunduki",
        "location": "Khakassia, Russia",
        "coordinates": [54.5000, 90.0000],
        "description": "A site with petroglyphs and ancient observatory structures, used for astronomical observations.",
        "wikipedia": "https://en.wikipedia.org/wiki/Sunduki",
        "constructedDate": "c. 2000 BC",
        "isUnesco": false
    },
    {
        "name": "Turan-Uyuk",
        "location": "Tuva Republic, Russia",
        "coordinates": [52.4677, 95.5722],
        "description": "A valley containing numerous Scythian burial mounds, known as kurgans.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tuva",
        "constructedDate": "c. 9th century BC",
        "isUnesco": false
    },
    {
        "name": "Khakassia Dolmens",
        "location": "Khakassia, Russia",
        "coordinates": [54.5000, 89.5000],
        "description": "Ancient dolmen structures located in the region of Khakassia, used for burial and rituals.",
        "wikipedia": "https://en.wikipedia.org/wiki/Khakassia",
        "constructedDate": "c. 2000 BC",
        "isUnesco": false
    },
    {
        "name": "Samus Archaeological Complex",
        "location": "Tomsk Oblast, Russia",
        "coordinates": [56.5000, 84.9000],
        "description": "A complex of sites with Bronze Age settlements, burial mounds, and petroglyphs.",
        "wikipedia": "https://en.wikipedia.org/wiki/Samus_Archaeological_Complex",
        "constructedDate": "c. 1800 BC",
        "isUnesco": false
    },
    {
        "name": "Kemerovo Oblast Petroglyphs",
        "location": "Kemerovo Oblast, Russia",
        "coordinates": [54.5000, 86.0000],
        "description": "A series of rock carvings depicting ancient symbols and figures, found in Siberia.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kemerovo_Oblast",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Haga dolmen",
        "location": "Haga, Sweden",
        "coordinates": [57.7035, 11.9706],
        "description": "One of the largest dolmens in Sweden, a type of single-chamber megalithic tomb.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dolmen",
        "constructedDate": "c. 3500 BC",
        "isUnesco": false
    },
    {
        "name": "Jelling Stones",
        "location": "Jelling, Denmark",
        "coordinates": [55.7534, 9.4204],
        "description": "Massive carved runestones from the Viking Age, often considered a symbol of Denmark's transition to Christianity.",
        "wikipedia": "https://en.wikipedia.org/wiki/Jelling_stones",
        "constructedDate": "c. 10th century AD",
        "isUnesco": false
    },
    {
        "name": "Gunnarstorp Dolmen",
        "location": "Viken, Sweden",
        "coordinates": [56.142, 12.580],
        "description": "A Neolithic dolmen featuring a large capstone, located in the Skåne region.",
        "wikipedia": "https://en.wikipedia.org/wiki/Gunnarstorp_dolmen",
        "constructedDate": "c. 3300 BC",
        "isUnesco": false
    },
    {
        "name": "Gullringen Stone Circle",
        "location": "Öland, Sweden",
        "coordinates": [56.7223, 16.6656],
        "description": "An ancient stone circle on the island of Öland, believed to be used for ritual purposes.",
        "wikipedia": "https://en.wikipedia.org/wiki/Stone_circle",
        "constructedDate": "c. 1000 BC",
        "isUnesco": false
    },
    {
        "name": "Lindholm Høje",
        "location": "Aalborg, Denmark",
        "coordinates": [57.0576, 9.9215],
        "description": "A Viking burial site featuring stone ship settings and numerous graves.",
        "wikipedia": "https://en.wikipedia.org/wiki/Lindholm_H%C3%B8je",
        "constructedDate": "c. 1000 AD",
        "isUnesco": false
    },
    {
        "name": "Borgarvirki",
        "location": "Vatnsdalshólar, Iceland",
        "coordinates": [65.5852, -20.8325],
        "description": "A volcanic plug modified into a fortress with stone walls, believed to be a Viking stronghold.",
        "wikipedia": "https://en.wikipedia.org/wiki/Borgarvirki",
        "constructedDate": "c. 9th century AD",
        "isUnesco": false
    },
    {
        "name": "Anundshög",
        "location": "Västerås, Sweden",
        "coordinates": [59.6218, 16.5425],
        "description": "The largest burial mound in Sweden, surrounded by stone ship settings.",
        "wikipedia": "https://en.wikipedia.org/wiki/Anundsh%C3%B6g",
        "constructedDate": "c. 500–1050 AD",
        "isUnesco": false
    },
    {
        "name": "Viking Runestones",
        "location": "Various, Scandinavia",
        "coordinates": [59.3293, 18.0686],
        "description": "Runestones are raised stones with inscriptions, created during the Viking Age.",
        "wikipedia": "https://en.wikipedia.org/wiki/Runestone",
        "constructedDate": "c. 4th to 12th century AD",
        "isUnesco": false
    },
    {
        "name": "Skara Brae",
        "location": "Orkney, Scotland",
        "coordinates": [59.0485, -3.3436],
        "description": "A well-preserved Neolithic village dating from about 3180 BC.",
        "wikipedia": "https://en.wikipedia.org/wiki/Skara_Brae",
        "constructedDate": "c. 3180 BC",
        "isUnesco": false
    },
    {
        "name": "Gamla Uppsala",
        "location": "Uppsala, Sweden",
        "coordinates": [59.9255, 17.6339],
        "description": "A site of historical and archaeological significance with burial mounds.",
        "wikipedia": "https://en.wikipedia.org/wiki/Gamla_Uppsala",
        "constructedDate": "c. 6th century AD",
        "isUnesco": false
    },
    {
        "name": "Jelling Mounds",
        "location": "Jelling, Denmark",
        "coordinates": [55.7533, 9.4177],
        "description": "Two large burial mounds and a series of runestones erected by King Gorm the Old.",
        "wikipedia": "https://en.wikipedia.org/wiki/Jelling_stones",
        "constructedDate": "c. 10th century AD",
        "isUnesco": false
    },
    {
        "name": "Isalo National Park",
        "location": "Ihosy, Madagascar",
        "coordinates": [-22.5000, 45.3333],
        "description": "Noted for its sandstone formations, natural canyons, and historically significant burial sites used by the Bara people.",
        "wikipedia": "https://en.wikipedia.org/wiki/Isalo_National_Park",
        "constructedDate": "Natural formation, cultural significance",
        "isUnesco": false
    },
    {
        "name": "Andriambahoaka Royal Tombs",
        "location": "Ambohimanga, Madagascar",
        "coordinates": [-18.8167, 47.5167],
        "description": "An ancient royal burial site associated with the Merina kingdom, located within the fortified royal city of Ambohimanga.",
        "wikipedia": "https://en.wikipedia.org/wiki/Rova_of_Antananarivo",
        "constructedDate": "c. 16th century AD",
        "isUnesco": false
    },
    {
        "name": "Tiya Megaliths",
        "location": "Tiya, Ethiopia",
        "coordinates": [8.4331, 38.6190],
        "description": "A UNESCO World Heritage site with around 40 ancient stelae engraved with symbols and believed to be grave markers.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tiya",
        "constructedDate": "c. 12th century AD",
        "isUnesco": false
    },
    {
        "name": "Namoratunga",
        "location": "Lake Turkana, Kenya",
        "coordinates": [2.2500, 36.0833],
        "description": "A group of megalithic sites that consist of stone pillars and are thought to be linked to ancient Cushitic cultures.",
        "wikipedia": "https://en.wikipedia.org/wiki/Namoratunga",
        "constructedDate": "c. 300 BC",
        "isUnesco": false
    },
    {
        "name": "Senegambian Stone Circles",
        "location": "Senegal and Gambia",
        "coordinates": [13.6833, -15.5333],
        "description": "A concentration of megalithic circles of laterite pillars, used as burial sites and showing sophisticated ancient construction techniques.",
        "wikipedia": "https://en.wikipedia.org/wiki/Stone_Circles_of_Senegambia",
        "constructedDate": "c. 750–1000 AD",
        "isUnesco": false
    },
    {
        "name": "Wassu Stone Circles",
        "location": "Gambia",
        "coordinates": [13.6868, -14.8818],
        "description": "One of the most famous sites of the Senegambian stone circle complex, believed to have been used for burials.",
        "wikipedia": "https://en.wikipedia.org/wiki/Wassu_Stone_Circles",
        "constructedDate": "c. 750–1000 AD",
        "isUnesco": false
    },
    {
        "name": "Ngwenya Mines",
        "location": "Eswatini (Swaziland)",
        "coordinates": [-26.2211, 31.0406],
        "description": "The site of the world's oldest known mine, dating back 43,000 years, where hematite and iron ore were extracted.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ngwenya_mine",
        "constructedDate": "c. 43000 BC (mining site)",
        "isUnesco": false
    },
    {
        "name": "Tsodilo Hills",
        "location": "Botswana",
        "coordinates": [-18.7539, 21.7339],
        "description": "A UNESCO World Heritage site known for its rock art and ancient habitation, providing insights into early human settlement.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tsodilo",
        "constructedDate": "Rock art: c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Tiya Megaliths",
        "location": "Tiya, Ethiopia",
        "coordinates": [8.4331, 38.6190],
        "description": "A UNESCO World Heritage site with 36 standing stones, many of which are engraved with mysterious symbols.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tiya_(archaeological_site)",
        "constructedDate": "c. 12th century AD",
        "isUnesco": false
    },
    {
        "name": "Nabta Playa",
        "location": "Western Desert, Egypt",
        "coordinates": [22.5250, 30.7167],
        "description": "A Neolithic archaeological site that contains the remains of prehistoric human habitation and is believed to have astronomical significance.",
        "wikipedia": "https://en.wikipedia.org/wiki/Nabta_Playa",
        "constructedDate": "c. 6000–4500 BC",
        "isUnesco": false
    },
    {
        "name": "Ait Benhaddou",
        "location": "Ouarzazate, Morocco",
        "coordinates": [31.0475, -7.1294],
        "description": "A fortified village along the former caravan route between the Sahara and Marrakech, known for its traditional pre-Saharan earthen architecture.",
        "wikipedia": "https://en.wikipedia.org/wiki/A%C3%AFt_Benhaddou",
        "constructedDate": "c. 17th century",
        "isUnesco": false
    },
    {
        "name": "Nok Culture Sites",
        "location": "Nok, Nigeria",
        "coordinates": [9.0000, 8.0000],
        "description": "An archaeological culture known for its terracotta sculptures and early iron working.",
        "wikipedia": "https://en.wikipedia.org/wiki/Nok_culture",
        "constructedDate": "c. 1500 BC–500 AD",
        "isUnesco": false
    },
    {
        "name": "Tombos Stelae",
        "location": "Tombos, Sudan",
        "coordinates": [19.7426, 30.4378],
        "description": "Ancient Nubian stelae located near the Nile, known for their inscriptions and carvings.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tombos",
        "constructedDate": "c. 1450 BC",
        "isUnesco": false
    },
    {
        "name": "Abydos, Egypt",
        "location": "Abydos, Egypt",
        "coordinates": [26.1849, 31.9195],
        "description": "A site containing ancient temples and necropolises, notable for the Osireion and its megalithic elements.",
        "wikipedia": "https://en.wikipedia.org/wiki/Abydos,_Egypt",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Matrilineal Rock Art",
        "location": "Kondoa, Tanzania",
        "coordinates": [-4.4000, 35.8000],
        "description": "A UNESCO World Heritage site with rock art representing human figures and animals.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kondoa_Rock_Art_Sites",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Sukur Cultural Landscape",
        "location": "Madagali, Nigeria",
        "coordinates": [10.7420, 13.5658],
        "description": "A UNESCO World Heritage site known for its terraced fields, traditional architecture, and stone structures.",
        "wikipedia": "https://en.wikipedia.org/wiki/Sukur",
        "constructedDate": "c. 16th century AD",
        "isUnesco": false
    },
    {
        "name": "Domboshaba Ruins",
        "location": "Francistown, Botswana",
        "coordinates": [-21.1820, 27.5122],
        "description": "An archaeological site featuring stone walls and remains of ancient settlements.",
        "wikipedia": "https://en.wikipedia.org/wiki/Domboshaba",
        "constructedDate": "c. 1450 AD",
        "isUnesco": false
    },
    {
        "name": "Chegaine Megaliths",
        "location": "Tassili n'Ajjer, Algeria",
        "coordinates": [24.5284, 9.6350],
        "description": "Stone structures and burial mounds found in the Tassili n'Ajjer region.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tassili_n%27Ajjer",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Serabit el-Khadim",
        "location": "Sinai Peninsula, Egypt",
        "coordinates": [29.0281, 33.8362],
        "description": "An ancient Egyptian archaeological site with temple ruins dedicated to the goddess Hathor, featuring numerous rock-cut inscriptions.",
        "wikipedia": "https://en.wikipedia.org/wiki/Serabit_el-Khadim",
        "constructedDate": "c. 1900–1300 BC",
        "isUnesco": false
    },
    {
        "name": "Wadi Hammamat",
        "location": "Eastern Desert, Egypt",
        "coordinates": [26.1178, 33.5832],
        "description": "An ancient quarrying site known for its carved inscriptions and petroglyphs dating back to the Pharaonic era.",
        "wikipedia": "https://en.wikipedia.org/wiki/Wadi_Hammamat",
        "constructedDate": "c. 2000 BC",
        "isUnesco": false
    },
    {
        "name": "Temple of Hibis",
        "location": "Kharga Oasis, Egypt",
        "coordinates": [25.4391, 30.5587],
        "description": "A well-preserved temple from the Late Period dedicated to the god Amun, featuring impressive columns and reliefs.",
        "wikipedia": "https://en.wikipedia.org/wiki/Temple_of_Hibis",
        "constructedDate": "c. 6th century BC",
        "isUnesco": false
    },
    {
        "name": "Temple of Qasr el-Sagha",
        "location": "Faiyum, Egypt",
        "coordinates": [29.5375, 30.5961],
        "description": "An ancient and mysterious temple with megalithic architecture, its purpose and date remain largely unknown.",
        "wikipedia": "https://en.wikipedia.org/wiki/Qasr_el-Sagha",
        "constructedDate": "c. 1800 BC",
        "isUnesco": false
    },
    {
        "name": "Temple of Tjuyu and Yuya",
        "location": "Valley of the Kings, Egypt",
        "coordinates": [25.7402, 32.6010],
        "description": "A tomb of noble couple Tjuyu and Yuya, containing remarkable artifacts and architecture indicative of megalithic construction.",
        "wikipedia": "https://en.wikipedia.org/wiki/Yuya",
        "constructedDate": "c. 14th century BC",
        "isUnesco": false
    },
    {
        "name": "Dendera Temple Complex",
        "location": "Qena, Egypt",
        "coordinates": [26.1443, 32.6723],
        "description": "An extensive temple complex known for the Temple of Hathor, featuring massive stone columns and detailed reliefs.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dendera_Temple_complex",
        "constructedDate": "c. 2250 BC",
        "isUnesco": false
    },
    {
        "name": "Valley Temple of Khafre",
        "location": "Giza, Egypt",
        "coordinates": [29.9753, 31.1376],
        "description": "A mortuary temple built in limestone and granite, part of the Pyramid complex of Khafre.",
        "wikipedia": "https://en.wikipedia.org/wiki/Valley_Temple_of_Khafre",
        "constructedDate": "c. 2570 BC",
        "isUnesco": false
    },
    {
        "name": "Medinet Habu",
        "location": "Luxor, Egypt",
        "coordinates": [25.7225, 32.5959],
        "description": "A massive temple complex built by Ramses III, noted for its well-preserved reliefs and architecture.",
        "wikipedia": "https://en.wikipedia.org/wiki/Medinet_Habu",
        "constructedDate": "c. 1186–1155 BC",
        "isUnesco": false
    },
    {
        "name": "Temple of Edfu",
        "location": "Edfu, Egypt",
        "coordinates": [24.9789, 32.8732],
        "description": "The most complete and best-preserved temple in Egypt, dedicated to the falcon god Horus.",
        "wikipedia": "https://en.wikipedia.org/wiki/Temple_of_Edfu",
        "constructedDate": "c. 237–57 BC",
        "isUnesco": false
    },
    {
        "name": "Abydos Temple of Seti I",
        "location": "Abydos, Egypt",
        "coordinates": [26.1840, 31.9214],
        "description": "A significant temple complex dedicated to Osiris, containing the famous King List of Seti I.",
        "wikipedia": "https://en.wikipedia.org/wiki/Abydos,_Egypt",
        "constructedDate": "c. 1290 BC",
        "isUnesco": false
    },
    {
        "name": "The Great Sphinx of Giza",
        "location": "Giza, Egypt",
        "coordinates": [29.9753, 31.1376],
        "description": "A limestone statue of a reclining sphinx, a mythical creature with the body of a lion and the head of a human.",
        "wikipedia": "https://en.wikipedia.org/wiki/Great_Sphinx_of_Giza",
        "constructedDate": "c. 2500 BC",
        "isUnesco": false
    },
    {
        "name": "Temple of Kom Ombo",
        "location": "Kom Ombo, Egypt",
        "coordinates": [24.4528, 32.9282],
        "description": "A double temple dedicated to the gods Sobek and Horus, featuring unique architectural elements.",
        "wikipedia": "https://en.wikipedia.org/wiki/Temple_of_Kom_Ombo",
        "constructedDate": "c. 180–47 BC",
        "isUnesco": false
    },
    {
        "name": "Temple of Esna",
        "location": "Esna, Egypt",
        "coordinates": [25.2933, 32.5542],
        "description": "A temple dedicated to the ram-headed god Khnum, known for its well-preserved hypostyle hall.",
        "wikipedia": "https://en.wikipedia.org/wiki/Esna",
        "constructedDate": "c. 200 AD",
        "isUnesco": false
    },
    {
        "name": "Temple of Medamud",
        "location": "Medamud, Egypt",
        "coordinates": [25.7167, 32.6500],
        "description": "A temple dedicated to the god Montu, featuring remains of the Ptolemaic and Roman periods.",
        "wikipedia": "https://en.wikipedia.org/wiki/Medamud",
        "constructedDate": "c. 2000 BC",
        "isUnesco": false
    },
    {
        "name": "Luxor Temple",
        "location": "Luxor, Egypt",
        "coordinates": [25.7006, 32.6394],
        "description": "A large Ancient Egyptian temple complex located on the east bank of the Nile River in the city of Luxor.",
        "wikipedia": "https://en.wikipedia.org/wiki/Luxor_Temple",
        "constructedDate": "c. 1400 BC",
        "isUnesco": false
    },
    {
        "name": "Deir el-Bahari",
        "location": "Luxor, Egypt",
        "coordinates": [25.7383, 32.6055],
        "description": "A complex of mortuary temples and tombs located on the west bank of the Nile, including the famous temple of Hatshepsut.",
        "wikipedia": "https://en.wikipedia.org/wiki/Deir_el-Bahari",
        "constructedDate": "c. 15th century BC",
        "isUnesco": false
    },
    {
        "name": "Pucará de Tilcara",
        "location": "Jujuy, Argentina",
        "coordinates": [-23.5781, -65.3946],
        "description": "An archaeological site of a pre-Inca fortification in the Quebrada de Humahuaca.",
        "wikipedia": "https://en.wikipedia.org/wiki/Pucar%C3%A1_de_Tilcara",
        "constructedDate": "c. 1100–1500 AD",
        "isUnesco": false
    },
    {
        "name": "El Shincal de Quimivil",
        "location": "Catamarca, Argentina",
        "coordinates": [-27.3333, -66.9333],
        "description": "An archaeological site of an Inca city with terraces, platforms, and stone structures.",
        "wikipedia": "https://en.wikipedia.org/wiki/El_Shincal",
        "constructedDate": "c. 1470 AD",
        "isUnesco": false
    },
    {
        "name": "Salar de Atacama Petroglyphs",
        "location": "Atacama Desert, Chile",
        "coordinates": [-23.555, -68.132],
        "description": "Ancient petroglyphs found in the Atacama Desert depicting animals and geometric shapes.",
        "wikipedia": "https://en.wikipedia.org/wiki/Atacama_Desert",
        "constructedDate": "c. 1000–1500 AD",
        "isUnesco": false
    },
    {
        "name": "Alero El Toro",
        "location": "La Rioja, Argentina",
        "coordinates": [-28.971, -67.316],
        "description": "A rock shelter with ancient petroglyphs and cave paintings.",
        "wikipedia": "https://es.wikipedia.org/wiki/Alero_El_Toro",
        "constructedDate": "c. 5000 BC",
        "isUnesco": false
    },
    {
        "name": "Valle del Encanto",
        "location": "Coquimbo, Chile",
        "coordinates": [-30.722, -70.942],
        "description": "An archaeological site featuring petroglyphs, pictographs, and stone carvings.",
        "wikipedia": "https://es.wikipedia.org/wiki/Valle_del_Encanto",
        "constructedDate": "c. 2000 BC",
        "isUnesco": false
    },
    {
        "name": "Pucará de Quitor",
        "location": "San Pedro de Atacama, Chile",
        "coordinates": [-22.9167, -68.2000],
        "description": "A pre-Columbian archaeological site, known for its stone fortress built by the Atacameño people.",
        "wikipedia": "https://en.wikipedia.org/wiki/Pucará_de_Quitor",
        "constructedDate": "c. 12th century AD",
        "isUnesco": false
    },
    {
        "name": "Tulor Village",
        "location": "San Pedro de Atacama, Chile",
        "coordinates": [-22.9155, -68.1936],
        "description": "An ancient settlement with well-preserved circular mud structures, representing early human habitation in the region.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tulor",
        "constructedDate": "c. 800 BC–500 AD",
        "isUnesco": false
    },
    {
        "name": "Parque Arqueológico y Natural de las Cuevas de Acsibi",
        "location": "Salta, Argentina",
        "coordinates": [-25.0198, -66.1395],
        "description": "An archaeological park featuring cave paintings and evidence of early human settlement.",
        "wikipedia": "https://es.wikipedia.org/wiki/Cuevas_de_Acsibi",
        "constructedDate": "c. 10,000 BC",
        "isUnesco": false
    },
    {
        "name": "Los Menhires Archaeological Reserve",
        "location": "Tafí del Valle, Argentina",
        "coordinates": [-26.7980, -65.7080],
        "description": "A site containing numerous large standing stones or menhirs, attributed to the Tafí culture.",
        "wikipedia": "https://es.wikipedia.org/wiki/Parque_Los_Menhires",
        "constructedDate": "c. 850 BC",
        "isUnesco": false
    },
    {
        "name": "Fuerte de Samaipata",
        "location": "Santa Cruz, Bolivia (near the Argentinian border)",
        "coordinates": [-18.1803, -63.8746],
        "description": "A pre-Columbian archaeological site known for its large sculpted rock with ritual and ceremonial structures.",
        "wikipedia": "https://en.wikipedia.org/wiki/Fuerte_de_Samaipata",
        "constructedDate": "c. 300 AD",
        "isUnesco": false
    },
    {
        "name": "Los Menhires Archaeological Reserve",
        "location": "Tafí del Valle, Argentina",
        "coordinates": [-26.8669, -65.7121],
        "description": "A collection of over 50 menhirs, large stone monoliths created by the Tafi culture.",
        "wikipedia": "https://es.wikipedia.org/wiki/Parque_Los_Menhires",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Pucará de Tilcara",
        "location": "Jujuy, Argentina",
        "coordinates": [-23.5776, -65.4036],
        "description": "A pre-Inca fortification site located in the Quebrada de Humahuaca.",
        "wikipedia": "https://en.wikipedia.org/wiki/Pucar%C3%A1_de_Tilcara",
        "constructedDate": "c. 1000 AD",
        "isUnesco": false
    },
    {
        "name": "El Shincal de Quimivil",
        "location": "Catamarca, Argentina",
        "coordinates": [-27.3497, -67.1758],
        "description": "The remains of a significant Inca city in the southern Andes.",
        "wikipedia": "https://en.wikipedia.org/wiki/El_Shincal",
        "constructedDate": "c. 1470 AD",
        "isUnesco": false
    },
    {
        "name": "Megaliths of Piedra del Aguila",
        "location": "Neuquén, Argentina",
        "coordinates": [-40.0475, -70.0736],
        "description": "A site featuring megalithic stone formations that were possibly used for astronomical purposes.",
        "wikipedia": "https://es.wikipedia.org/wiki/Piedra_del_Águila",
        "constructedDate": "Unknown",
        "isUnesco": false
    },
    {
        "name": "Monte Verde",
        "location": "Llanquihue Province, Chile",
        "coordinates": [-41.5200, -73.2000],
        "description": "An archaeological site near Puerto Montt, known for its early human settlement and organic preservation.",
        "wikipedia": "https://en.wikipedia.org/wiki/Monte_Verde",
        "constructedDate": "c. 14,500 BC",
        "isUnesco": false
    },
    {
        "name": "Tulor Village",
        "location": "San Pedro de Atacama, Chile",
        "coordinates": [-22.9155, -68.2034],
        "description": "An ancient village with circular mud structures, representing one of the earliest settlements in the region.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tulor",
        "constructedDate": "c. 380 BC",
        "isUnesco": false
    },
    {
        "name": "Rapa Nui Moai",
        "location": "Easter Island, Chile",
        "coordinates": [-27.1127, -109.3497],
        "description": "Monolithic human figures carved by the Rapa Nui people, located on Easter Island.",
        "wikipedia": "https://en.wikipedia.org/wiki/Moai",
        "constructedDate": "c. 1250–1500 AD",
        "isUnesco": false
    },
    {
        "name": "Cueva del Milodón",
        "location": "Patagonia, Chile",
        "coordinates": [-51.5833, -72.5500],
        "description": "A cave known for the discovery of remnants of extinct species and ancient human habitation.",
        "wikipedia": "https://en.wikipedia.org/wiki/Cueva_del_Milod%C3%B3n_Natural_Monument",
        "constructedDate": "Natural formation with human habitation evidence",
        "isUnesco": false
    },
    {
        "name": "Kaimanawa Wall",
        "location": "Taupo District, New Zealand",
        "coordinates": [-38.9100, 175.7500],
        "description": "A controversial stone wall structure that some believe to be of ancient origin, though its origins remain debated.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kaimanawa_Wall",
        "constructedDate": "Unknown",
        "isUnesco": false
    },
    {
        "name": "Māngere Mountain",
        "location": "Auckland, New Zealand",
        "coordinates": [-36.9465, 174.7819],
        "description": "A volcanic cone and one of the largest and best-preserved Maori pa (fortified village) sites in the region.",
        "wikipedia": "https://en.wikipedia.org/wiki/M%C4%81ngere_Mountain",
        "constructedDate": "Pre-European Maori era",
        "isUnesco": false
    },
    {
        "name": "Wairau Bar",
        "location": "Marlborough, New Zealand",
        "coordinates": [-41.4803, 173.8603],
        "description": "An early Maori archaeological site, providing evidence of some of the first human settlements in New Zealand.",
        "wikipedia": "https://en.wikipedia.org/wiki/Wairau_Bar",
        "constructedDate": "c. 1280 AD",
        "isUnesco": false
    },
    {
        "name": "Matatina Marae",
        "location": "Waikato, New Zealand",
        "coordinates": [-37.5667, 175.5000],
        "description": "A significant Maori cultural site featuring traditional carvings and meeting houses.",
        "wikipedia": "https://en.wikipedia.org/wiki/Marae",
        "constructedDate": "Traditional Maori",
        "isUnesco": false
    },
    {
        "name": "Tongariro National Park",
        "location": "North Island, New Zealand",
        "coordinates": [-39.2900, 175.5620],
        "description": "A UNESCO World Heritage site featuring volcanic landscapes and significant Maori spiritual sites.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tongariro_National_Park",
        "constructedDate": "Natural and cultural significance",
        "isUnesco": false
    },
    {
        "name": "Sechin Alto",
        "location": "Ancash, Peru",
        "coordinates": [-9.5089, -78.2186],
        "description": "An ancient archaeological site in Peru, featuring massive stone walls and ceremonial platforms.",
        "wikipedia": "https://en.wikipedia.org/wiki/Sechin_Alto",
        "constructedDate": "c. 1600 BC",
        "isUnesco": false
    },
    {
        "name": "Meidum Pyramid",
        "location": "Meidum, Egypt",
        "coordinates": [29.4723, 31.1448],
        "description": "An ancient Egyptian pyramid, originally a step pyramid later modified into a true pyramid.",
        "wikipedia": "https://en.wikipedia.org/wiki/Meidum",
        "constructedDate": "c. 2600 BC",
        "isUnesco": false
    },
    {
        "name": "Thebes Necropolis",
        "location": "Luxor, Egypt",
        "coordinates": [25.7333, 32.6167],
        "description": "A vast necropolis on the west bank of the Nile, home to tombs and temples including the Valley of the Kings.",
        "wikipedia": "https://en.wikipedia.org/wiki/Theban_Necropolis",
        "constructedDate": "c. 1500 BC",
        "isUnesco": false
    },
    {
        "name": "Great Zimbabwe",
        "location": "Masvingo, Zimbabwe",
        "coordinates": [-20.2671, 30.9333],
        "description": "The remains of a medieval city built of stone, a significant cultural and historical site in Africa.",
        "wikipedia": "https://en.wikipedia.org/wiki/Great_Zimbabwe",
        "constructedDate": "c. 1100 AD",
        "isUnesco": false
    },
    {
        "name": "Monastery of Geghard",
        "location": "Kotayk Province, Armenia",
        "coordinates": [40.1427, 44.7711],
        "description": "A medieval monastery partially carved out of rock, surrounded by cliffs and known for its unique architecture.",
        "wikipedia": "https://en.wikipedia.org/wiki/Geghard",
        "constructedDate": "c. 4th century AD",
        "isUnesco": false
    },
    {
        "name": "Sigiriya",
        "location": "Central Province, Sri Lanka",
        "coordinates": [7.9570, 80.7603],
        "description": "An ancient rock fortress and palace ruin, known for its frescoes and significant archaeological value.",
        "wikipedia": "https://en.wikipedia.org/wiki/Sigiriya",
        "constructedDate": "c. 477–495 AD",
        "isUnesco": false
    },
    {
        "name": "Machu Picchu",
        "location": "Cusco Region, Peru",
        "coordinates": [-13.1631, -72.5450],
        "description": "An iconic Inca citadel set high in the Andes Mountains, renowned for its sophisticated dry-stone construction.",
        "wikipedia": "https://en.wikipedia.org/wiki/Machu_Picchu",
        "constructedDate": "c. 1450 AD",
        "isUnesco": false
    },
    {
        "name": "Nuraghe Su Nuraxi",
        "location": "Barumini, Sardinia, Italy",
        "coordinates": [39.7033, 8.9900],
        "description": "A prehistoric stone structure in Sardinia, representing one of the most important archaeological sites of the Nuragic civilization.",
        "wikipedia": "https://en.wikipedia.org/wiki/Su_Nuraxi_di_Barumini",
        "constructedDate": "c. 1500 BC",
        "isUnesco": false
    },
    {
        "name": "Tikal",
        "location": "Petén Basin, Guatemala",
        "coordinates": [17.2212, -89.6237],
        "description": "An ancient Mayan city, famous for its monumental architecture and significant historical importance.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tikal",
        "constructedDate": "c. 400 BC",
        "isUnesco": false
    },
    {
        "name": "Borobudur",
        "location": "Central Java, Indonesia",
        "coordinates": [-7.6079, 110.2038],
        "description": "A 9th-century Mahayana Buddhist temple and the world's largest Buddhist temple, known for its unique architecture.",
        "wikipedia": "https://en.wikipedia.org/wiki/Borobudur",
        "constructedDate": "c. 800 AD",
        "isUnesco": false
    },
    {
        "name": "Knap of Howar",
        "location": "Papa Westray, Scotland",
        "coordinates": [59.3535, -2.9090],
        "description": "One of the oldest preserved stone houses in northern Europe, offering insights into Neolithic life.",
        "wikipedia": "https://en.wikipedia.org/wiki/Knap_of_Howar",
        "constructedDate": "c. 3700 BC",
        "isUnesco": false
    },
    {
        "name": "Derinkuyu",
        "location": "Cappadocia, Turkey",
        "coordinates": [38.3735, 34.7376],
        "description": "An ancient multi-level underground city with sophisticated architecture, used as a refuge during times of war.",
        "wikipedia": "https://en.wikipedia.org/wiki/Derinkuyu_underground_city",
        "constructedDate": "c. 8th century BC",
        "isUnesco": false
    },
    {
        "name": "Karahan Tepe",
        "location": "Şanlıurfa Province, Turkey",
        "coordinates": [37.5123, 39.2796],
        "description": "An archaeological site with large T-shaped stone pillars, similar to Göbekli Tepe, indicating early human settlement.",
        "wikipedia": "https://en.wikipedia.org/wiki/Karahan_Tepe",
        "constructedDate": "c. 9500 BC",
        "isUnesco": false
    },
    {
        "name": "Colosseum",
        "location": "Rome, Italy",
        "coordinates": [41.8902, 12.4922],
        "description": "An ancient Roman amphitheater, known as the largest ever built, used for gladiatorial contests and public spectacles.",
        "wikipedia": "https://en.wikipedia.org/wiki/Colosseum",
        "constructedDate": "c. 70–80 AD",
        "isUnesco": false
    },
    {
        "name": "Pantheon",
        "location": "Rome, Italy",
        "coordinates": [41.8986, 12.4769],
        "description": "A former Roman temple, now a church, known for its large dome and oculus. It is one of the best-preserved ancient Roman buildings.",
        "wikipedia": "https://en.wikipedia.org/wiki/Pantheon,_Rome",
        "constructedDate": "c. 113–125 AD",
        "isUnesco": false
    },
    {
        "name": "Roman Forum",
        "location": "Rome, Italy",
        "coordinates": [41.8925, 12.4853],
        "description": "The heart of ancient Rome, the forum was the site of triumphal processions, elections, and public speeches.",
        "wikipedia": "https://en.wikipedia.org/wiki/Roman_Forum",
        "constructedDate": "c. 7th century BC onwards",
        "isUnesco": false
    },
    {
        "name": "Baths of Caracalla",
        "location": "Rome, Italy",
        "coordinates": [41.8789, 12.4923],
        "description": "One of the largest and most impressive ancient Roman public baths, known for their elaborate architectural design.",
        "wikipedia": "https://en.wikipedia.org/wiki/Baths_of_Caracalla",
        "constructedDate": "c. 212–216 AD",
        "isUnesco": false
    },
    {
        "name": "Circus Maximus",
        "location": "Rome, Italy",
        "coordinates": [41.8852, 12.4853],
        "description": "An ancient Roman chariot-racing stadium and mass entertainment venue, it was the first and largest stadium in ancient Rome.",
        "wikipedia": "https://en.wikipedia.org/wiki/Circus_Maximus",
        "constructedDate": "6th century BC",
        "isUnesco": false
    },
    {
        "name": "Castel Sant'Angelo",
        "location": "Rome, Italy",
        "coordinates": [41.9031, 12.4663],
        "description": "Originally built as a mausoleum for Emperor Hadrian, later used by the popes as a fortress and castle.",
        "wikipedia": "https://en.wikipedia.org/wiki/Castel_Sant%27Angelo",
        "constructedDate": "c. 135 AD",
        "isUnesco": false
    },
    {
        "name": "Ara Pacis",
        "location": "Rome, Italy",
        "coordinates": [41.9115, 12.4766],
        "description": "An altar dedicated to Pax, the Roman goddess of Peace, commissioned by the Roman Senate to honor the return of Augustus.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ara_Pacis",
        "constructedDate": "13 BC",
        "isUnesco": false
    },
    {
        "name": "Trajan's Column",
        "location": "Rome, Italy",
        "coordinates": [41.8958, 12.4845],
        "description": "A Roman triumphal column commemorating Roman emperor Trajan's victory in the Dacian Wars, known for its spiral bas relief.",
        "wikipedia": "https://en.wikipedia.org/wiki/Trajan%27s_Column",
        "constructedDate": "113 AD",
        "isUnesco": false
    },
    {
        "name": "Palatine Hill",
        "location": "Rome, Italy",
        "coordinates": [41.8895, 12.4884],
        "description": "One of the most ancient parts of the city, known for its archaeological remains of palaces and temples.",
        "wikipedia": "https://en.wikipedia.org/wiki/Palatine_Hill",
        "constructedDate": "8th century BC onwards",
        "isUnesco": false
    },
    {
        "name": "Kourion",
        "location": "Limassol, Cyprus",
        "coordinates": [34.6645, 32.8872],
        "description": "An ancient city-kingdom with a well-preserved Greco-Roman theater, public baths, and mosaics.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kourion",
        "constructedDate": "c. 12th century BC",
        "isUnesco": false
    },
    {
        "name": "Amathus",
        "location": "Limassol, Cyprus",
        "coordinates": [34.7046, 33.1414],
        "description": "One of the ancient royal cities of Cyprus, known for its ruins, including the Temple of Aphrodite and the Acropolis.",
        "wikipedia": "https://en.wikipedia.org/wiki/Amathus",
        "constructedDate": "c. 1100 BC",
        "isUnesco": false
    },
    {
        "name": "Kolossi Castle",
        "location": "Limassol, Cyprus",
        "coordinates": [34.6674, 32.9311],
        "description": "A medieval castle used by the Knights of St John, significant for its architecture and historical importance.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kolossi_Castle",
        "constructedDate": "c. 1210 AD",
        "isUnesco": false
    },
    {
        "name": "Sanctuary of Apollo Hylates",
        "location": "Episkopi, Limassol, Cyprus",
        "coordinates": [34.6642, 32.8672],
        "description": "A religious sanctuary dedicated to Apollo, featuring various structures like the temple, baths, and gymnasium.",
        "wikipedia": "https://en.wikipedia.org/wiki/Sanctuary_of_Apollo_Hylates",
        "constructedDate": "c. 8th century BC",
        "isUnesco": false
    },
    {
        "name": "Limassol Castle",
        "location": "Limassol, Cyprus",
        "coordinates": [34.6755, 33.0427],
        "description": "A medieval castle located near the old port, known for its historical significance and housing the Cyprus Medieval Museum.",
        "wikipedia": "https://en.wikipedia.org/wiki/Limassol_Castle",
        "constructedDate": "c. 12th century AD",
        "isUnesco": false
    },
    {
        "name": "Curium Basilica",
        "location": "Kourion, Limassol, Cyprus",
        "coordinates": [34.6633, 32.8825],
        "description": "A large early Christian basilica with mosaics, part of the ancient city of Kourion.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kourion",
        "constructedDate": "c. 5th century AD",
        "isUnesco": false
    },
    {
        "name": "Paphos Archaeological Park",
        "location": "Paphos, Cyprus",
        "coordinates": [34.7550, 32.4066],
        "description": "A UNESCO World Heritage site known for its well-preserved mosaics, ancient villas, and Roman ruins.",
        "wikipedia": "https://en.wikipedia.org/wiki/Paphos_Archaeological_Park",
        "constructedDate": "c. 4th century BC",
        "isUnesco": false
    },
    {
        "name": "Tombs of the Kings",
        "location": "Paphos, Cyprus",
        "coordinates": [34.7761, 32.4065],
        "description": "An ancient necropolis with underground tombs carved out of solid rock, used by high-ranking officials.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tombs_of_the_Kings_(Paphos)",
        "constructedDate": "c. 4th century BC",
        "isUnesco": false
    },
    {
        "name": "Panagia Chrysopolitissa Basilica",
        "location": "Paphos, Cyprus",
        "coordinates": [34.7593, 32.4103],
        "description": "An early Christian basilica known for its columns, including St. Paul's Pillar, where he was said to be flogged.",
        "wikipedia": "https://en.wikipedia.org/wiki/Panagia_Chrysopolitissa_Church",
        "constructedDate": "c. 4th century AD",
        "isUnesco": false
    },
    {
        "name": "Paphos Castle",
        "location": "Paphos, Cyprus",
        "coordinates": [34.7553, 32.4041],
        "description": "A medieval castle located at the harbor, originally built by the Byzantines and later used by the Ottomans.",
        "wikipedia": "https://en.wikipedia.org/wiki/Paphos_Castle",
        "constructedDate": "c. 13th century AD",
        "isUnesco": false
    },
    {
        "name": "Odeon of Paphos",
        "location": "Paphos, Cyprus",
        "coordinates": [34.7557, 32.4062],
        "description": "An ancient Greek-Roman theater used for musical performances and gatherings, part of the Paphos Archaeological Park.",
        "wikipedia": "https://en.wikipedia.org/wiki/Odeon_(building)",
        "constructedDate": "c. 2nd century AD",
        "isUnesco": false
    },
    {
        "name": "House of Dionysus",
        "location": "Paphos, Cyprus",
        "coordinates": [34.7545, 32.4073],
        "description": "A Roman villa famous for its intricate floor mosaics depicting scenes from Greek mythology.",
        "wikipedia": "https://en.wikipedia.org/wiki/House_of_Dionysus_(Paphos)",
        "constructedDate": "c. 2nd century AD",
        "isUnesco": false
    },
    {
        "name": "Famagusta Gate",
        "location": "Nicosia, Cyprus",
        "coordinates": [35.1691, 33.3658],
        "description": "One of the three original gates in the Venetian walls of Nicosia, beautifully restored and used for cultural events.",
        "wikipedia": "https://en.wikipedia.org/wiki/Famagusta_Gate",
        "constructedDate": "c. 1567 AD",
        "isUnesco": false
    },
    {
        "name": "Venetian Walls of Nicosia",
        "location": "Nicosia, Cyprus",
        "coordinates": [35.1739, 33.3650],
        "description": "A series of fortifications surrounding the old city of Nicosia, built by the Venetians to protect against Ottoman attacks.",
        "wikipedia": "https://en.wikipedia.org/wiki/Venetian_Walls_of_Nicosia",
        "constructedDate": "c. 1567 AD",
        "isUnesco": false
    },
    {
        "name": "St. John's Cathedral",
        "location": "Nicosia, Cyprus",
        "coordinates": [35.1733, 33.3628],
        "description": "A small 17th-century Orthodox cathedral, known for its beautiful frescoes and as the seat of the Archbishop of Cyprus.",
        "wikipedia": "https://en.wikipedia.org/wiki/Saint_John%27s_Cathedral,_Nicosia",
        "constructedDate": "c. 1662 AD",
        "isUnesco": false
    },
    {
        "name": "Selimiye Mosque",
        "location": "Nicosia, Cyprus",
        "coordinates": [35.1775, 33.3650],
        "description": "Originally the St. Sophia Cathedral, this structure was converted into a mosque after the Ottoman conquest of Cyprus.",
        "wikipedia": "https://en.wikipedia.org/wiki/Selimiye_Mosque,_Nicosia",
        "constructedDate": "c. 1209 AD (as a cathedral), converted in 1570 AD",
        "isUnesco": false
    },
    {
        "name": "Bedesten",
        "location": "Nicosia, Cyprus",
        "coordinates": [35.1772, 33.3642],
        "description": "A historical building that served as a covered market during Ottoman times, originally built as a church.",
        "wikipedia": "https://en.wikipedia.org/wiki/Bedesten,_Nicosia",
        "constructedDate": "12th century AD",
        "isUnesco": false
    },
    {
        "name": "Omeriye Mosque",
        "location": "Nicosia, Cyprus",
        "coordinates": [35.1683, 33.3669],
        "description": "A mosque built on the site of the Augustinian Church of St. Mary, known for its Ottoman architecture.",
        "wikipedia": "https://en.wikipedia.org/wiki/%C3%96meriye_Mosque",
        "constructedDate": "14th century AD (as a church), converted in 1571 AD",
        "isUnesco": false
    },
    {
        "name": "Nicosia Aqueduct",
        "location": "Nicosia, Cyprus",
        "coordinates": [35.1753, 33.3640],
        "description": "An ancient aqueduct that supplied water to the city, showcasing the engineering skills of the time.",
        "wikipedia": "https://en.wikipedia.org/wiki/Nicosia_aqueduct",
        "constructedDate": "c. 18th century AD",
        "isUnesco": false
    },
    {
        "name": "Church of Saint Lazarus",
        "location": "Larnaca, Cyprus",
        "coordinates": [34.9127, 33.6360],
        "description": "A 9th-century church built over the tomb of Saint Lazarus, known for its impressive Byzantine architecture.",
        "wikipedia": "https://en.wikipedia.org/wiki/Church_of_Saint_Lazarus,_Larnaca",
        "constructedDate": "c. 890 AD",
        "isUnesco": false
    },
    {
        "name": "Larnaca Salt Lake",
        "location": "Larnaca, Cyprus",
        "coordinates": [34.9081, 33.6057],
        "description": "A network of four salt lakes, known for archaeological finds and the Hala Sultan Tekke mosque on its shores.",
        "wikipedia": "https://en.wikipedia.org/wiki/Larnaca_Salt_Lake",
        "constructedDate": "Natural formation with archaeological significance",
        "isUnesco": false
    },
    {
        "name": "Hala Sultan Tekke",
        "location": "Larnaca, Cyprus",
        "coordinates": [34.8924, 33.6109],
        "description": "An important Muslim shrine located near the Larnaca Salt Lake, believed to be the burial site of Prophet Muhammad's aunt.",
        "wikipedia": "https://en.wikipedia.org/wiki/Hala_Sultan_Tekke",
        "constructedDate": "c. 648 AD",
        "isUnesco": false
    },
    {
        "name": "Ancient Kition",
        "location": "Larnaca, Cyprus",
        "coordinates": [34.9236, 33.6315],
        "description": "An archaeological site representing the remains of the ancient city of Kition, featuring temples and fortifications.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kition",
        "constructedDate": "c. 13th century BC",
        "isUnesco": false
    },
    {
        "name": "Larnaca Castle",
        "location": "Larnaca, Cyprus",
        "coordinates": [34.9136, 33.6355],
        "description": "Larnaca Castle is a historic fort in Larnaca, Cyprus. Originally built by the Byzantines in the late 12th century, it was later reconstructed by the Ottomans in 1625 and has served various purposes including as a prison. Today, it functions as a museum.",
        "wikipedia": "https://en.wikipedia.org/wiki/Larnaca_Castle",
        "constructedDate": "Late 12th century, reconstructed in 1625 AD",
        "isUnesco": false
    },
    {
        "name": "Kamares Aqueduct",
        "location": "Larnaca, Cyprus",
        "coordinates": [34.9225, 33.6069],
        "description": "An aqueduct built during Ottoman rule, known for its architectural beauty and engineering skill.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kamares_Aqueduct",
        "constructedDate": "c. 1747 AD",
        "isUnesco": false
    },
    {
        "name": "Zeno of Citium's Tomb",
        "location": "Larnaca, Cyprus",
        "coordinates": [34.9230, 33.6303],
        "description": "The tomb of Zeno, the founder of the Stoic school of philosophy, located in the ancient city of Kition.",
        "wikipedia": "https://en.wikipedia.org/wiki/Zeno_of_Citium",
        "constructedDate": "c. 4th century BC",
        "isUnesco": false
    },
    {
        "name": "Salamis Ruins",
        "location": "Famagusta, Cyprus",
        "coordinates": [35.1919, 33.8903],
        "description": "An ancient city with extensive ruins, including a gymnasium, baths, and an amphitheater, reflecting its historical significance.",
        "wikipedia": "https://en.wikipedia.org/wiki/Salamis,_Cyprus",
        "constructedDate": "c. 11th century BC",
        "isUnesco": false
    },
    {
        "name": "Othello Castle",
        "location": "Famagusta, Cyprus",
        "coordinates": [35.1222, 33.9417],
        "description": "A medieval fortress, also known as the Citadel of Famagusta, famous for its role in Shakespeare's play 'Othello.'",
        "wikipedia": "https://en.wikipedia.org/wiki/Othello_Castle",
        "constructedDate": "c. 14th century AD",
        "isUnesco": false
    },
    {
        "name": "Lala Mustafa Pasha Mosque",
        "location": "Famagusta, Cyprus",
        "coordinates": [35.1250, 33.9425],
        "description": "Originally the Cathedral of Saint Nicholas, this Gothic-style building was converted into a mosque by the Ottomans.",
        "wikipedia": "https://en.wikipedia.org/wiki/Lala_Mustafa_Pasha_Mosque",
        "constructedDate": "c. 1328 AD",
        "isUnesco": false
    },
    {
        "name": "Fortifications of Famagusta",
        "location": "Famagusta, Cyprus",
        "coordinates": [35.1204, 33.9410],
        "description": "The Fortifications of Famagusta are a series of defensive walls that surround the city of Famagusta in Cyprus. Originally built by the Lusignans in the 14th century, they were significantly strengthened by the Venetians in the 15th and 16th centuries to protect the city from Ottoman attacks. The walls include several bastions, gates, and a moat.",
        "wikipedia": "https://en.wikipedia.org/wiki/Fortifications_of_Famagusta",
        "constructedDate": "c. 14th century, strengthened in the 15th–16th centuries AD",
        "isUnesco": false
    },
    {
        "name": "Saint Barnabas Monastery",
        "location": "Famagusta, Cyprus",
        "coordinates": [35.1538, 33.8846],
        "description": "A monastery dedicated to Saint Barnabas, featuring a church, tomb, and an archaeological museum.",
        "wikipedia": "https://en.wikipedia.org/wiki/Monastery_of_Saint_Barnabas",
        "constructedDate": "c. 477 AD",
        "isUnesco": false
    },
    {
        "name": "Enkomi",
        "location": "Famagusta, Cyprus",
        "coordinates": [35.1438, 33.9200],
        "description": "An ancient Bronze Age city with archaeological remains, including a large necropolis and administrative buildings.",
        "wikipedia": "https://en.wikipedia.org/wiki/Enkomi",
        "constructedDate": "c. 1600 BC",
        "isUnesco": false
    },
    {
        "name": "Kantara Castle",
        "location": "Famagusta, Cyprus",
        "coordinates": [35.4131, 33.8956],
        "description": "A Byzantine-era castle strategically located on a hill, providing panoramic views of the surrounding landscape.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kantara_Castle",
        "constructedDate": "c. 10th century AD",
        "isUnesco": false
    },
    {
        "name": "Kyrenia Castle",
        "location": "Kyrenia, Cyprus",
        "coordinates": [35.3405, 33.3173],
        "description": "A historic castle located at the east end of the old harbour, originally built by the Byzantines and later expanded by the Venetians.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kyrenia_Castle",
        "constructedDate": "c. 7th century AD",
        "isUnesco": false
    },
    {
        "name": "Shipwreck Museum",
        "location": "Kyrenia Castle, Cyprus",
        "coordinates": [35.3405, 33.3173],
        "description": "Located within Kyrenia Castle, this museum houses the remains of a 4th-century BC Greek merchant ship and its cargo.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kyrenia_ship",
        "constructedDate": "c. 300 BC (shipwreck), museum established in modern times",
        "isUnesco": false
    },
    {
        "name": "Bellapais Abbey",
        "location": "Kyrenia, Cyprus",
        "coordinates": [35.3339, 33.3539],
        "description": "A 13th-century monastery known for its Gothic architecture, offering stunning views of the surrounding landscape.",
        "wikipedia": "https://en.wikipedia.org/wiki/Bellapais_Abbey",
        "constructedDate": "c. 1200 AD",
        "isUnesco": false
    },
    {
        "name": "Saint Hilarion Castle",
        "location": "Kyrenia, Cyprus",
        "coordinates": [35.3167, 33.3333],
        "description": "A former monastery and castle located on a rocky peak, known for its panoramic views and fairy-tale architecture.",
        "wikipedia": "https://en.wikipedia.org/wiki/St._Hilarion_Castle",
        "constructedDate": "c. 10th century AD",
        "isUnesco": false
    },
    {
        "name": "Antiphonitis Church",
        "location": "Kyrenia, Cyprus",
        "coordinates": [35.3870, 33.5372],
        "description": "A medieval domed church, renowned for its well-preserved frescoes and Byzantine architecture.",
        "wikipedia": "https://en.wikipedia.org/wiki/Antiphonitis",
        "constructedDate": "c. 12th century AD",
        "isUnesco": false
    },
    {
        "name": "Buffavento Castle",
        "location": "Kyrenia, Cyprus",
        "coordinates": [35.2928, 33.4081],
        "description": "One of the three famous castles in the Kyrenia mountain range, built to ward off Arab raids and provide refuge.",
        "wikipedia": "https://en.wikipedia.org/wiki/Buffavento_Castle",
        "constructedDate": "c. 11th century AD",
        "isUnesco": false
    },
    {
        "name": "Great Pyramid of Cholula",
        "location": "Cholula, Puebla, Mexico",
        "coordinates": [19.0544, -98.3094],
        "description": "The largest pyramid by volume in the world, known for its massive base and the church built on its summit.",
        "wikipedia": "https://en.wikipedia.org/wiki/Great_Pyramid_of_Cholula",
        "constructedDate": "c. 3rd century BC",
        "isUnesco": false
    },
    {
        "name": "Bimini Road",
        "location": "Bimini, Bahamas",
        "coordinates": [25.7553, -79.2810],
        "description": "A mysterious underwater rock formation located near North Bimini Island, consisting of a series of large rectangular limestone blocks.",
        "wikipedia": "https://en.wikipedia.org/wiki/Bimini_Road",
        "constructedDate": "Unknown; debated whether natural or man-made",
        "isUnesco": false
    },
    {
        "name": "Banaue Rice Terraces",
        "location": "Ifugao, Philippines",
        "coordinates": [16.9115, 121.0579],
        "description": "Ancient terraces carved into the mountains by the Ifugao people over 2,000 years ago, often referred to as the 'Eighth Wonder of the World.'",
        "wikipedia": "https://en.wikipedia.org/wiki/Banaue_Rice_Terraces",
        "constructedDate": "c. 1000 BC",
        "isUnesco": false
    },
    {
        "name": "Angono Petroglyphs",
        "location": "Rizal, Philippines",
        "coordinates": [14.5271, 121.1941],
        "description": "The oldest known artwork in the Philippines, featuring rock engravings dating back to the late Neolithic period.",
        "wikipedia": "https://en.wikipedia.org/wiki/Angono_Petroglyphs",
        "constructedDate": "c. 3000 BC",
        "isUnesco": false
    },
    {
        "name": "Limestone Tombs of Kamhantik",
        "location": "Quezon, Philippines",
        "coordinates": [13.7365, 122.4313],
        "description": "An archaeological site featuring limestone coffins, believed to date back to the 10th to 14th centuries.",
        "wikipedia": "https://en.wikipedia.org/wiki/Archaeology_of_the_Philippines#Luzon",
        "constructedDate": "c. 900 AD",
        "isUnesco": false
    },
    {
        "name": "Manunggul Jar",
        "location": "Palawan, Philippines",
        "coordinates": [9.9578, 118.7126],
        "description": "A secondary burial jar from the Tabon Caves, known for its intricate lid featuring two human figures, a masterpiece of pre-colonial art.",
        "wikipedia": "https://en.wikipedia.org/wiki/Manunggul_Jar",
        "constructedDate": "c. 890–710 BC",
        "isUnesco": false
    },
    {
        "name": "Butuan Silver Paleograph",
        "location": "Butuan, Philippines",
        "coordinates": [8.9504, 125.5436],
        "description": "An ancient silver strip with inscriptions believed to be one of the earliest forms of writing in the Philippines.",
        "wikipedia": "https://en.wikipedia.org/wiki/History_of_Butuan",
        "constructedDate": "c. 10th century AD",
        "isUnesco": false
    },
    {
        "name": "Acropolis of Athens",
        "location": "Athens, Greece",
        "coordinates": [37.9715, 23.7266],
        "description": "A historic citadel containing the remains of several ancient buildings of great architectural and historic significance, including the Parthenon.",
        "wikipedia": "https://en.wikipedia.org/wiki/Acropolis_of_Athens",
        "constructedDate": "c. 447 BC",
        "isUnesco": false
    },
    {
        "name": "Parthenon",
        "location": "Athens, Greece",
        "coordinates": [37.9715, 23.7266],
        "description": "A former temple on the Athenian Acropolis, dedicated to the goddess Athena, whom the people of Athens considered their patroness.",
        "wikipedia": "https://en.wikipedia.org/wiki/Parthenon",
        "constructedDate": "c. 447–432 BC",
        "isUnesco": false
    },
    {
        "name": "Temple of Olympian Zeus",
        "location": "Athens, Greece",
        "coordinates": [37.9681, 23.7332],
        "description": "A colossal ruined temple in the center of the Greek capital, dedicated to Zeus, king of the Olympian gods.",
        "wikipedia": "https://en.wikipedia.org/wiki/Temple_of_Olympian_Zeus,_Athens",
        "constructedDate": "c. 6th century BC, completed 2nd century AD",
        "isUnesco": false
    },
    {
        "name": "The Erechtheion",
        "location": "Athens, Greece",
        "coordinates": [37.9722, 23.7265],
        "description": "An ancient Greek temple on the Acropolis of Athens which was dedicated to both Athena and Poseidon.",
        "wikipedia": "https://en.wikipedia.org/wiki/Erechtheion",
        "constructedDate": "c. 421–406 BC",
        "isUnesco": false
    },
    {
        "name": "Theatre of Dionysus",
        "location": "Athens, Greece",
        "coordinates": [37.9695, 23.7285],
        "description": "A major open-air theatre and one of the earliest preserved in Athens, used for festivals in honor of the god Dionysus.",
        "wikipedia": "https://en.wikipedia.org/wiki/Theatre_of_Dionysus",
        "constructedDate": "c. 6th century BC",
        "isUnesco": false
    },
    {
        "name": "Odeon of Herodes Atticus",
        "location": "Athens, Greece",
        "coordinates": [37.9699, 23.7246],
        "description": "A stone theatre structure located on the southwest slope of the Acropolis of Athens, built in 161 AD by Herodes Atticus in memory of his wife.",
        "wikipedia": "https://en.wikipedia.org/wiki/Odeon_of_Herodes_Atticus",
        "constructedDate": "c. 161 AD",
        "isUnesco": false
    },
    {
        "name": "Stoa of Attalos",
        "location": "Athens, Greece",
        "coordinates": [37.9751, 23.7220],
        "description": "A stoa (covered walkway or portico) in the Agora of Athens, originally built by King Attalos II of Pergamon and reconstructed in modern times.",
        "wikipedia": "https://en.wikipedia.org/wiki/Stoa_of_Attalos",
        "constructedDate": "c. 159–138 BC",
        "isUnesco": false
    },
    {
        "name": "Temple of Hephaestus",
        "location": "Athens, Greece",
        "coordinates": [37.9763, 23.7206],
        "description": "A well-preserved Greek temple dedicated to Hephaestus and Athena Ergane, located at the north-west side of the Agora of Athens.",
        "wikipedia": "https://en.wikipedia.org/wiki/Temple_of_Hephaestus",
        "constructedDate": "c. 449–415 BC",
        "isUnesco": false
    },
    {
        "name": "Por-Bazhyn",
        "location": "Tuva, Russia",
        "coordinates": [50.5878, 97.3928],
        "description": "Por-Bazhyn is a ruined structure located on a lake island in the mountains of southern Tuva, Russia. The name means 'clay house' in Tuvan. Excavations suggest it was initially built as a Uyghur palace in the 8th century AD, later converted into a Manichaean monastery, and then abandoned after a short period of use. The site was ultimately destroyed by an earthquake and a subsequent fire. The construction reflects the Tang Chinese architectural tradition.",
        "wikipedia": "https://en.wikipedia.org/wiki/Por-Bazhyn",
        "constructedDate": "8th century AD",
        "isUnesco": false
    },
    {
        "name": "Aapravasi Ghat",
        "location": "Port Louis District, Mauritius",
        "coordinates": [
            -20.158611,
            57.503056
        ],
        "description": "After the British abolished slavery in Mauritius, the Aapravasi Ghat was chosen by the British government to receive  Indian indentured laborers into the country to work on farms and sugar estates. Between 1834 and 1920, almost half a million contracted workers passed through Port Louis from India, either to work in Mauritius or to transfer to other British colonies.",
        "wikipedia": "https://en.wikipedia.org/wiki/Aapravasi_Ghat",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Abu Mena†",
        "location": "Abusir, Egypt",
        "coordinates": [
            30.84098,
            29.663117
        ],
        "description": "The ruins of the former Christian holy city contain a church, a baptistery, basilicas, public buildings, streets, monasteries, houses, and workshops, and were built over the tomb of Menas of Alexandria. The World Heritage Committee designated Abu Mena as an endangered site in 2001, due to cave-ins in the area caused by the clay at the surface, which becomes semi-liquid when met with \"excess water\".",
        "wikipedia": "https://en.wikipedia.org/wiki/Abu_Mena",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Air and Ténéré Natural Reserves†",
        "location": "Arlit Department, Niger",
        "coordinates": [
            18.0,
            9.0
        ],
        "description": "Africa's largest protected area, located in the Saharan desert of Ténéré, consists of the volcanic rock mass of Aïr and a small isolated Sahelian pocket with unique flora and fauna. The natural reserve was placed on UNESCO's List of World Heritage in Danger in 1992, due to the increase in military conflicts and the hostage-taking of six reserve staff in February. Removal from this list was considered in 1999, but as of 2011, its position remains unchanged.",
        "wikipedia": "https://en.wikipedia.org/wiki/A%C3%AFr_and_T%C3%A9n%C3%A9r%C3%A9_National_Nature_Reserve",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Aksum",
        "location": "Tigray Region, Ethiopia",
        "coordinates": [
            14.13019,
            38.718605
        ],
        "description": "The ruins of the city of Aksum, dating from the 1st to the 13th century, marks the heart of ancient Ethiopian civilisation. It includes monolithic obelisks, giant stelae, royal tombs, and ruins of former castles.",
        "wikipedia": "https://en.wikipedia.org/wiki/Axum",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Al Qal'a of Beni Hammad",
        "location": "Maadid, Algeria",
        "coordinates": [
            35.81844,
            4.78684
        ],
        "description": "The fortified first capital of the Hammadid emirs was originally built in 1007 and demolished in 1152. It features an 8-bay, 13-aisle mosque, which is one of the largest in Algeria.",
        "wikipedia": "https://en.wikipedia.org/wiki/Qal%27at_Bani_Hammad",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Aldabra Atoll",
        "location": "Aldabra Group, Seychelles",
        "coordinates": [
            -9.416681,
            46.41665
        ],
        "description": "The Aldabra Atoll consists of four large coral islands and a lagoon, surrounded by a coral reef. The islands are home to the world's largest population of giant tortoises.",
        "wikipedia": "https://en.wikipedia.org/wiki/Aldabra",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Amphitheatre of El Jem",
        "location": "El Djem, Tunisia",
        "coordinates": [
            35.29639,
            10.70694
        ],
        "description": "The Amphitheatre of El Jem, built during the 3rd century, is North Africa's largest amphitheatre, and the largest one built outside of Italy, with a capacity of 35,000 spectators, and \"illustrates the grandeur and extent of Imperial Rome\".",
        "wikipedia": "https://en.wikipedia.org/wiki/Amphitheatre_of_El_Jem",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Ancient Ferrous Metallurgy Sites of Burkina Faso",
        "location": "Douroula, Burkina Faso",
        "coordinates": [
            12.5877583,
            -3.3289861
        ],
        "description": "A series of five archaeological locations spread across the country, recognized for their historical significance in the development and practice of iron smelting technology in Africa. Each site contains remains of furnaces and other features associated with the iron production process, showcasing the technological innovation and skill of the people in this region.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ancient_Ferrous_Metallurgy_Sites_of_Burkina_Faso",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Ancient Ksour of Ouadane, Chinguetti, Tichitt and Oualata",
        "location": "Chinguetti, Mauritania",
        "coordinates": [
            20.92889,
            -11.62361
        ],
        "description": "\"Founded in the 11th and 12th centuries to serve the caravans crossing the Sahara, these trading and religious centres became focal points of Islamic culture.  Typically, houses with patios crowd along narrow streets around a mosque with a square minaret. They illustrate a traditional way of life centred on the nomadic culture of the people of the western Sahara.\"",
        "wikipedia": "https://en.wikipedia.org/wiki/Ancient_Ksour_of_Ouadane,_Chinguetti,_Tichitt_and_Oualata",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Ancient Thebes with its Necropolis",
        "location": "Luxor, Egypt",
        "coordinates": [
            25.73333,
            32.6
        ],
        "description": "The former capital of Egypt and city of the Egyptian god Amun contains relics from the height of Ancient Egypt. The temples, palaces, and the necropolises of the Valley of the Kings and the Valley of the Queens bear \"a striking testimony to Egyptian civilization\".",
        "wikipedia": "https://en.wikipedia.org/wiki/Thebes,_Egypt",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Archaeological Site of Carthage",
        "location": "Tunis, Tunisia",
        "coordinates": [
            36.85278,
            10.32333
        ],
        "description": "Founded in the 9th century BCE, Carthage was developed into a trading empire spanning the Mediterranean and was, according to UNESCO, \"home to a brilliant civilization\". The city was destroyed in 146 BCE in the Punic Wars at the hands of the Romans, but was later rebuilt by these.",
        "wikipedia": "https://en.wikipedia.org/wiki/Archaeological_Site_of_Carthage",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Archaeological Site of Cyrene†",
        "location": "Jebel Akhdar, Libya",
        "coordinates": [
            32.825,
            21.85833
        ],
        "description": "The formerly Greek colony was Romanized and transformed into a capital, until it was destroyed by the 365 Crete earthquake. The thousand-year-old ruins have remained renowned since the 18th century. All five sites in Libya, including Cyrene, were placed on UNESCO's List of World Heritage in Danger in 2016, due to ongoing conflict in the country.",
        "wikipedia": "https://en.wikipedia.org/wiki/Cyrene,_Libya",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Archaeological Site of Leptis Magna†",
        "location": "Khoms, Libya",
        "coordinates": [
            32.63833,
            14.29306
        ],
        "description": "The Roman city of Leptis Magna was enlarged by Emperor Septimius Severus, who was born there. Public monuments, a harbour, a marketplace, storehouses, shops, and homes were among the reasons for its induction into the list. All five sites in Libya, including Leptis Magna, were placed on UNESCO's List of World Heritage in Danger in 2016, due to ongoing conflict in the country.",
        "wikipedia": "https://en.wikipedia.org/wiki/Leptis_Magna",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Archaeological Site of Sabratha†",
        "location": "Sabratha, Libya",
        "coordinates": [
            32.80528,
            12.485
        ],
        "description": "\"A Phoenician trading-post that served as an outlet for the products of the African hinterland, Sabratha was part of the short-lived Numidian Kingdom of Massinissa before being Romanized and rebuilt in the 2nd and 3rd centuries.\" All five sites in Libya, including Sabratha, were placed on UNESCO's List of World Heritage in Danger in 2016, due to ongoing conflict in the country.",
        "wikipedia": "https://en.wikipedia.org/wiki/Sabratha",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Archaeological Site of Volubilis",
        "location": "Meknes, Morocco",
        "coordinates": [
            34.07389,
            -5.55694
        ],
        "description": "The important Roman outpost of Volubilis was founded in the 3rd century BCE to become the capital of Mauretania. It contained many buildings, the remains of which have survived extensively to this day.",
        "wikipedia": "https://en.wikipedia.org/wiki/Volubilis",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Archaeological Sites of the Island of Meroe",
        "location": "Meroë, Sudan",
        "coordinates": [
            16.933333,
            33.716667
        ],
        "description": "The site was the centre of the Kingdom of Kush, a major force active from the 8th century BCE to the 4th century CE. It is home to pyramids, temples, and domestic buildings, among other vestiges.",
        "wikipedia": "https://en.wikipedia.org/wiki/Mero%C3%AB",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Asante Traditional Buildings",
        "location": "Kumasi, Ghana",
        "coordinates": [
            6.401111,
            -1.625833
        ],
        "description": "The site, north-east of Kumasi, hosts the final intact remains of the Ashanti Empire, which peaked in the 18th century. The dwellings, which are made of earth, wood, and straw, are susceptible to the damages caused by the \"onslaught of time and weather\".",
        "wikipedia": "https://en.wikipedia.org/wiki/Asante_Traditional_Buildings",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Asmara: A Modernist African City",
        "location": "Maekel, Eritrea",
        "coordinates": [
            15.335277777777778,
            38.935833333333335
        ],
        "description": "Located at over 2000 metres above sea level, the capital of Eritrea developed from the 1890s onwards as a military outpost for the Italian colonial power. After 1935, Asmara underwent a large scale programme of construction applying the Italian rationalist idiom of the time to governmental edifices, residential and commercial buildings, churches, mosques, synagogues, cinemas, hotels, etc.",
        "wikipedia": "https://en.wikipedia.org/wiki/Asmara",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Barberton Makhonjwa Mountains",
        "location": "Mpumalanga, South Africa",
        "coordinates": [
            -25.973889,
            31.013889
        ],
        "description": "The mountains contain the world's oldest geological structures and are known to have preserved volcanic and sedimentary rocks estimated to be between 3.6 and 3.25 billion years old.",
        "wikipedia": "https://en.wikipedia.org/wiki/Makhonjwa_Mountains",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Banc d'Arguin National Park",
        "location": "Nouadhibou, Mauritania",
        "coordinates": [
            20.23472,
            -16.10889
        ],
        "description": "The park consists of sand dunes, coastal swamps, small islands, and shallow bodies of water, all bordering the coast of the Atlantic Ocean. Birds are often found to migrate in the area, accompanied by various species of sea turtles and dolphins, whose presence fishermen often use to attract fish.",
        "wikipedia": "https://en.wikipedia.org/wiki/Banc_d%27Arguin_National_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Bassari Country: Bassari, Fula and Bedik Cultural Landscapes",
        "location": "Senegal, Senegal",
        "coordinates": [
            12.593333,
            -12.845833
        ],
        "description": "This area stands out for its cultural diversity and the preservation of traditional lifestyles, deeply intertwined with the natural environment. The communities maintain unique cultural identities, practices, and structures, reflecting their adaptation to the landscape. Their traditional practices, including agriculture, architecture, and religious ceremonies.",
        "wikipedia": "https://en.wikipedia.org/wiki/Bassari_Country",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Bwindi Impenetrable National Park",
        "location": "Kabale District, Uganda",
        "coordinates": [
            -1.080556,
            29.661389
        ],
        "description": "Located on the border of plains and mountain forests, the park in south-western Uganda is home to over 160 species of trees, over a hundred species of ferns, and various species of birds and butterflies. Many endangered species are within its boundaries as well, including the mountain gorilla.",
        "wikipedia": "https://en.wikipedia.org/wiki/Bwindi_Impenetrable_National_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Cape Floral Region Protected Areas",
        "location": "Eastern Cape, South Africa",
        "coordinates": [
            -34.361111,
            18.475
        ],
        "description": "The site consists of eight protected areas that are among the richest in plant life worldwide, containing nearly 20% of Africa's total flora. Its scientific value is demonstrated by the presence of fire and radiation adaptivity in plants and seed dispersal by insects.",
        "wikipedia": "https://en.wikipedia.org/wiki/Cape_Floristic_Region",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Chongoni Rock-Art Area",
        "location": "Dedza District, Malawi",
        "coordinates": [
            -14.293333,
            34.279167
        ],
        "description": "The 127-site area contains the richest concentration of rock art in Central Africa, ranging from Stone Age paintings to contemporary work from farmers. The symbols depicted in the rock art are strongly centred around women and retain a cultural significance for the Chewa.",
        "wikipedia": "https://en.wikipedia.org/wiki/Chongoni_Rock-Art_Area",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Cidade Velha, Historic Centre of Ribeira Grande",
        "location": "Ribeira Grande, Cape Verde",
        "coordinates": [
            14.915139,
            -23.605194
        ],
        "description": "The town, in the south of the island of Santiago, was the first European colonial outpost in the tropics, with remains dating back to the 16th century. Two churches, a royal fortress, and Pillary Square help comprise the tropical town's original street layout.",
        "wikipedia": "https://en.wikipedia.org/wiki/Cidade_Velha",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Cliff of Bandiagara (Land of the Dogons)",
        "location": "Bandiagara Cercle, Mali",
        "coordinates": [
            14.33333,
            -3.41667
        ],
        "description": "The sandy plateau and cliffs of Bandiagara outline the site, featuring houses, granaries, altars, sanctuaries, and Togu-Na meeting-places. Age-old social traditions such as masks, feasts, rituals, and ancestral worship also add to its cultural significance.",
        "wikipedia": "https://en.wikipedia.org/wiki/Cliff_of_Bandiagara",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Comoé National Park",
        "location": "Zanzan, Côte d'Ivoire",
        "coordinates": [
            9.0,
            -4.0
        ],
        "description": "Among the largest protected sites of West Africa, the park features the Comoé River and the unique flora which accompanies it. The site was placed on UNESCO's List of World Heritage in Danger in 2003, due to unrest in Côte d'Ivoire, and various other factors such as poaching, wildfires, lack of proper management of the site, and overgrazing. It was later removed from the list in 2017 following successful efforts to fight poaching, leading to increases in populations of fauna such as elephants and chimpanzees.",
        "wikipedia": "https://en.wikipedia.org/wiki/Como%C3%A9_National_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Dja Faunal Reserve",
        "location": "Dja-et-Lobo, Cameroon",
        "coordinates": [
            3.0,
            13.0
        ],
        "description": "Among Africa's largest and best-protected rain forests, the Cameroonian reserve is almost completely surrounded by the Dja River and contains 107 mammal species, of which five are threatened.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dja_Faunal_Reserve",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Djémila",
        "location": "Sétif, Algeria",
        "coordinates": [
            36.32056,
            5.73667
        ],
        "description": "The ruins of a Roman town in a mountainous location, including a forum, temples, basilicas, triumphal arches, and houses, each adapted to a location 900 m (3,000 ft) above sea level.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dj%C3%A9mila",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Djoudj National Bird Sanctuary",
        "location": "Saint-Louis Region, Senegal",
        "coordinates": [
            16.5,
            -16.16667
        ],
        "description": "The Senegal River delta wetland area consists of streams, lakes, ponds, and backwaters. It is the home to 1.5 million birds, including the great white pelican, the purple heron, the African spoonbill, the great egret, and cormorants. The sanctuary also features crocodiles, African manatees, and other typical Sahelian species. The site was previously on the danger list from 1984 to 1988 due to potential impact from a dam and again from 2000 to 2006 due to invasive species.",
        "wikipedia": "https://en.wikipedia.org/wiki/Djoudj_National_Bird_Sanctuary",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Dougga / Thugga",
        "location": "Béja Governorate, Tunisia",
        "coordinates": [
            36.42361,
            9.22028
        ],
        "description": "The site features the ruins of Dougga, a former capital of a Libyan–Punic state, which flourished under Ancient Rome and the Byzantine Empire, but declined in the Islamic period.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dougga",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Ecosystem and Relict Cultural Landscape of Lopé-Okanda",
        "location": "Ogooué-Ivindo, Gabon",
        "coordinates": [
            0.5,
            11.5
        ],
        "description": "The park features well-preserved tropical rain forests and savanna, resulting in a diverse ecosystem consisting of endangered, large mammals.",
        "wikipedia": "https://en.wikipedia.org/wiki/Lop%C3%A9_National_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Ennedi Massif: Natural and Cultural Landscape",
        "location": "Chad, Chad",
        "coordinates": [
            17.04167,
            21.86278
        ],
        "description": "",
        "wikipedia": "https://en.wikipedia.org/wiki/Ennedi_Plateau",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Fasil Ghebbi, Gondar Region",
        "location": "Amhara Region, Ethiopia",
        "coordinates": [
            12.60692,
            37.46617
        ],
        "description": "The fortress was the residence of the Ethiopian emperors during the 16th and 17th century. The city remains, which feature buildings with Hindu and Arab influences, were later remodelled with Baroque-style architecture by Jesuit missionaries.",
        "wikipedia": "https://en.wikipedia.org/wiki/Fasil_Ghebbi",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Fort Jesus, Mombasa",
        "location": "Mombasa, Kenya",
        "coordinates": [
            -4.062778,
            39.679444
        ],
        "description": "Fort Jesus is a Portuguese fort built from 1593 to 1596 on Mombasa Island to guard the old port of Mombasa, Kenya. The site's layout follows the Renaissance ideal that the human body is perfectly proportionate.",
        "wikipedia": "https://en.wikipedia.org/wiki/Fort_Jesus",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Forts and Castles, Volta, Greater Accra, Central and Western Regions",
        "location": "Central Region, Ghana",
        "coordinates": [
            5.247398,
            -0.785167
        ],
        "description": "The site features the remains of fortified trading posts, built along the Ghanaian coast between 1482 and 1786.",
        "wikipedia": "https://en.wikipedia.org/wiki/Forts_and_Castles,_Volta,_Greater_Accra,_Central_and_Western_Regions",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Fossil Hominid Sites of South Africa",
        "location": "Gauteng, South Africa",
        "coordinates": [
            -24.15861,
            29.17694
        ],
        "description": "The various fossil sites contain traces of human occupation and evolution dating 3.3 million years.",
        "wikipedia": "https://en.wikipedia.org/wiki/Cradle_of_Humankind",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "French Austral Lands and Seas",
        "location": "Crozet Islands, France",
        "coordinates": [
            -49.3803611,
            69.3528056
        ],
        "description": "",
        "wikipedia": "https://en.wikipedia.org/wiki/French_Austral_Lands_and_Seas",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Garajonay National Park",
        "location": "La Gomera, Spain",
        "coordinates": [
            28.12625,
            -17.237222
        ],
        "description": "Most of the park, in the middle of the island of La Gomera of the Canary Islands, is covered with a lush laurel forest.",
        "wikipedia": "https://en.wikipedia.org/wiki/Garajonay_National_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Garamba National Park†",
        "location": "Orientale, Democratic Republic of the Congo",
        "coordinates": [
            4.0,
            29.25
        ],
        "description": "The park has vast savannas, grasslands, and woodlands, featuring elephants, giraffes, hippopotamuses, and the white rhinoceros. Garamba was deemed to be endangered following the diminution of the white rhinoceros population in the area, but it was removed from the list in 1991. However, it later regained the status in 1996, when three rangers were killed and the population of white rhinoceros fell once again.",
        "wikipedia": "https://en.wikipedia.org/wiki/Garamba_National_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Gebel Barkal and the Sites of the Napatan Region",
        "location": "Meroë, Sudan",
        "coordinates": [
            18.533333,
            31.816667
        ],
        "description": "The five sites in the Nile Valley feature temples that are testimonial to the Napatan and Meroitic cultures.",
        "wikipedia": "https://en.wikipedia.org/wiki/Gebel_Barkal_and_the_Sites_of_the_Napatan_Region",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Gough and Inaccessible Islands",
        "location": "Saint Helena, United Kingdom",
        "coordinates": [
            -40.324722,
            -9.928611
        ],
        "description": "The site represents one of the least-disrupted islands and marine ecosystems in the South Temperate Zone. The cliffs are free of introduced mammals and feature one of the world's largest colonies of seabirds.",
        "wikipedia": "https://en.wikipedia.org/wiki/Gough_Island",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Great Zimbabwe National Monument",
        "location": "Masvingo Province, Zimbabwe",
        "coordinates": [
            -20.283333,
            30.933333
        ],
        "description": "The city, now in ruins, was an important trading centre between the 11th and 15th centuries and was capital of the Bantu civilization.",
        "wikipedia": "https://en.wikipedia.org/wiki/Great_Zimbabwe",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Harar Jugol, the Fortified Historic Town",
        "location": "Harari Region, Ethiopia",
        "coordinates": [
            9.308889,
            42.137778
        ],
        "description": "The city is on a plateau and surrounded by gorges and savanna. It contains 82 mosques, 102 shrines, and unique interior design in the townhouses. It is said to be the fourth-holiest city of Islam.",
        "wikipedia": "https://en.wikipedia.org/wiki/Harar_Jugol,_the_Fortified_Historic_Town",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Historic Cairo",
        "location": "Cairo, Egypt",
        "coordinates": [
            30.05,
            31.26111
        ],
        "description": "One of the world's oldest Islamic cities and in the middle of urban Cairo, the site dates from the 10th century and reached its golden age in the 14th century. It contains mosques, madrasahs, hammams, and fountains.",
        "wikipedia": "https://en.wikipedia.org/wiki/Islamic_Cairo",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Historic Centre of Agadez",
        "location": "Tchirozerine Department, Niger",
        "coordinates": [
            16.973611,
            7.991389
        ],
        "description": "",
        "wikipedia": "https://en.wikipedia.org/wiki/Agadez",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Historic City of Meknes",
        "location": "Meknes, Morocco",
        "coordinates": [
            33.88333,
            -5.55833
        ],
        "description": "The former capital was founded in the 11th century and turned into a city with Spanish-Moorish influence during the 17th and 18th centuries.",
        "wikipedia": "https://en.wikipedia.org/wiki/Meknes",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Historic Town of Grand-Bassam",
        "location": "Sud-Comoé, Côte d'Ivoire",
        "coordinates": [
            5.195914,
            -3.736369
        ],
        "description": "A colonial town built during the 19th and 20th centuries, Grand-Bassam was the first Ivorian capital following French rule in the region. Its quarters, which specialised in commerce, administration and general housing, helped the city become the economic and judicial hub of the country, in addition to being its most important port.",
        "wikipedia": "https://en.wikipedia.org/wiki/Grand-Bassam",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Ichkeul National Park",
        "location": "Bizerte, Tunisia",
        "coordinates": [
            37.16361,
            9.67472
        ],
        "description": "Ichkeul Lake and the surrounding wetlands is a destination for hundreds of thousands of migrating birds, including ducks, geese, storks, and pink flamingos. It was once part of a chain that extended across North Africa. Previously, the World Heritage Committee had designated Ichkeul National Park as an endangered site in 1996, due to construction of dams that increased the salinity of the lake and reduced its ability to support bird populations. In 2006 it was removed from the endangered list as the lake was no longer used for agriculture, reducing salinity and allowing birds to return.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ichkeul_National_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "iSimangaliso Wetland Park",
        "location": "KwaZulu-Natal, South Africa",
        "coordinates": [
            -27.83889,
            32.55
        ],
        "description": "The park features a variety of landforms, including coral reefs, long sandy beaches, coastal dunes, lake systems, and papyrus wetland, caused by fluvial, marine, and aeolian processes.",
        "wikipedia": "https://en.wikipedia.org/wiki/ISimangaliso_Wetland_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Island of Gorée",
        "location": "Dakar Region, Senegal",
        "coordinates": [
            14.66722,
            -17.40083
        ],
        "description": "The island was the largest slave-trading centre on the African coast from the 15th to the 19th century.",
        "wikipedia": "https://en.wikipedia.org/wiki/Island_of_Gor%C3%A9e",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Island of Mozambique",
        "location": "Nampula, Mozambique",
        "coordinates": [
            -15.03417,
            40.73583
        ],
        "description": "The fortified former Portuguese trading post has used the same architectural techniques, style, and materials since the 16th century.",
        "wikipedia": "https://en.wikipedia.org/wiki/Island_of_Mozambique",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Island of Saint-Louis",
        "location": "Saint-Louis Region, Senegal",
        "coordinates": [
            16.02778,
            -16.50444
        ],
        "description": "The French colonial settlement from the 17th century is on an island in the mouth of the Sénégal River. It played an important role in the culture and economy of West Africa.",
        "wikipedia": "https://en.wikipedia.org/wiki/Saint-Louis,_Senegal",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Ivindo National Park",
        "location": "Gabon, Gabon",
        "coordinates": [
            0.406111,
            12.640833
        ],
        "description": "The park spans a significant area characterized by a mosaic of ecosystems, including rainforests, rivers, and  waterfalls, most notably Kongou and Djidji waterfalls. This protected area is home to a veriety of wildlife, including endangered species such as forest elephants, lowland gorillas, and chimpanzees. The park's rivers are vital habitats for freshwater species and play a crucial role in the conservation of aquatic biodiversity in the region.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ivindo_National_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Kahuzi-Biega National Park†",
        "location": "Maniema, Democratic Republic of the Congo",
        "coordinates": [
            -2.5,
            28.75
        ],
        "description": "The park is dominated by two extinct volcanoes, Kahuzi and Biega. It also has abundant fauna, including the graueria gorillas. The park was deemed to be endangered in 1997 when deforestation and hunting became a major problem. Militia groups and illegal settlers were also settling in the park, while fire and poaching helped justify the World Heritage Committee's decision.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kahuzi-Biega_National_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Kairouan",
        "location": "Kairouan Governorate, Tunisia",
        "coordinates": [
            35.68167,
            10.10389
        ],
        "description": "The former capital was founded in 670 and flourished in the 9th century. Its heritage includes the Mosque of Uqba and the Mosque of the Three Gates.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kairouan",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Kasbah of Algiers",
        "location": "Algiers, Algeria",
        "coordinates": [
            36.78333,
            3.06028
        ],
        "description": "A unique Islamic city on the Mediterranean coast, the former site overlooks the Carthaginian trading posts of the 4th century BCE. It contains remains of a citadel, old mosques, and Ottoman-style palaces.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kasbah_of_Algiers",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Kenya Lake System in the Great Rift Valley",
        "location": "Rift Valley Province, Kenya",
        "coordinates": [
            0.4425,
            36.24
        ],
        "description": "Located in the Great Rift Valley, Kenya, the site features three lakes: Lake Bogoria, Lake Nakuru, and Lake Elementaita. A highly diverse population of birds, including thirteen threatened species, frequent the area.",
        "wikipedia": "https://en.wikipedia.org/wiki/Great_Rift_Valley,_Kenya",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Khami Ruins National Monument",
        "location": "Matabeleland, Zimbabwe",
        "coordinates": [
            -20.158333,
            28.376667
        ],
        "description": "The city was built after the mid-16th century and was an important trading centre.",
        "wikipedia": "https://en.wikipedia.org/wiki/Khami_Ruins_National_Monument",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "ǂKhomani Cultural Landscape",
        "location": "Northern Cape, South Africa",
        "coordinates": [
            -25.68761111111111,
            20.374583333333334
        ],
        "description": "\"The ǂKhomani Cultural Landscape is located at the border with Botswana and Namibia in the northern part of the country, coinciding with the Kalahari Gemsbok National Park (KGNP). The large expanse of sand contains evidence of human occupation from the Stone Age to the present and is associated with the culture of the formerly nomadic ǂKhomani San people and the strategies that allowed them to adapt to harsh desert conditions.\"",
        "wikipedia": "https://en.wikipedia.org/wiki/%C7%80Xam_and_%C7%82Khomani_heartland",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Kilimanjaro National Park",
        "location": "Kilimanjaro Region, Tanzania, United Republic of",
        "coordinates": [
            -3.06667,
            37.36667
        ],
        "description": "The volcanic massif Mount Kilimanjaro is Africa's highest point at 5,895 metres (19,341 ft) and is surrounded by a park with savanna and forest featuring numerous mammals.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kilimanjaro_National_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Kondoa Rock-Art Sites",
        "location": "Kondoa district, Tanzania, United Republic of",
        "coordinates": [
            -4.724444,
            35.833889
        ],
        "description": "Two millennia of rock carvings, many of high artistic value, have been found at 150 shelters in the site. They tell the tale of socio-economic development from hunter-gatherer to agro-pastoralism.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kondoa_Rock-Art_Sites",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Konso Cultural Landscape",
        "location": "Southern Nations, Nationalities, and People's Region, Ethiopia",
        "coordinates": [
            5.3,
            37.4
        ],
        "description": "The site features 55 kilometres (34 mi) of stonewalled terraces and fortified settlements in the Konso highlands of Ethiopia.",
        "wikipedia": "https://en.wikipedia.org/wiki/Konso",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Koutammakou, the Land of the Batammariba*",
        "location": "Benin, Benin",
        "coordinates": [
            10.066667,
            1.133333
        ],
        "description": "The site covers the Batammariba people's transnational cultural landscape, including sacred spaces and distinctive mud tower-house architecture.",
        "wikipedia": "https://en.wikipedia.org/wiki/Koutammakou,_the_Land_of_the_Batammariba",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Ksar of Ait-Ben-Haddou",
        "location": "Aït Benhaddou, Morocco",
        "coordinates": [
            31.04722,
            -7.12889
        ],
        "description": "The ksar is an example of a traditional pre-Saharan habitat, surrounded by high walls and reinforced with corner towers.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ksar_of_Ait-Ben-Haddou",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Kunta Kinteh Island and Related Sites",
        "location": "Banjul, Gambia",
        "coordinates": [
            13.316166,
            -16.357194
        ],
        "description": "The site is a testimony to the encounters between Africa and Europe from pre-colonial times to independence along the Gambia River.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kunta_Kinteh_Island_and_Related_Sites",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Lake Malawi National Park",
        "location": "Central Region, Malawi",
        "coordinates": [
            -14.03333,
            34.88333
        ],
        "description": "Lake Malawi contains hundreds of fish species, mostly endemic.",
        "wikipedia": "https://en.wikipedia.org/wiki/Lake_Malawi_National_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Lakes of Ounianga",
        "location": "Ennedi Region, Chad",
        "coordinates": [
            19.055,
            20.505556
        ],
        "description": "The Lakes of Ounianga are a series of 18 lakes located in the Sahara desert, in North-Eastern Chad. They exhibit a variety of sizes, depths, chemical compositions, and colourations, and some of them are home to aquatic fauna.",
        "wikipedia": "https://en.wikipedia.org/wiki/Lakes_of_Ounianga",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Lake Turkana National Parks†",
        "location": "Lake Turkana, Kenya",
        "coordinates": [
            3.051306,
            36.503667
        ],
        "description": "Turkana, as Africa's largest saline lake, is an important area for the study of fauna and flora. It is a breeding ground for the Nile crocodile, hippopotamus, and several venomous snakes. The site was placed the List of World Heritage in Danger in 2018, primarily due to the potential impact of Ethiopia's Gilgel Gibe III Dam.",
        "wikipedia": "https://en.wikipedia.org/wiki/Lake_Turkana_National_Parks",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Lamu Old Town",
        "location": "Lamu, Kenya",
        "coordinates": [
            -2.268,
            40.902
        ],
        "description": "The town is the oldest Swahili settlement and is built in coral stone and mangrove timber. It features inner courtyards, verandas, and elaborate wooden doors.",
        "wikipedia": "https://en.wikipedia.org/wiki/Lamu_Old_Town",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Laurisilva of Madeira",
        "location": "Madeira, Portugal",
        "coordinates": [
            32.766667,
            -17.0
        ],
        "description": "The site is the largest surviving area of laurel forest. It consists of approximately 90% old-growth forest and is home to endemic species such as the Madeiran long-toed pigeon.",
        "wikipedia": "https://en.wikipedia.org/wiki/Laurisilva",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Le Morne Cultural Landscape",
        "location": "Rivière Noire District, Mauritius",
        "coordinates": [
            -20.451944,
            57.328333
        ],
        "description": "The rugged mountain that juts into the ocean was used as a shelter by runaway slaves through the 18th and early 19th centuries. They formed small settlements in the caves and on its summit.",
        "wikipedia": "https://en.wikipedia.org/wiki/Le_Morne_Cultural_Landscape",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Lower Valley of the Awash",
        "location": "Afar Region, Ethiopia",
        "coordinates": [
            11.10006,
            40.57939
        ],
        "description": "Palaeontological findings from at least four million years ago, such as Lucy, give evidence of human evolution.",
        "wikipedia": "https://en.wikipedia.org/wiki/Awash_River",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Lower Valley of the Omo",
        "location": "Southern Nations, Nationalities, and People's Region, Ethiopia",
        "coordinates": [
            4.8,
            35.966667
        ],
        "description": "The prehistoric site near Lake Turkana is the location of many fossil findings, such as Homo gracilis.",
        "wikipedia": "https://en.wikipedia.org/wiki/Omo_River_(Ethiopia)",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "M'Zab Valley",
        "location": "Ghardaïa, Algeria",
        "coordinates": [
            32.48333,
            3.68333
        ],
        "description": "The intact, traditional human habitat was built around five ksour in the 10th century by the Ibadites.",
        "wikipedia": "https://en.wikipedia.org/wiki/M%27Zab",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Maloti-Drakensberg Park",
        "location": "Qacha's Nek District, Lesotho",
        "coordinates": [
            -29.765278,
            29.123056
        ],
        "description": "The park features incisive dramatic cutbacks, golden sandstone ramparts, and the largest concentration of cave art in Sub-Saharan Africa.",
        "wikipedia": "https://en.wikipedia.org/wiki/Maloti-Drakensberg_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Mana Pools National Park, Sapi and Chewore Safari Areas",
        "location": "Mashonaland West, Zimbabwe",
        "coordinates": [
            -15.819444,
            29.408056
        ],
        "description": "The park, located on the banks of the Zambezi River, features a variety of wild animals, such as buffalo, leopards, cheetahs, and Nile crocodiles.",
        "wikipedia": "https://en.wikipedia.org/wiki/Mana_Pools_National_Park,_Sapi_and_Chewore_Safari_Areas",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Manovo-Gounda St Floris National Park†",
        "location": "Bamingui-Bangoran, Central African Republic",
        "coordinates": [
            9.0,
            21.5
        ],
        "description": "The park features vast savannas with a wealth of flora and fauna, such as black rhinoceros, elephants, cheetahs, leopards, wild dogs, red-fronted gazelles, and buffalo. The site was placed on the List of World Heritage in Danger in 1997 due to grazing and poaching that is thought to have claimed 80% of the wildlife in the park. Also cited was the shooting of four park staff and \"a general state of deteriorating security\".",
        "wikipedia": "https://en.wikipedia.org/wiki/Manovo-Gounda_St_Floris_National_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Mapungubwe Cultural Landscape",
        "location": "Limpopo, South Africa",
        "coordinates": [
            -22.1925,
            29.23889
        ],
        "description": "The open savanna landscape lies at the confluence of the Limpopo and Shashe Rivers. It was the heart of the Mapungubwe Kingdom until the 14th century, when the area was abandoned, leaving untouched remains of palaces and settlements.",
        "wikipedia": "https://en.wikipedia.org/wiki/Mapungubwe_Cultural_Landscape",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Matobo Hills",
        "location": "Matabeleland South, Zimbabwe",
        "coordinates": [
            -20.5,
            28.5
        ],
        "description": "The large boulders have been used as natural shelters since the early Stone Age and feature a collection of rock paintings.",
        "wikipedia": "https://en.wikipedia.org/wiki/Matobo_Hills",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Mbanza Kongo, Vestiges of the Capital of the former Kingdom of Kongo",
        "location": "Zaire Province, Angola",
        "coordinates": [
            -6.267778,
            14.248056
        ],
        "description": "The town of Mbanza Kongo was the \"political and spiritual capital of the Kingdom of Kongo,\" displaying a mix of Kongolese and Portuguese-style architecture.",
        "wikipedia": "https://en.wikipedia.org/wiki/Mbanza-Kongo",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Medina of Essaouira (formerly Mogador)",
        "location": "Essaouira, Morocco",
        "coordinates": [
            31.51667,
            -9.76944
        ],
        "description": "The fortified seaport built during the late 18th century has a mix of North African and European architecture and was a major trading hub between the Sahara and Europe.",
        "wikipedia": "https://en.wikipedia.org/wiki/Essaouira",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Medina of Fez",
        "location": "Fez, Morocco",
        "coordinates": [
            34.06111,
            -4.97778
        ],
        "description": "The former capital was founded in the 9th century and features the world's oldest university. The urban fabric and main monuments date from the 13th and 14th centuries.",
        "wikipedia": "https://en.wikipedia.org/wiki/Fes_el_Bali",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Medina of Marrakesh",
        "location": "Marrakesh, Morocco",
        "coordinates": [
            31.63139,
            -7.98667
        ],
        "description": "The town was founded in the 1070s and remained a political, economic, and cultural centre for a long time. Monuments from that period include the Koutoubia Mosque, the kasbah, and the battlements. The city also holds newer features, including palaces.",
        "wikipedia": "https://en.wikipedia.org/wiki/Medina_of_Marrakesh",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Medina of Sousse",
        "location": "Sousse Governorate, Tunisia",
        "coordinates": [
            35.82778,
            10.63861
        ],
        "description": "A prime example of a town from the early Islamic period, the city was an important port during the 9th century.",
        "wikipedia": "https://en.wikipedia.org/wiki/Medina_of_Sousse",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Medina of Tétouan (formerly known as Titawin)",
        "location": "Tétouan, Morocco",
        "coordinates": [
            35.57083,
            -5.36667
        ],
        "description": "Morocco's most complete medina served as the main point of contact between Morocco and Andalusia during the 8th century. The town was rebuilt by Andalusian refugees following the reconquista.",
        "wikipedia": "https://en.wikipedia.org/wiki/T%C3%A9touan",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Medina of Tunis",
        "location": "Tunis, Tunisia",
        "coordinates": [
            36.81667,
            10.16667
        ],
        "description": "The medina holds 700 monuments, including palaces, mosques, mausoleums, madrasahs, and fountains, testifying to Tunis's golden age from the 12th to the 16th century.",
        "wikipedia": "https://en.wikipedia.org/wiki/Medina_of_Tunis",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Memphis and its Necropolis – the Pyramid Fields from Giza to Dahshur",
        "location": "Giza, Egypt",
        "coordinates": [
            29.97604,
            31.13041
        ],
        "description": "The former capital features funerary monuments, like rock tombs, mastabas, temples, and pyramids. It is one of the Seven Wonders of the Ancient World.",
        "wikipedia": "https://en.wikipedia.org/wiki/Memphis,_Egypt",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Mosi-oa-Tunya / Victoria Falls",
        "location": "Livingstone District, Zambia",
        "coordinates": [
            -17.92453,
            25.85539
        ],
        "description": "The falls of the Zambezi River, which is more than 2 km (1.2 mi) wide, plunge down various basalt gorges resulting in a colourful mist.",
        "wikipedia": "https://en.wikipedia.org/wiki/Victoria_Falls,_Zambia",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Mount Kenya National Park/Natural Forest",
        "location": "Central Province, Kenya",
        "coordinates": [
            0.155,
            37.315556
        ],
        "description": "The park surrounds the 5,199 m (17,057 ft) Mount Kenya and features twelve glaciers.",
        "wikipedia": "https://en.wikipedia.org/wiki/Mount_Kenya_National_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Mount Nimba Strict Nature Reserve†",
        "location": "Lola Prefecture, Côte d'Ivoire",
        "coordinates": [
            7.60318,
            -8.39097
        ],
        "description": "The reserve features Mount Nimba, its slopes covered in dense forest and grassy mountain pastures. In 1992, the park was inscribed on the World Heritage Committee's danger list, citing a proposed iron and ore mining concession inside the park's boundaries and the apparition of refugees. The state party later stated that there was an error with the proposed mining site's boundaries and that it was not in the reserve. However, as of 2019, the site remains on the list due to mining activity near the site.",
        "wikipedia": "https://en.wikipedia.org/wiki/Mount_Nimba_Strict_Nature_Reserve",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Namib Sand Sea",
        "location": "Namibia, Namibia",
        "coordinates": [
            -24.885278,
            15.407778
        ],
        "description": "Characterized by dynamic and extensive dune fields, which are among the oldest and largest in the world. The Namib Sand Sea is notable for its unique ecosystem, which has adapted to extreme arid conditions, including a variety of endemic species that rely on fog as a primary source of moisture.",
        "wikipedia": "https://en.wikipedia.org/wiki/Namib_Sand_Sea",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Ngorongoro Conservation Area",
        "location": "Arusha Region, Tanzania, United Republic of",
        "coordinates": [
            -3.18722,
            35.54083
        ],
        "description": "The site features a concentration of wild animals in a crater beside the active volcano Oldonyo Lengai. Ngorongoro was previously on the danger list from 1984 to 1989 due to decreased conservation and poor management.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ngorongoro_Conservation_Area",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Niokolo-Koba National Park†",
        "location": "Kédougou Region, Senegal",
        "coordinates": [
            13.06667,
            -12.71667
        ],
        "description": "The forests and savannas bordering the Gambia River have a diverse fauna, including Derby eland, chimpanzees, lions, birds, reptiles, and amphibians. The park was listed as being endangered in 2007 for low mammal populations, the construction of a dam, and management problems.",
        "wikipedia": "https://en.wikipedia.org/wiki/Niokolo-Koba_National_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Nubian Monuments from Abu Simbel to Philae",
        "location": "Aswan, Egypt",
        "coordinates": [
            22.33639,
            31.62611
        ],
        "description": "Located along the Nile, the site contains monuments such as the Temple of Ramesses II and the Sanctuary of Isis.",
        "wikipedia": "https://en.wikipedia.org/wiki/Nubian_Monuments_from_Abu_Simbel_to_Philae",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Okapi Wildlife Reserve†",
        "location": "Orientale, Democratic Republic of the Congo",
        "coordinates": [
            2.0,
            28.5
        ],
        "description": "Covering a fifth of the Ituri Rainforest in the Congo River basin, the reserve contains many threatened species of primates and birds. It is inhabited by the nomadic pygmy Mbuti and Efé tribes. In 1997, looting, the killing of elephants, and the departure of reserve staff led the World Heritage Committee to place the reserve on its List of World Heritage in Danger, only a year following its inscription as a natural heritage site.",
        "wikipedia": "https://en.wikipedia.org/wiki/Okapi_Wildlife_Reserve",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Okavango Delta",
        "location": "Okavango, Botswana",
        "coordinates": [
            -19.283333,
            22.9
        ],
        "description": "One of the world's largest inland deltas, formed where the Okavango River empties onto a basin in the Kalahari Desert. This nearly intact ecosystem is characterized by seasonal flooding, which transforms the landscape into a mosaic of water channels, lagoons, and islands that house an exceptional variety of wildlife.",
        "wikipedia": "https://en.wikipedia.org/wiki/Okavango_Delta",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Old Towns of Djenné†",
        "location": "Djenné, Mali",
        "coordinates": [
            13.90639,
            -4.555
        ],
        "description": "Inhabited since 250 BCE, the city was an important link in the trans-Saharan gold trade. It contains 2,000 traditional houses. Djenné was placed on UNESCO's List of World Heritage in Danger in 2016, due to deterioration, urbanization, and erosion of the site.",
        "wikipedia": "https://en.wikipedia.org/wiki/Old_Towns_of_Djenn%C3%A9",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Old Town of Ghadamès†",
        "location": "Ghadames, Libya",
        "coordinates": [
            30.133333,
            9.5
        ],
        "description": "Located in an oasis, Ghadames is one of the oldest pre-Saharan cities and represents a traditional architecture with vertical division of functions. All five sites in Libya, including Ghadames, were placed on UNESCO's List of World Heritage in Danger in 2016, due to ongoing conflict in the country.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ghadames",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Osun-Osogbo Sacred Grove",
        "location": "Osogbo, Nigeria",
        "coordinates": [
            7.75556,
            4.55222
        ],
        "description": "The dense forests are one of the final remnants of high forests in southern Nigeria. It is the last sacred grove of the Yoruba culture.",
        "wikipedia": "https://en.wikipedia.org/wiki/Osun-Osogbo_Sacred_Grove",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Pitons, cirques and remparts of Reunion Island",
        "location": "La Réunion, France",
        "coordinates": [
            -21.099444,
            55.48
        ],
        "description": "Outstanding terrain and biodiversity, as part of La Réunion National Park.",
        "wikipedia": "https://en.wikipedia.org/wiki/Pitons,_cirques_and_remparts_of_Reunion_Island",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Portuguese City of Mazagan (El Jadida)",
        "location": "El Jadida, Morocco",
        "coordinates": [
            33.25667,
            -8.50194
        ],
        "description": "The fortification, akin to Renaissance military design from the early 16th century, was taken over by Morocco in 1769. Surviving buildings include the cistern and a Gothic church.",
        "wikipedia": "https://en.wikipedia.org/wiki/El_Jadida",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Punic Town of Kerkuane and its Necropolis",
        "location": "Nabeul Governorate, Tunisia",
        "coordinates": [
            36.94639,
            11.09917
        ],
        "description": "The city was abandoned in 250 BCE during the First Punic War and is the only surviving example of a Phoenicio–Punic settlement.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kerkouane",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Rabat, Modern Capital and Historic City: a Shared Heritage",
        "location": "Rabat-Salé-Zemmour-Zaer, Morocco",
        "coordinates": [
            34.024167,
            -6.822778
        ],
        "description": "Rebuilt under the direction of the French from 1912 to the 1930s, the city blends historic and modern features, such as botanical gardens, the Hassan Mosque, and the remnants of Moorish and Andalusian settlements from the 17th century.",
        "wikipedia": "https://en.wikipedia.org/wiki/Rabat",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Rainforests of the Atsinanana†",
        "location": "Madagascar, Madagascar",
        "coordinates": [
            -14.459722,
            49.7025
        ],
        "description": "The site consists of six national parks and protects the island's unique biodiversity, which has evolved in isolation for 60 million years. The park was deemed to be in danger in 2010, when logging and hunting activities continued to escalate, despite a ban by Madagascar on exporting illegal timber.",
        "wikipedia": "https://en.wikipedia.org/wiki/Rainforests_of_the_Atsinanana",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Richtersveld Cultural and Botanical Landscape",
        "location": "Northern Cape, South Africa",
        "coordinates": [
            -28.6,
            17.203889
        ],
        "description": "The mountainous desert sustains the semi-nomadic livelihood of the Nama, which includes seasonal migrations that have gone unchanged for two millennia.",
        "wikipedia": "https://en.wikipedia.org/wiki/Richtersveld_Cultural_and_Botanical_Landscape",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Risco Caido and the Sacred Mountains of Gran Canaria Cultural Landscape",
        "location": "Risco Caído, Spain",
        "coordinates": [
            28.0443889,
            -15.6611944
        ],
        "description": "",
        "wikipedia": "https://en.wikipedia.org/wiki/Risco_Ca%C3%ADdo",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Robben Island",
        "location": "Western Cape, South Africa",
        "coordinates": [
            -33.8,
            18.366667
        ],
        "description": "Between the 17th and 20th century, the island was used as a prison, including for political prisoners, a hospital for socially unacceptable groups, and a military base.",
        "wikipedia": "https://en.wikipedia.org/wiki/Robben_Island",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Rock-Art Sites of Tadrart Acacus†",
        "location": "Fezzan, Libya",
        "coordinates": [
            24.83333,
            10.33333
        ],
        "description": "Thousands of cave paintings are visible in different styles, dating from 12,000 BCE to 100 CE. All five sites in Libya, including Tadrart Acacus, were placed on UNESCO's List of World Heritage in Danger in 2016, due to ongoing conflict in the country.",
        "wikipedia": "https://en.wikipedia.org/wiki/Rock-Art_Sites_of_Tadrart_Acacus",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Rock-Hewn Churches, Lalibela",
        "location": "Amhara Region, Ethiopia",
        "coordinates": [
            12.02935,
            39.04042
        ],
        "description": "The site contains eleven medieval cave churches from the 13th century.",
        "wikipedia": "https://en.wikipedia.org/wiki/Rock-Hewn_Churches,_Lalibela",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Royal Hill of Ambohimanga",
        "location": "Antananarivo, Madagascar",
        "coordinates": [
            -18.75917,
            47.56278
        ],
        "description": "The royal city and burial site is a spiritual and sacred site which has created strong feelings of national identity for several centuries.",
        "wikipedia": "https://en.wikipedia.org/wiki/Royal_Hill_of_Ambohimanga",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Royal Palaces of Abomey",
        "location": "Zou Department, Benin",
        "coordinates": [
            7.183333,
            1.983333
        ],
        "description": "The city held the seat of twelve kings who ruled the Kingdom of Dahomey between 1625 and 1900. All but one king built their palace within the area. The site was originally classified as endangered from its inception in 1985 due to extensive damage caused by a tornado, but was later removed from the list in 2007.",
        "wikipedia": "https://en.wikipedia.org/wiki/Royal_Palaces_of_Abomey",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Ruins of Kilwa Kisiwani and Ruins of Songo Mnara",
        "location": "Kilwa District, Tanzania, United Republic of",
        "coordinates": [
            -8.95778,
            39.52278
        ],
        "description": "The site features the remains of two ports used extensively for trade across the Indian Ocean from the 13th through 16th centuries. It was placed onto the List of World Heritage in Danger by the World Heritage Committee in 2004, citing \"the continuing deterioration and the serious threats affecting the property of the Ruins of Kilwa Kisiwani and Songo Mnara\". Conditions subsequently improved and the site was removed from the World Heritage in Danger list in 2014.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kilwa_Kisiwani",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Ruins of Loropéni",
        "location": "Loropéni, Burkina Faso",
        "coordinates": [
            10.25,
            -3.583333
        ],
        "description": "More than a thousand years old, Loropéni is the best-preserved of ten fortresses in Lobi, which were part of about a hundred stone enclosures built during the trans-Saharan gold trade.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ruins_of_Lorop%C3%A9ni",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Rwenzori Mountains National Park",
        "location": "Bundibugyo District, Uganda",
        "coordinates": [
            0.223611,
            29.924167
        ],
        "description": "Covering most of the Rwenzori Mountains, including Mount Margherita, Africa's third-highest peak, the park features glaciers, waterfalls, and lakes in an alpine landscape. It also features various endangered species and unusual flora. The park was previously listed as endangered from 1999 through 2004 due to general lack of security and conservation.",
        "wikipedia": "https://en.wikipedia.org/wiki/Rwenzori_Mountains_National_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Sacred Mijikenda Kaya Forests",
        "location": "Coast Province, Kenya",
        "coordinates": [
            -3.931944,
            39.596111
        ],
        "description": "The site comprises eleven forests spread 200 km (120 mi) along the coast of Kenya. They hold the remains of villages built during the 16th century by the Mijikenda and are now considered sacred sites.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kaya_(Mijikenda)",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Saint Catherine Area",
        "location": "South Sinai Governorate, Egypt",
        "coordinates": [
            28.55623,
            33.97543
        ],
        "description": "The orthodox monastery from the 6th century is positioned near Mount Horeb where, according to the Old Testament, Moses received the Tablets of the Law. The region is sacred for Christians, Muslims, and Jews.",
        "wikipedia": "https://en.wikipedia.org/wiki/Saint_Catherine%27s_Monastery",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Salonga National Park",
        "location": "Maniema, Democratic Republic of the Congo",
        "coordinates": [
            -2.0,
            21.0
        ],
        "description": "Africa's largest tropical rain forest reserve is situated at the heart of the Congo River basin and only accessible by water. It is the habitat of endangered species such as the bonobo, the Congo peafowl, the forest elephant, and the slender-snouted crocodile. The site was deemed to be in danger in 1999, due to increased poaching activities and encroachments. However, this categorization was eventually removed in 2021 due to increased conservation efforts.",
        "wikipedia": "https://en.wikipedia.org/wiki/Salonga_National_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Saloum Delta",
        "location": "Senegal, Senegal",
        "coordinates": [
            13.835278,
            -16.498611
        ],
        "description": "The area has sustained human life thanks to fishing and shellfish gathering, for which there are 218 shellfish mounds across the site.",
        "wikipedia": "https://en.wikipedia.org/wiki/Saloum_Delta_National_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Sanganeb Marine National Park and Dungonab Bay – Mukkawar Island Marine National Park",
        "location": "Sudan, Sudan",
        "coordinates": [
            19.73611,
            37.44306
        ],
        "description": "Situated in the central Red Sea, Sanganeb, Dungonab Bay, and Mukkawar Island feature a diverse system of coral reefs, mangroves, seagrass beds, beaches, and islets. They host populations of seabirds, marine mammals, fish, sharks, turtles, manta rays, and dugongs.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dungonab_Bay",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "San Cristóbal de la Laguna",
        "location": "Province of Santa Cruz de Tenerife, Spain",
        "coordinates": [
            28.477889,
            -16.311778
        ],
        "description": "The city contains two centres: the unplanned Upper Town; and the planned Lower Town, laid out according to philosophical principles. Many of the buildings date from the 16th to the 18th centuries.",
        "wikipedia": "https://en.wikipedia.org/wiki/San_Crist%C3%B3bal_de_la_Laguna",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Sangha Trinational",
        "location": "Cameroon, Cameroon",
        "coordinates": [
            2.609444,
            16.554167
        ],
        "description": "This area encompasses national parks and reserves including Nouabalé-Ndoki National Park in Congo, Lobéké National Park in Cameroon, and Dzanga-Ndoki National Park in the Central African Republic. The Sangha Trinational is celebrated for its exceptional biodiversity, the area also supports the traditional and sustainable livelihoods of indigenous peoples, including the Ba'Aka pygmies, who have lived in harmony with this forest for thousands of years.",
        "wikipedia": "https://en.wikipedia.org/wiki/Sangha_Trinational",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Selous Game Reserve†",
        "location": "Lindi Region, Tanzania, United Republic of",
        "coordinates": [
            -9.0,
            37.4
        ],
        "description": "The park's vegetation varies from dense thickets to open wooded grasslands and features large numbers of elephants, black rhinoceros, cheetahs, giraffes, hippos, and crocodiles. It was placed onto the List of World Heritage in Danger by the World Heritage Committee in 2014 due to widespread poaching, especially of elephants and rhinoceros.",
        "wikipedia": "https://en.wikipedia.org/wiki/Selous_Game_Reserve",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Serengeti National Park",
        "location": "Arusha Region, Tanzania, United Republic of",
        "coordinates": [
            -2.33333,
            34.56667
        ],
        "description": "The vast savanna is known for the annual migration for herds of wildebeest, gazelle, zebras, and their predators.",
        "wikipedia": "https://en.wikipedia.org/wiki/Serengeti_National_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Simien National Park",
        "location": "Amhara Region, Ethiopia",
        "coordinates": [
            13.183333,
            38.066667
        ],
        "description": "The eroded Ethiopian plateau comprises jagged mountain peaks, deep valleys, and sharp precipices dropping about 1,500 m (4,900 ft). The decrease of the walia ibex, bushbuck, and bushpig populations, as well as an increase of the human population in the park, prompted the World Heritage Committee to place it on their List of World Heritage in Danger in 1996. However, the park was removed from the danger list in 2017 after improvements in management and reduction of overgrazing.",
        "wikipedia": "https://en.wikipedia.org/wiki/Simien_National_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Stone Circles of Senegambia",
        "location": "Central River Division, Gambia",
        "coordinates": [
            13.691111,
            -15.5225
        ],
        "description": "The groups of stone circles are among over 1,000 different monuments along the Gambia River. Used as burial grounds, they were erected between the 3rd century BCE and the 16th century CE.",
        "wikipedia": "https://en.wikipedia.org/wiki/Senegambian_stone_circles",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Stone Town of Zanzibar",
        "location": "Zanzibar, Tanzania, United Republic of",
        "coordinates": [
            -6.16306,
            39.18917
        ],
        "description": "A prime example of an East African coastal trading town, its urban fabric and townscape remain intact.",
        "wikipedia": "https://en.wikipedia.org/wiki/Stone_Town_of_Zanzibar",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Sudanese style mosques in northern Côte d’Ivoire",
        "location": "Côte d'Ivoire, Côte d'Ivoire",
        "coordinates": [
            10.490317,
            -6.410167
        ],
        "description": "",
        "wikipedia": "https://en.wikipedia.org/wiki/List_of_mosques_in_Ivory_Coast",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Sukur Cultural Landscape",
        "location": "Madagali, Nigeria",
        "coordinates": [
            10.74056,
            13.57194
        ],
        "description": "The site features the Palace of the Hidi, terraced fields, and the remains of a former iron industry.",
        "wikipedia": "https://en.wikipedia.org/wiki/Sukur_Cultural_Landscape",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Taï National Park",
        "location": "Guiglo, Côte d'Ivoire",
        "coordinates": [
            5.75,
            -7.116667
        ],
        "description": "One of few remaining sections of the West African tropical forest, the park features rich flora as well as eleven species of monkeys.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ta%C3%AF_National_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Tassili n'Ajjer",
        "location": "Illizi, Algeria",
        "coordinates": [
            25.5,
            9.0
        ],
        "description": "The site is in a landscape with 15,000 cave engravings that record climatic changes, animal migrations, and the evolution of human life, dating from 6,000 BCE to the first centuries CE.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tassili_n%27Ajjer",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Teide National Park",
        "location": "Province of Santa Cruz de Tenerife, Spain",
        "coordinates": [
            28.271389,
            -16.643611
        ],
        "description": "The national park features the Teide stratovolcano, which at 3,718 m (12,198 ft) is Spain's tallest mountain and the world's third-tallest volcano.",
        "wikipedia": "https://en.wikipedia.org/wiki/Teide_National_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Thimlich Ohinga Archaeological Site",
        "location": "Migori County, Kenya",
        "coordinates": [
            -0.891101,
            34.325173
        ],
        "description": "Dating back to the 16th century CE, the dry-stone walled settlement is the largest and best-preserved traditional enclosure of its kind.",
        "wikipedia": "https://en.wikipedia.org/wiki/Thimlich_Ohinga",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Timbuktu†",
        "location": "Timbuktu Cercle, Mali",
        "coordinates": [
            16.773333,
            -2.999444
        ],
        "description": "The city was a centre for the propagation of Islam in the 15th and 16th centuries and features three mosques and many madrasahs. The site was deemed endangered in 1990 due to encroaching sand, but was removed from the list in 2005 after successful conservation efforts. The site was once again deemed endangered after the Battle of Gao in June 2012, which followed several weeks of constant threats to the area. Days later, some sites within Timbuktu were destroyed by Ansar Dine, an Islamist group, citing religious reasons.",
        "wikipedia": "https://en.wikipedia.org/wiki/Timbuktu",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Timgad",
        "location": "Batna Province, Algeria",
        "coordinates": [
            35.484167,
            6.468611
        ],
        "description": "A military colony built by Emperor Trajan in 100 CE, the site features cardo and decumanus streets, typical of a Roman town.",
        "wikipedia": "https://en.wikipedia.org/wiki/Timgad",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Tipasa",
        "location": "Tipaza, Algeria",
        "coordinates": [
            36.591944,
            2.449444
        ],
        "description": "First a Carthaginian trading centre, Tipasa was converted into a military base by the Romans. Heavy Christian influences can be seen from the 3rd and 4th centuries, though Tipasa went into steady decline in the Byzantine period. Tipasa was classified as endangered in 2002 due to natural and human-caused deterioration of the archaeological remains, but was removed from the list in 2006 due to improved protection of the site.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tipaza",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Tiya",
        "location": "Southern Nations, Nationalities, and People's Region, Ethiopia",
        "coordinates": [
            8.43491,
            38.6121
        ],
        "description": "The archaeological site contains 36 monuments, which includes 32 carved stelae covered with symbols hard to decrypt.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tiya_(archaeological_site)",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Tomb of Askia†",
        "location": "Gao Region, Mali",
        "coordinates": [
            16.2898,
            0.04456
        ],
        "description": "Built in 1495, the pyramid was built as a tomb for Emperor Askia Mohamed. It represents the power of an empire that controlled the trans-Saharan gold trade. The site was deemed endangered after the Battle of Gao in June 2012, which followed several weeks of constant threats to the area.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tomb_of_Askia",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Tombs of Buganda Kings at Kasubi†",
        "location": "Kampala District, Uganda",
        "coordinates": [
            0.329167,
            32.553333
        ],
        "description": "The tombs, built after 1884, are a major example of prime architecture using organic materials, principally wood, thatch, reed, and wattle and daub. The tombs were almost completely destroyed by a fire in March 2010, prompting the World Heritage Committee to reluctantly mark the site as being in danger. The Ugandan government has since called for the reconstruction of the tombs, and UNESCO has agreed to mobilise funds for the project.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tombs_of_Buganda_Kings_at_Kasubi",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Tsingy de Bemaraha Strict Nature Reserve",
        "location": "Melaky, Madagascar",
        "coordinates": [
            -18.66667,
            44.75
        ],
        "description": "The canyon of the Manambolo River comprises karstic and limestone landscapes cut into peaks and a forest of limestone needles. It also holds undisturbed forests, lakes, and mangrove swamps, which are the habitat for lemurs and birds.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tsingy_de_Bemaraha_Strict_Nature_Reserve",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Tsodilo",
        "location": "North-West, Botswana",
        "coordinates": [
            -18.75,
            21.733333
        ],
        "description": "The site features more than 4,500 rock art paintings in the Kalahari Desert created over a span of 100,000 years.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tsodilo",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Twyfelfontein or /Ui-//aes",
        "location": "Kunene, Namibia",
        "coordinates": [
            -20.595583,
            14.372583
        ],
        "description": "The site has one of the largest concentrations of rock engravings in Africa, which range from a period of over 2,000 years.",
        "wikipedia": "https://en.wikipedia.org/wiki/Twyfelfontein",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Vallée de Mai Nature Reserve",
        "location": "Praslin, Seychelles",
        "coordinates": [
            -4.32917,
            55.7375
        ],
        "description": "The natural palm forest is preserved in almost its original state.",
        "wikipedia": "https://en.wikipedia.org/wiki/Vall%C3%A9e_de_Mai_Nature_Reserve",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Virunga National Park†",
        "location": "North Kivu, Democratic Republic of the Congo",
        "coordinates": [
            0.916667,
            29.166667
        ],
        "description": "The park comprises swamps, savannas, and snowfields. Virunga was inscribed on the World Heritage Committee's List of World Heritage in Danger in 1994, due to the genocide in Rwanda and the subsequent increase of the refugee population in the park, deforestation, poaching, departure of park staff, and depletion of forests.",
        "wikipedia": "https://en.wikipedia.org/wiki/Virunga_National_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Vredefort Dome",
        "location": "Free State, South Africa",
        "coordinates": [
            -26.86,
            27.26
        ],
        "description": "The crater, with a diameter of 190 km (120 mi), is the largest, oldest, and most deeply eroded astrobleme found on Earth, dating back more than two billion years.",
        "wikipedia": "https://en.wikipedia.org/wiki/Vredefort_Dome",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "W-Arly-Pendjari Complex",
        "location": "Benin, Benin",
        "coordinates": [
            11.88417,
            2.48778
        ],
        "description": "The parks are in the zone between savanna and forest lands and are an important ecosystem for the biogeographical area.",
        "wikipedia": "https://en.wikipedia.org/wiki/W_National_Park_of_Niger",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Wadi Al-Hitan (Whale Valley)",
        "location": "Faiyum Governorate, Egypt",
        "coordinates": [
            29.33333,
            30.18333
        ],
        "description": "Located in western Egypt, the site contains fossil remains of the now extinct Archaeoceti, mapping the evolution of the whales from a land-based to an aquatic mammal.",
        "wikipedia": "https://en.wikipedia.org/wiki/Wadi_Al-Hitan",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Qal'at Bani Hammad",
        "location": "M'sila Province, Algeria",
        "coordinates": [
            35.81389,
            4.79333
        ],
        "description": "In a mountainous site of extraordinary beauty, the ruins of the first capital of the Hammadid emirs, founded in 1007 and demolished in 1152, provide an authentic picture of a fortified Muslim city. The mosque, whose prayer room has 13 aisles with eight bays, is one of the largest in Algeria. Beni Hammad Fort is near the town of Maadid (aka Maadhid), about 225 kilometres (140 mi) southeast of Algiers.",
        "wikipedia": "https://en.wikipedia.org/wiki/Beni_Hammad_Fort",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Djémila",
        "location": "Sétif Province, Algeria",
        "coordinates": [
            36.32056,
            5.73667
        ],
        "description": "Djémila (formerly known as Cuicul) was a Roman town in a mountainous site, comprising a forum, temples, basilicas, triumphal arches and religious buildings and other structures, each adapted to a location 900 m (3,000 ft) above sea level.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dj%C3%A9mila",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Casbah of Algiers",
        "location": "Algiers Province, Algeria",
        "coordinates": [
            36.78333,
            3.06028
        ],
        "description": "The Casbah of Algiers is a unique Islamic city on the Mediterranean coast. It contains remains of a citadel, medieval mosques, and Ottoman palaces.",
        "wikipedia": "https://en.wikipedia.org/wiki/Casbah_of_Algiers",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "M'zab",
        "location": "Ghardaïa Province, Algeria",
        "coordinates": [
            32.48333,
            3.68333
        ],
        "description": "The intact, traditional human habitat was built around five ksour of the M'zab Valley in the 10th century by the Ibadites.",
        "wikipedia": "https://en.wikipedia.org/wiki/M%27zab",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Tassili n'Ajjer",
        "location": "Illizi, Algeria",
        "coordinates": [
            25.5,
            9.0
        ],
        "description": "A vast plateau on the edge of the Sahara, Tassili n'Ajjer contains more than 15,000 cave engravings that record climatic changes, animal migrations, and the evolution of human life, dating from 6,000 BCE to the first centuries CE. It is also noted for its eroded sandstone landforms.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tassili_n%27Ajjer",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Timgad",
        "location": "Batna Province, Algeria",
        "coordinates": [
            35.45,
            6.63333
        ],
        "description": "Established by Emperor Trajan in 100 CE as a military colony, Timgad features cardo and decumanus streets, constituting a typical example of Roman town-planning.",
        "wikipedia": "https://en.wikipedia.org/wiki/Timgad",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Tipaza",
        "location": "Tipaza Province, Algeria",
        "coordinates": [
            36.53333,
            2.36667
        ],
        "description": "Previously a Carthaginian trading centre, Tipaza was conquered by the Romans and converted into a military base. The site also bears witness to Paleo-Christian and Byzantine influences.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tipaza",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Dilmun Burial Mounds",
        "location": "Northern Governorate, Bahrain",
        "coordinates": [
            26.14972,
            50.51278
        ],
        "description": "Located in the western part of Bahrain Island, Dilmun Burial Mounds date back to the Dilmun, the Umm al-Nar culture. They were built between 2050 and 1750 BCE include 21 archaeological sites with more than 11 K burial mounds and 17 royal mounds built as 2-storeyed funeral towers.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dilmun_Burial_Mounds",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Qal'at al-Bahrain",
        "location": "Northern Governorate, Bahrain",
        "coordinates": [
            26.23306,
            50.52722
        ],
        "description": "Qal'at al-Bahrain was the capital of the Dilmun civilization and a place of consistent human settlement and occupation from c. 2300 BC to the present. A Portuguese fort stands atop the site.",
        "wikipedia": "https://en.wikipedia.org/wiki/Qal%27at_al-Bahrain",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Bahrain Pearling Trail",
        "location": "Bahrain, Bahrain",
        "coordinates": [
            26.24111,
            50.61361
        ],
        "description": "Consisting of buildings in Muharraq, oyster beds, a segment of the coast, and a fortress, the site testifies to the pearling tradition that dominated the Persian Gulf from the 2nd century to the early 20th century, when the introduction of cultured pearls from Japan resulted in the crash of pearling economy in Bahrain.",
        "wikipedia": "https://en.wikipedia.org/wiki/Bahrain_Pearling_Trail",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Abu Mena",
        "location": "Alexandria Governorate, Egypt",
        "coordinates": [
            30.85,
            29.66667
        ],
        "description": "The ruins of the former Christian holy city contain a church, a baptistery, basilicas, public buildings, streets, monasteries, houses, and workshops, and were built over the tomb of Menas of Alexandria.",
        "wikipedia": "https://en.wikipedia.org/wiki/Abu_Mena",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Thebes",
        "location": "Luxor Governorate, Egypt",
        "coordinates": [
            25.73333,
            32.6
        ],
        "description": "The former capital of Egypt and the city of Amun, Thebes contains temples and palaces at Karnak and Luxor, as well as the necropolises at the Valley of the Kings and the Valley of the Queens, bearing witness to the height of the Egyptian civilization.",
        "wikipedia": "https://en.wikipedia.org/wiki/Thebes,_Egypt",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Islamic Cairo",
        "location": "Cairo Governorate, Egypt",
        "coordinates": [
            30.05,
            31.26111
        ],
        "description": "One of the world's oldest Islamic cities and in the middle of urban Cairo, the site dates from the 10th century and peaked during the 14th century. Islamic Cairo contains mosques, madrasas, hammams, and fountains.",
        "wikipedia": "https://en.wikipedia.org/wiki/Islamic_Cairo",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Giza pyramid complex",
        "location": "Giza Governorate, Egypt",
        "coordinates": [
            30.05,
            31.26111
        ],
        "description": "Having served as the capital of the Old Kingdom of Egypt, Giza is host to a variety of funerary monuments, including tombs, ornate mastabas, temples, and pyramids.",
        "wikipedia": "https://en.wikipedia.org/wiki/Giza_pyramid_complex",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "International Campaign to Save the Monuments of Nubia",
        "location": "Aswan Governorate, Egypt",
        "coordinates": [
            22.33639,
            31.62611
        ],
        "description": "Located along the Nile, the site contains monuments such as the Temple of Ramesses II at Abu Simbel and the Sanctuary of Isis at Philae, saved from being submerged by Lake Nasser as a result of the construction of the Aswan Dam.",
        "wikipedia": "https://en.wikipedia.org/wiki/Nubian_Monuments_from_Abu_Simbel_to_Philae",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Saint Catherine's Monastery",
        "location": "South Sinai Governorate, Egypt",
        "coordinates": [
            28.55611,
            33.97556
        ],
        "description": "The orthodox monastery of Saint Catherine is among the oldest Christian monasteries still in function. Dating from the 6th century, it is positioned near Mount Horeb where, according to the Old Testament, Moses received the Tablets of the Law. The region is sacred for Christians, Muslims and Jews.",
        "wikipedia": "https://en.wikipedia.org/wiki/Saint_Catherine%27s_Monastery",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Wadi al Hitan",
        "location": "Faiyum Governorate, Egypt",
        "coordinates": [
            29.33333,
            30.18333
        ],
        "description": "Located in western Egypt, Wadi Al-Hitan contains fossil remains of the now extinct Archaeoceti, mapping the evolution of the whales from a land-based to an aquatic mammal.",
        "wikipedia": "https://en.wikipedia.org/wiki/Wadi_Al-Hitan",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Ahwar of Southern Iraq",
        "location": "Dhi Qar Governorate, Iraq",
        "coordinates": [
            31.56222,
            47.65778
        ],
        "description": "Located in southern Iraq, the site contains three cities of Sumerian origin, namely Uruk, Ur and Eridu, in addition to four wetland areas in the Mesopotamian Marshlands.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ahwar_of_Southern_Iraq",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Babylon",
        "location": "Babylon Governorate, Iraq",
        "coordinates": [
            32.5419694,
            44.42083
        ],
        "description": "Situated 85 km south of Baghdad, the property includes the ruins of the city which, between 626 and 539 BCE, was the capital of the Neo-Babylonian Empire. It includes villages and agricultural areas surrounding the ancient city.",
        "wikipedia": "https://en.wikipedia.org/wiki/Babylon",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Citadel of Erbil",
        "location": "Erbil Governorate, Iraq",
        "coordinates": [
            36.19111,
            44.00917
        ],
        "description": "Situated on the top of a tell and overlooking the city of Erbil, the Erbil Citadel\ncorresponds to ancient Arbela, an important Assyrian political and religious centre dating back to the Assyrian period.",
        "wikipedia": "https://en.wikipedia.org/wiki/Citadel_of_Erbil",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Hatra",
        "location": "Nineveh Governorate, Iraq",
        "coordinates": [
            35.58806,
            42.71833
        ],
        "description": "The fortified Parthian city of Hatra withstood repeated attacks by the Roman Empire in the 2nd century. Its architecture reflects both Hellenistic and Roman influences.",
        "wikipedia": "https://en.wikipedia.org/wiki/Hatra",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Assur",
        "location": "Saladin Governorate, Iraq",
        "coordinates": [
            35.45889,
            43.25972
        ],
        "description": "Located on the Tigris and dating from the 3rd millennium BCE, Ashur was the first capital of the Assyrian Empire and the religious centre of the Assyrians. Following its destruction by the Babylonians, the city was briefly revived during the Parthian period.",
        "wikipedia": "https://en.wikipedia.org/wiki/Assur",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Samarra",
        "location": "Saladin Governorate, Iraq",
        "coordinates": [
            34.34111,
            43.82361
        ],
        "description": "Located on the Tigris, the Islamic city of Samarra was the capital of the Abbasid Caliphate. It contains two of the largest mosques and several of the largest palaces in the Islamic world, in addition to being among the finest example of Abbasid-era town-planning.",
        "wikipedia": "https://en.wikipedia.org/wiki/Samarra",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "As-Salt",
        "location": "Balqa Governorate, Jordan",
        "coordinates": [
            32.03917,
            35.72583
        ],
        "description": "Established on three closely-spaced hills in the Balqa (region), As-Salt was the main settlement on the East Bank of the Jordan River that served as a cultural, commercial and financial hub. This prosperity allowed for the influx of people from the region who settled in As-Salt, creating a distinct city built with yellow limestone that had European Art Nouveau and Neo-Colonial styles combined with local traditions.",
        "wikipedia": "https://en.wikipedia.org/wiki/Al-Salt",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Al-Maghtas",
        "location": "Jordan, Jordan",
        "coordinates": [
            31.83722,
            35.55278
        ],
        "description": "Situated on the Jordan River, Al-Maghtas is considered the location of the Baptism of Jesus by John the Baptist. A Christian pilgrimage site, it contains remains of Roman and Byzantine churches, chapels, a monastery, caves and pools.",
        "wikipedia": "https://en.wikipedia.org/wiki/Al-Maghtas",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Petra",
        "location": "Ma'an Governorate, Jordan",
        "coordinates": [
            30.33056,
            35.44333
        ],
        "description": "The Nabataean city of Petra was a major trading hub between Arabia, Egypt and Syria-Phoenicia, famed for its rock-cut architecture as well as its mining and water engineering systems.",
        "wikipedia": "https://en.wikipedia.org/wiki/Petra",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Qusayr 'Amra",
        "location": "Zarqa Governorate, Jordan",
        "coordinates": [
            31.80194,
            36.58583
        ],
        "description": "The desert castle of Quseir Amra was built in the early 8th century, and had served both as a fortress and an Umayyad royal palace. The site also noted for its extensive frescoes, constituting an important and unique example of early Islamic art.",
        "wikipedia": "https://en.wikipedia.org/wiki/Qasr_Amra",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Um ar-Rasas",
        "location": "Madaba Governorate, Jordan",
        "coordinates": [
            31.50167,
            35.92056
        ],
        "description": "Established as a Roman military camp, Um er-Rasas grew into a settlement by the 5th century, inhabited successively by Christian and Islamic communities. The largely unexcavated site contains ruins of Roman fortifications, churches with well-preserved mosaic floors and two stylite towers.",
        "wikipedia": "https://en.wikipedia.org/wiki/Umm_ar-Rasas",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Wadi Rum",
        "location": "Jordan, Jordan",
        "coordinates": [
            29.63972,
            35.43389
        ],
        "description": "Situated in southern Jordan, Wadi Rum features a great variety of desert landforms including sandstone valleys, natural arches, gorges, cliffs, landslides and caverns. The site also contains extensive rock art, inscriptions and archaeological remains, bearing witness to more than 12,000 years of continuous human habitation.",
        "wikipedia": "https://en.wikipedia.org/wiki/Wadi_Rum",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Anjar",
        "location": "Beqaa Governorate, Lebanon",
        "coordinates": [
            33.72583,
            35.92972
        ],
        "description": "Established in the early 8th century, the city of Anjar was quickly abandoned following the fall of the Umayyad Caliphate, leaving behind ruins of walls, towers, palaces, mosques and baths, constituting an example of Umayyad period town-planning.",
        "wikipedia": "https://en.wikipedia.org/wiki/Anjar,_Lebanon",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Baalbek",
        "location": "Baalbek-Hermel Governorate, Lebanon",
        "coordinates": [
            34.00694,
            36.205
        ],
        "description": "Previously known as Heliopolis, the Phoenician city of Baalbek hosts some of the largest and best preserved Roman temples, including the Temples of Jupiter, Venus and Bacchus.",
        "wikipedia": "https://en.wikipedia.org/wiki/Baalbek",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Byblos",
        "location": "Mount Lebanon Governorate, Lebanon",
        "coordinates": [
            34.11917,
            35.6475
        ],
        "description": "Continuously inhabited since the Neolithic Era, Byblos was one of the oldest cities of Phoenician origin. It has since witnessed Persian, Roman, Crusader and Ottoman occupation, each exerting influence upon its architecture. Byblos also played a significant role in the diffusion of the Phoenician alphabet.",
        "wikipedia": "https://en.wikipedia.org/wiki/Byblos",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Kadisha Valley",
        "location": "North Lebanon Governorate, Lebanon",
        "coordinates": [
            34.24333,
            36.04889
        ],
        "description": "The Qadisha Valley has provided shelter for Christian communities since the beginnings of Christianity, hosting a large number of monasteries and hermitages. The Forest of the Cedars of God is among the last locations where Cedrus libani still grows, historically one of the most prized construction materials.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kadisha_Valley",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Rachid Karami International Fair-Tripoli",
        "location": "North Lebanon Governorate, Lebanon",
        "coordinates": [
            34.43917,
            35.82417
        ],
        "description": "Located in northern Lebanon, the Rachid Karameh International Fair of Tripoli was designed in 1962 by the Brazilian architect Oscar Niemeyer on a 70-hectare site located between the historic centre of Tripoli and the Al Mina port. The fair was the flagship project of Lebanon's modernization policy in the 1960s.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tripoli,_Lebanon#Oscar_Niemeyer's_Rachid_Karame_Fairground",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Tyre",
        "location": "South Lebanon Governorate, Lebanon",
        "coordinates": [
            33.27194,
            35.19444
        ],
        "description": "The ancient Phoenician city of Tyre was one of the foremost maritime powers in the eastern Mediterranean and reportedly where purple dye was discovered. Extant archaeological remains mainly date back to Roman times, including baths, an arena, a colonnaded road, a triumphal arch, an aqueduct and a hippodrome.",
        "wikipedia": "https://en.wikipedia.org/wiki/Tyre,_Lebanon",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Cyrene",
        "location": "Jabal al Akhdar, Libya",
        "coordinates": [
            32.825,
            21.85833
        ],
        "description": "The formerly Greek colony of Cyrene was Romanized and transformed into a capital, until it was destroyed by the 365 Crete earthquake. The thousand-year-old ruins have remained renowned since the 18th century.",
        "wikipedia": "https://en.wikipedia.org/wiki/Cyrene,_Libya",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Leptis Magna",
        "location": "Khoms, Libya",
        "coordinates": [
            32.63833,
            14.29306
        ],
        "description": "The Roman city of Leptis Magna was enlarged by Emperor Septimius Severus, who was born there. Public monuments, a harbour, a marketplace, storehouses, shops, and homes were among the reasons for its induction into the list.",
        "wikipedia": "https://en.wikipedia.org/wiki/Leptis_Magna",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Sabratha",
        "location": "Zawiya District, Libya",
        "coordinates": [
            32.80528,
            12.485
        ],
        "description": "Founded as a Phoenician trading post, Sabratha was briefly ruled by Masinissa of Numidia before its Romanization and reconstruction in the 2nd and 3rd centuries.",
        "wikipedia": "https://en.wikipedia.org/wiki/Sabratha",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Ghadamès",
        "location": "Nalut District, Libya",
        "coordinates": [
            30.13333,
            9.5
        ],
        "description": "Located in an oasis, Ghadames is among the oldest of pre-Saharan cities and represents a traditional domestic architecture with vertical division of functions.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ghadames",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Acacus Mountains",
        "location": "Fezzan, Libya",
        "coordinates": [
            24.83333,
            10.33333
        ],
        "description": "The massif of Tadrart Acacus contains thousands of cave paintings in different styles, dating from 12,000 BCE to 100 CE, reflecting changes of flora and fauna in the region as well as different lifestyles of successive populations in the Sahara.",
        "wikipedia": "https://en.wikipedia.org/wiki/Rock-art_sites_of_Tadrart_Acacus",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Ancient Ksour of Ouadane, Chinguetti, Tichitt and Oualata",
        "location": "Ouadane, Mauritania",
        "coordinates": [
            20.92889,
            -11.62361
        ],
        "description": "These four settlements were founded in the 11th and 12th centuries, originally meant to serve caravan trade routes across the Sahara. They gradually evolved into Islamic cultural centres, bearing witness to the nomadic lifestyle of the populations of western Sahara.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ancient_Ksour_of_Ouadane,_Chinguetti,_Tichitt_and_Oualata",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Banc d'Arguin National Park",
        "location": "Nouadhibou, Mauritania",
        "coordinates": [
            20.23472,
            -16.10889
        ],
        "description": "The park consists of sand dunes, coastal swamps, small islands, and shallow bodies of water; all bordering the coast of the Atlantic Ocean. Birds are often found to migrate in the area, accompanied by various species of sea turtles and dolphins, whose presence fishermen often use to attract fish.",
        "wikipedia": "https://en.wikipedia.org/wiki/Banc_d%27Arguin_National_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Volubilis",
        "location": "Fès-Meknès, Morocco",
        "coordinates": [
            34.07389,
            -5.55694
        ],
        "description": "The important Roman outpost of Volubilis was founded in the 3rd century BCE as the capital of Mauretania, later becoming the capital of the Idrisid dynasty. It contained many buildings, the remains of which have survived extensively to this day.",
        "wikipedia": "https://en.wikipedia.org/wiki/Volubilis",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Meknes",
        "location": "Fès-Meknès, Morocco",
        "coordinates": [
            33.88333,
            -5.55833
        ],
        "description": "The former capital of the Alaouite dynasty was founded in the 11th century by the Almoravids and turned into a city with Spanish-Moorish influence during the 17th and 18th centuries.",
        "wikipedia": "https://en.wikipedia.org/wiki/Meknes",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Aït Benhaddou",
        "location": "Drâa-Tafilalet, Morocco",
        "coordinates": [
            31.04722,
            -7.12889
        ],
        "description": "The Ksar of Ait-Ben-Haddou is an example of a traditional pre-Saharan habitat in southern Morocco, surrounded by high walls and reinforced with corner towers.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ksar_of_Ait-Ben-Haddou",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Essaouira",
        "location": "Marrakesh-Safi, Morocco",
        "coordinates": [
            31.51667,
            -9.76944
        ],
        "description": "Built during the late 18th century, the fortified seaport of Essaouira has a mix of North African and European architecture, and was a major trading hub between the Sahara and Europe.",
        "wikipedia": "https://en.wikipedia.org/wiki/Essaouira",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Fes el Bali",
        "location": "Fez, Morocco",
        "coordinates": [
            34.06111,
            -4.97778
        ],
        "description": "Fez was founded in the 9th century and reached its apogee as the capital of the Marinid dynasty in the 13th and 14th centuries, from which its urban fabric and major monuments date. It also hosts the world's oldest university, the University of Al Quaraouiyine.",
        "wikipedia": "https://en.wikipedia.org/wiki/Fes_el_Bali",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Marrakesh",
        "location": "Marrakesh-Safi, Morocco",
        "coordinates": [
            31.63139,
            -7.98667
        ],
        "description": "Marrakesh was founded in the 1070s and remained a political, economic, and cultural centre for a long time. Monuments from that period include the Koutoubia Mosque, the kasbah, and the battlements. The city also holds newer features, including palaces and madrasas.",
        "wikipedia": "https://en.wikipedia.org/wiki/Medina_of_Marrakesh",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Tétouan",
        "location": "Tanger-Tetouan-Al Hoceima, Morocco",
        "coordinates": [
            35.57083,
            -5.36667
        ],
        "description": "Morocco's most complete medina at Tétouan served as the main point of contact between Morocco and Andalusia during the 8th century. The town was rebuilt by Andalusian refugees following the Reconquista, the influence of which is evident in its art and architecture.",
        "wikipedia": "https://en.wikipedia.org/wiki/T%C3%A9touan",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Rabat",
        "location": "Rabat, Morocco",
        "coordinates": [
            34.02417,
            -6.82278
        ],
        "description": "Rebuilt under the direction of the French from 1912 to the 1930s, Rabat blends historic and modern features, such as botanical gardens, the Hassan Mosque, and the remnants of Moorish and Andalusian settlements from the 17th century.",
        "wikipedia": "https://en.wikipedia.org/wiki/Rabat",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "El Jadida",
        "location": "Casablanca-Settat, Morocco",
        "coordinates": [
            33.25667,
            -8.50194
        ],
        "description": "The Portuguese fortification of Mazagan, akin to early Renaissance military design from the early 16th century, was taken over by Morocco in 1769. Surviving buildings include the cistern and a Gothic church.",
        "wikipedia": "https://en.wikipedia.org/wiki/El_Jadida",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Aflaj Irrigation Systems of Oman",
        "location": "Dakhiliyah, Oman",
        "coordinates": [
            22.99889,
            57.53611
        ],
        "description": "The five Aflaj systems inscribed represents an irrigation method dating back to at least 500 CE. Such system effectively distribute water through gravity, as it flows to support agricultural and domestic use.",
        "wikipedia": "https://en.wikipedia.org/wiki/Aflaj_Irrigation_Systems_of_Oman",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Qalhat",
        "location": "Ash Sharqiyah South Governorate, Oman",
        "coordinates": [
            22.7,
            59.367
        ],
        "description": "",
        "wikipedia": "https://en.wikipedia.org/wiki/Qalhat",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Archaeological Sites of Bat, Al-Khutm and Al-Ayn",
        "location": "Ad Dhahirah Region, Oman",
        "coordinates": [
            23.26972,
            56.745
        ],
        "description": "Located in the interior of the Sultanate, Bat, al-Khutm and al-Ayn are among the best preserved ensembles of settlements and necropolises from the 3rd millennium BCE in Eastern Arabia and the world. The necropolis of Bat, in particular, reflects the funeral practices of the Early Bronze Age in Oman.",
        "wikipedia": "https://en.wikipedia.org/wiki/Archaeological_Sites_of_Bat,_Al-Khutm_and_Al-Ayn",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Bahla Fort",
        "location": "Ad Dakhiliyah Region, Oman",
        "coordinates": [
            22.96417,
            57.30111
        ],
        "description": "The fort is part of Bahla, an oasis settlement and formerly the capital of the Nabhani dynasty, who dominated Oman and prospered in the Arabian Peninsula during the Late Middle Ages.",
        "wikipedia": "https://en.wikipedia.org/wiki/Bahla_Fort",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Land of Frankincense",
        "location": "Dhofar Governorate, Oman",
        "coordinates": [
            18.25333,
            53.6475
        ],
        "description": "The ancient ports of Khor Rori and Al-Baleed, the caravan oasis of Shisr and Wadi Dawkah in southern Arabia were crucial in the production and distribution of frankincense, one of the most sought-after items of luxury in Antiquity.",
        "wikipedia": "https://en.wikipedia.org/wiki/Frankincense_Trail",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Old City of Jerusalem",
        "location": "East,",
        "coordinates": [
            31.76667,
            35.21667
        ],
        "description": "A holy city for three of the world's major religions, Jerusalem contains more than 200 historic monuments, including the Dome of the Rock, the Wailing Wall and the Church of the Holy Sepulchre.",
        "wikipedia": "https://en.wikipedia.org/wiki/Old_City_(Jerusalem)",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Church of the Nativity",
        "location": "Bethlehem Governorate, Palestine",
        "coordinates": [
            31.70444,
            35.2075
        ],
        "description": "The site is traditionally identified as the birthplace of Jesus. The church, built in the 4th century and reconstructed in the 6th century, holds prominent religious significance to both Christians and Muslims.",
        "wikipedia": "https://en.wikipedia.org/wiki/Church_of_the_Nativity",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Battir",
        "location": "Bethlehem Governorate, Palestine",
        "coordinates": [
            31.71972,
            35.13056
        ],
        "description": "The hill landscape of Battir features stone terraces for market garden, grapevine and olive production, supported by a traditional irrigation system.",
        "wikipedia": "https://en.wikipedia.org/wiki/Battir",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Hebron",
        "location": "Hebron Governorate, Palestine",
        "coordinates": [
            31.525,
            35.10833
        ],
        "description": "The centre of interest of the town was the site of Al-Ibrahimi Mosque/The tomb of the Patriarchs whose buildings are in a compound built in the 1st century CE to protect the tombs of the patriarch Abraham / Ibrahim and his family.",
        "wikipedia": "https://en.wikipedia.org/wiki/Hebron",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Al Zubarah",
        "location": "Al Shamal, Qatar",
        "coordinates": [
            25.97806,
            51.02972
        ],
        "description": "The coastal town of Al Zubarah was a major pearling and trading centre in the Persian Gulf in the late 18th and early 19th centuries, before its destruction in 1811 and eventual abandonment in the early 20th century. The remains of its palaces, mosques, harbour, fortifications and other structures are preserved by the sand blown from the desert.",
        "wikipedia": "https://en.wikipedia.org/wiki/Al_Zubarah",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Al-Hasa Oasis",
        "location": "Hofuf, Saudi Arabia",
        "coordinates": [
            25.42944,
            49.62194
        ],
        "description": "",
        "wikipedia": "https://en.wikipedia.org/wiki/Al-Ahsa_Oasis",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Mada'in Salih",
        "location": "Al Madinah Province, Saudi Arabia",
        "coordinates": [
            26.78361,
            37.955
        ],
        "description": "Formerly known as Hegra, the site of Al-Hijr constitutes the largest settlement of the Nabataean Kingdom after Petra. It contains a series of well-preserved rock-cut tombs and monuments dating back to the 1st century BCE.",
        "wikipedia": "https://en.wikipedia.org/wiki/Hegra_(Mada%27in_Salih)",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Diriyah",
        "location": "Riyadh Province, Saudi Arabia",
        "coordinates": [
            24.73417,
            46.5725
        ],
        "description": "Founded in the 15th century, the Turaif District of Dir'iyah was the first capital of the Saudi dynasty. Dir'iyah witnessed the growth of its political and religious significance, as well as the spread of Wahhabism in the 18th and the early 19th centuries.",
        "wikipedia": "https://en.wikipedia.org/wiki/Diriyah",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Bir Hima Rock Petroglyphs and Inscriptions",
        "location": "Najran Province, Saudi Arabia",
        "coordinates": [
            18.24861,
            44.45167
        ],
        "description": "Ḥimā Cultural Area contains a collection of rock art images illustrating hunting, fauna, flora and lifestyles of 7,000 years.",
        "wikipedia": "https://en.wikipedia.org/wiki/Bir_Hima_Rock_Petroglyphs_and_Inscriptions",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Al-Balad, Jeddah",
        "location": "Makkah Region, Saudi Arabia",
        "coordinates": [
            21.48389,
            39.1875
        ],
        "description": "Located on the coast of the Red Sea, Jeddah grew into a major commercial centre in the 7th century, and has long served as the principal gateway for pilgrims to Mecca.",
        "wikipedia": "https://en.wikipedia.org/wiki/Al-Balad,_Jeddah",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Rock Art in the Ha'il Region",
        "location": "Ha'il Region, Saudi Arabia",
        "coordinates": [
            28.01056,
            40.91306
        ],
        "description": "The sites of Jabel Umm Sinman and Jabal al-Manjor and Raat contain a large number of petroglyphs and inscriptions, covering 10,000 years of human history.",
        "wikipedia": "https://en.wikipedia.org/wiki/Rock_Art_in_the_Ha%27il_Region",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "‘Uruq Bani Mu’arid",
        "location": "Najran, Saudi Arabia",
        "coordinates": [
            19.337,
            45.904
        ],
        "description": "The property encompasses the western part of the greatest expanse of windblown sand on Earth, known as Ar Rub' al-KhaIi, and conserves one of the Earth’s most spectacular desert landscapes.",
        "wikipedia": "https://en.wikipedia.org/wiki/%27Uruq_Bani_Ma%27arid",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Qaryat al-Faw",
        "location": "Riyadh Province, Saudi Arabia",
        "coordinates": [
            19.764917,
            45.163389
        ],
        "description": "Lying at a strategic point of the ancient trade routes of the Arabian Peninsula, the property was abruptly abandoned around the 5th century CE.",
        "wikipedia": "https://en.wikipedia.org/wiki/Qaryat_al-Faw",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Meroë",
        "location": "River Nile State, Sudan",
        "coordinates": [
            16.93333,
            33.71667
        ],
        "description": "Meroe was the centre of the Kingdom of Kush, a major force active from the 8th century BCE to the 4th century CE. It is home to pyramids, temples, and domestic buildings, among other vestiges.",
        "wikipedia": "https://en.wikipedia.org/wiki/Mero%C3%AB",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Jebel Barkal",
        "location": "Northern State, Sudan",
        "coordinates": [
            18.53333,
            31.81667
        ],
        "description": "The archaeological sites of Gebel Barkal, Kurru, Nuri, Sanam and Zuma in the Nile Valley testify to the Napatan and Meroitic civilisations. They host a series of pyramids, tombs, temples, palaces, burial mounds and funerary chambers.",
        "wikipedia": "https://en.wikipedia.org/wiki/Gebel_Barkal_and_the_Sites_of_the_Napatan_Region",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Sanganeb Marine National Park and Dungonab Bay",
        "location": "Sudan, Sudan",
        "coordinates": [
            19.73611,
            37.44306
        ],
        "description": "Situated in the central Red Sea, Sanganeb, Dungonab Bay and Mukkawar Island feature a diverse system of coral reefs, mangroves, seagrass beds, beaches and islets, and host populations of seabirds, marine mammals, fish, sharks, turtles, manta rays and dugongs.",
        "wikipedia": "https://en.wikipedia.org/w/index.php?title=Sanganeb_Marine_National_Park_and_Dungonab_Bay&action=edit&redlink=1",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Ancient Aleppo",
        "location": "Aleppo Governorate, Syria",
        "coordinates": [
            36.23333,
            37.16667
        ],
        "description": "Situated at the crossroads of several trade routes, Aleppo has been successively ruled, among others, by the Romans, Ayyubids, Mameluks and Ottomans, each leaving significant influence in its architectural fabric, resulting in a diverse cityscape. Major structures include the Citadel, the Great Mosque and the Madrasa Halawiye.",
        "wikipedia": "https://en.wikipedia.org/wiki/Old_City_of_Aleppo",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Ancient City of Bosra",
        "location": "Daraa Governorate, Syria",
        "coordinates": [
            32.51806,
            36.48167
        ],
        "description": "Formerly a Nabataean settlement, Bosra was conquered by the Romans in the 2nd century CE and made capital of Arabia. It came under Islamic rule in the 7th century. Remains of the ancient city include a theatre, a basilica, a cathedral, a mosque and a madrasa, among others.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ancient_City_of_Bosra",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Old city of Damascus",
        "location": "Damascus Governorate, Syria",
        "coordinates": [
            33.51139,
            36.30639
        ],
        "description": "Established the 3rd millennium BCE, Damascus is considered to be one of the oldest continuously inhabited cities in the world. As the capital of the Umayyads, it has been of significant influence to the Arab world. The Great Mosque is among the largest in the world and the oldest sites of continuous prayer since the beginnings of Islam.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ancient_City_of_Damascus",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Dead Cities",
        "location": "Syria, Syria",
        "coordinates": [
            36.33417,
            36.84417
        ],
        "description": "The site comprises some 40 villages, dating from the 1st to 7th centuries and abandoned in the 8th to 10th centuries. They provide an insight into rural life in Late Antiquity and during the Byzantine period.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dead_Cities",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Krac des Chevaliers and Sahyun Castle",
        "location": "Homs, Syria",
        "coordinates": [
            34.78167,
            36.26306
        ],
        "description": "The Crac des Chevaliers and the Qal'at Salah El-Din are regarded as two of the most prominent examples of castles during the Crusader period, demonstrating an evolution of fortifications and exchange of influences in defensive technology.",
        "wikipedia": "https://en.wikipedia.org/wiki/Krak_des_Chevaliers",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Palmyra",
        "location": "Homs Governorate, Syria",
        "coordinates": [
            34.55417,
            38.26667
        ],
        "description": "Palmyra came under Roman rule in the 1st century CE, and grew to become one of the most important cultural centres of the ancient world. Its extensive ruins include remains of the Great Colonnade, the Temple of Bel, the Camp of Diocletian and the Roman Theatre.",
        "wikipedia": "https://en.wikipedia.org/wiki/Site_of_Palmyra",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Carthage",
        "location": "Tunis Governorate, Tunisia",
        "coordinates": [
            36.85278,
            10.32333
        ],
        "description": "Founded in the 9th century BCE, Carthage developed into a trading empire spanning the Mediterranean. The city was destroyed in 146 BCE in the Punic Wars at the hands of the Romans, but was later reestablished.",
        "wikipedia": "https://en.wikipedia.org/wiki/Archaeological_Site_of_Carthage",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Dougga",
        "location": "Béja Governorate, Tunisia",
        "coordinates": [
            36.42361,
            9.22028
        ],
        "description": "The site features the ruins of Dougga, a former capital of a Libyan–Punic state, which flourished under the Romans and the Byzantines, but went into decline in the Islamic period.",
        "wikipedia": "https://en.wikipedia.org/wiki/Dougga",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Amphitheatre of El Jem",
        "location": "Mahdia Governorate, Tunisia",
        "coordinates": [
            35.29639,
            10.70694
        ],
        "description": "Built during the 3rd century, the Amphitheatre of El Jem is North Africa's largest amphitheatre, and the largest one built outside of Italy, with a capacity of 35,000 spectators, regarded as among the most accomplished examples of Roman architecture of its kind.",
        "wikipedia": "https://en.wikipedia.org/wiki/Amphitheatre_of_El_Jem",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Lake Ichkeul",
        "location": "Bizerte Governorate, Tunisia",
        "coordinates": [
            37.16361,
            9.67472
        ],
        "description": "Ichkeul Lake and the surrounding wetlands is a destination for hundreds of thousands of migrating birds, including ducks, geese, storks and pink flamingos. It was once part of a chain that extended across North Africa.",
        "wikipedia": "https://en.wikipedia.org/wiki/Ichkeul_National_Park",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Kairouan",
        "location": "Kairouan Governorate, Tunisia",
        "coordinates": [
            35.68167,
            10.10389
        ],
        "description": "Founded in 670, Kairouan was the former capital of Ifriqiya and flourished in the 9th century. Its heritage includes the Mosque of Uqba and the Mosque of the Three Gates.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kairouan",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Medina of Sousse",
        "location": "Sousse Governorate, Tunisia",
        "coordinates": [
            35.82778,
            10.63861
        ],
        "description": "A prime example of a town from the early Islamic period, Sousse was an important commercial and military port during the 9th century.",
        "wikipedia": "https://en.wikipedia.org/wiki/Medina_of_Sousse",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Medina of Tunis",
        "location": "Tunis Governorate, Tunisia",
        "coordinates": [
            36.81667,
            10.16667
        ],
        "description": "The Medina of Tunis contains some 700 monuments, including palaces, mosques, mausoleums, madrasah and fourtains, testifying to Tunis' golden age from the 12th to the 16th century.",
        "wikipedia": "https://en.wikipedia.org/wiki/Medina_of_Tunis",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Kerkouane",
        "location": "Nabeul Governorate, Tunisia",
        "coordinates": [
            36.94639,
            11.09917
        ],
        "description": "Abandoned in 250 BCE during the First Punic War and never rebuilt, Kerkuane is the only surviving example of a Phoenicio–Punic settlement.",
        "wikipedia": "https://en.wikipedia.org/wiki/Kerkouane",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Al Ain",
        "location": "Abu Dhabi, United Arab Emirates",
        "coordinates": [
            24.06778,
            55.80639
        ],
        "description": "Situated in a desert region, Al Ain has been occupied since the Neolithic period, hosting stone tombs from the 3rd millennium BCE, wells, adobe constructions and one of the oldest examples of the aflaj irrigation system at Bidaa Bint Saud.",
        "wikipedia": "https://en.wikipedia.org/wiki/Al_Ain",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Zabid",
        "location": "Al Hudaydah Governorate, Yemen",
        "coordinates": [
            14.19806,
            43.33
        ],
        "description": "Zabid was the capital of Yemen from the 13th to the 15th century. Its Islamic university greatly contributed to the spread of Islamic knowledge.",
        "wikipedia": "https://en.wikipedia.org/wiki/Zab%C4%ABd",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Sanaa",
        "location": "Sana'a Governorate, Yemen",
        "coordinates": [
            15.35556,
            44.20806
        ],
        "description": "Situated in a mountain valley, Sana'a has been continuously inhabited for more than 2,500 years. It became a centre for the spread of Islam in the 7th and 8th centuries. The city is home to the unique rammed-earth tower-houses, in addition to 103 mosques and 14 hammams built before the 11th century.",
        "wikipedia": "https://en.wikipedia.org/wiki/Sana%27a",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Shibam",
        "location": "Hadhramaut Governorate, Yemen",
        "coordinates": [
            15.92694,
            48.62667
        ],
        "description": "The 16th-century walled city of Shibam is among the oldest and best examples of vertical urban planning, with its distinct mud brick tower houses garnering it the nickname \"the Manhattan of the desert\".",
        "wikipedia": "https://en.wikipedia.org/wiki/Shibam",
        "constructedDate": "",
        "isUnesco": true
    },
    {
        "name": "Socotra",
        "location": "Socotra Governorate, Yemen",
        "coordinates": [
            12.5,
            53.83333
        ],
        "description": "Although part of the African landmass, the Socotra Archipelago is administered from Yemen. It is known for its great biodiversity and distinct flora and fauna, with a large number of endemic species found nowhere else on earth. It also supports a broad range of avian and marine life.",
        "wikipedia": "https://en.wikipedia.org/wiki/Socotra",
        "constructedDate": "",
        "isUnesco": true
    }
];
