import React, {useState} from "react";
import { Marker, Popup } from "react-leaflet";
import L from "leaflet";
import ReportModal from './ReportModal';
import unescoLogo from './unesco.png';
import "leaflet/dist/leaflet.css";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const CustomMarker = ({ structure }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleReportClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <Marker position={structure.coordinates}>
            <Popup>
                <h3>
                    {structure.isUnesco && <img src={unescoLogo} alt="UNESCO Logo" className="unesco-logo"/>}
                    {structure.name}
                </h3>
                <p><strong>Location:</strong> {structure.location}</p>
                <p>{structure.description}</p>
                {structure.constructedDate && (
                    <p><strong>Constructed:</strong> {structure.constructedDate}</p>
                )}                <p>
                    <a href={structure.wikipedia} target="_blank" rel="noopener noreferrer">
                        More Info
                    </a>
                </p>
                    <a href="#" className="report-button" rel="noopener noreferrer" onClick={handleReportClick}>
                    <p className="report-text">Report Issue</p>
                </a>

            </Popup>
            {isModalOpen && <ReportModal onClose={handleCloseModal} structure={structure}
                                         header="Report An Issue"
                                         description="Please describe the issue with the marker on the map:"/>}
        </Marker>
    );
};

export default CustomMarker;
