import './App.css';
import Map from './Map';
import ReactGA from "react-ga4";
import React, {useEffect, useState} from 'react';
import { megalithicStructures } from "./data";

function App() {
    ReactGA.initialize("G-KMZ9HF89WN");

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredStructures, setFilteredStructures] = useState(megalithicStructures);
    const [pinCount, setPinCount] = useState(0);

    useEffect(() => {
        setPinCount(filteredStructures.length);
    }, [filteredStructures]);

    const handleSearch = (event) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term);
        setFilteredStructures(megalithicStructures.filter(structure =>
            structure.name.toLowerCase().includes(term) ||
            structure.location.toLowerCase().includes(term)
        ));
    };

    return (
        <div className="App">
            <div>
                <input
                    type="text"
                    placeholder="Search by name or location"
                    value={searchTerm}
                    onChange={handleSearch}
                    className="search-input"
                />
            </div>
            <div className="pin-count-label">Results: {pinCount}</div>
            <Map structures={filteredStructures}/>
        </div>
    );
}

export default App;
