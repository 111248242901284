import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import CustomMarker from "./Marker";

const Map = ({ structures }) => {
    return (
        <div className="map-container">
            <MapContainer center={[20, 0]} zoom={3} className="leaflet-container">
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                {structures.map((structure, index) => (
                    <CustomMarker key={index} structure={structure} />
                ))}
            </MapContainer>
        </div>
    );
};

export default Map;
